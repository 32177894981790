import React, { useEffect } from "react";
import { toast } from "react-toastify";
import Header from "./Home_header";
import useState from "react-usestateref";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { Bars } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";

const P2PChat = () => {
  const [selectedCurrency, setSelectedCurrency, selectedCurrencyref] = useState(
    {}
  );
  const [pageLoader, setPageloader] = useState(true);

  const [orderType, setorderType, orderTyperef] = useState("");
  const [profileDatas, setprofileData, profileDatasref] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    const url = window.location.href;
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname.split("/");
    const orderId = pathSegments[pathSegments.length - 1];
    getAllp2pOrders(orderId);
    getProfile();
  }, []);

  const getProfile = async () => {
    setPageloader(true);

    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
    setPageloader(false);

      console.log(resp);
      if (resp.status) {
        console.log("resp.status", resp.data);
        setprofileData(resp.data);
      }
    } catch (error) { }
  };

  const getAllp2pOrders = async (arg) => {
    setPageloader(true);
    try {
      const data = {
        apiUrl: apiService.p2p_get_order,
        payload: {
          orderId: arg,
        },
      };
      const resp = await postMethod(data);
    setPageloader(false);

      if (resp.status) {
        setSelectedCurrency(resp.Message);


        console.log(orderTyperef.current,
          profileDatasref.current._id,
          selectedCurrencyref.current.user_id,
          selectedCurrencyref.current.status)
        if (resp.Message.ordertype == "Buy") {
          setorderType("Sell");
        } else {
          setorderType("Buy");
        }
      }
    } catch (error) {
      console.error("Error fetching P2P orders:", error);
    }
  };

  const buyer_cancel = async (status) => {
    console.log("uuhikjjkijkijm");
    try {
      var obj = {
        orderId: window.location.href.split("/").pop(),
        status: "cancelled",
      };
      var data = {
        apiUrl: apiService.buyer_cancel,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/p2p");
      } else {
        toast.error(resp.Message);
      }
    } catch (error) { }
  };

  const seller_cancel = async (status) => {
    console.log("ijhnhijokok");
    try {
      var obj = {
        orderId: window.location.href.split("/").pop(),
        status: "cancelled",
      };
      var data = {
        apiUrl: apiService.seller_cancel,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/p2p");
      } else {
        toast.error(resp.Message);
      }
    } catch (error) { }
  };

  return (
    <main className="nextex_p2p_main">
      <section>
        <Header />
      </section>
      {pageLoader == true ? (
          <div className="dashboard_content_section loadercss">
            <Bars
              height="40"
              width="40"
              color="#1062fe"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
      <section className="nextex_p2p_section">
        <div className="container">
          <div className="nextex_p2p_section_content">
            <div>
              <Link to="/p2p">
                <h6 className="my-4 pay-title">
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> P2P
                </h6>
              </Link>

              <div className="row  payment-cards">
                <div className="col-lg-6">
                  <h5 className="pay-title">
                    {selectedCurrencyref.current.ordertype}{" "}
                    {selectedCurrencyref.current.currency_symbol}
                  </h5>{" "}
                  <div className="pay-wrapper-two">
                    <div className="pay-flex">
                      <span className="pay-method">Price</span>
                      <span className="pay-bank">
                        {" "}
                        {selectedCurrency.price}
                      </span>
                    </div>
                    <div className="pay-flex">
                      <span className="pay-name">Amount </span>
                      <span className="pay-money">
                        {selectedCurrency.quantity}
                      </span>
                    </div>
                  </div>
                  <div>
                    {orderTyperef.current == "Sell" &&
                      profileDatasref.current._id ==
                      selectedCurrencyref.current.user_id &&
                      selectedCurrencyref.current.status != "filled" &&
                      selectedCurrencyref.current.status != "cancelled" ? (
                      <button
                        type="button"
                        className="proceed-btn txt-cente"
                        onClick={seller_cancel}
                      >
                        Cancel
                      </button>
                    ) : (
                      ""
                    )}

                    {orderTyperef.current == "Buy" &&
                      profileDatasref.current._id ==
                      selectedCurrencyref.current.user_id &&
                      selectedCurrencyref.current.status != "filled" &&
                      selectedCurrencyref.current.status != "cancelled" ? (
                      <button
                        type="button"
                        className="proceed-btn txt-center"
                        onClick={buyer_cancel}
                      >
                        Cancel
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="chat-box">
                    <div className="chat-flex">
                      <div>
                        <img
                          src={require("../images/j.png")}
                          alt="j-img"
                          className="j-img"
                        />
                      </div>
                      <div className="chat-content">
                        <span className="pay-btc">
                          {selectedCurrency.username} Unknown
                        </span>
                        <span className="chat-para">
                          {selectedCurrency.orders_count} Volume |{" "}
                          {selectedCurrency.rating} % Transaction rate
                        </span>
                      </div>
                    </div>

                    <div className="pay-wrapper-two mt-4">
                      <div className="pay-flex">
                        <span className="pay-method">Price</span>
                        <span className="pay-bank">
                          {" "}
                          {selectedCurrency.price}
                        </span>
                      </div>
                      <div className="pay-flex">
                        <span className="pay-name">Amount </span>
                        <span className="pay-money">
                          {selectedCurrency.quantity}
                        </span>
                      </div>

                      <div className="pay-flex">
                        <span className="pay-name">Limit </span>
                        <span className="pay-money">
                          {`${selectedCurrency.fromLimit} ${selectedCurrency.firstCurrency} - ${selectedCurrency.toLimit} ${selectedCurrency.firstCurrency}`}
                        </span>
                      </div>

                      <div className="pay-flex">
                        <span className="pay-name">Payment Methods </span>
                        <span className="pay-money">
                          {selectedCurrency &&
                            selectedCurrency?.payment_method?.map(
                              (method, index) => <span>{method}</span>
                            )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        )}
    </main>
  );
};

export default P2PChat;
