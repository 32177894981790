import React, { useEffect } from "react";
import Home_header from "./Home_header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

function Home() {
  return (
    <main className="dashboard_page">
      <section>
        <Home_header />
      </section>

      <section className="dashboard_content_section">
        <div className="container">
          <div className="smart_dashboard_content">
            <h2>
              Net1EX –<span>Trust</span>{" "}
            </h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_board_profile_content">
                  <div className="contentpage">
                    <h4>TRUST & SECURITY</h4>

                    <p>
                      At Net1Ex, we prioritize the security, privacy, and safety
                      of our users above all else. Our mission is to provide a
                      secure trading environment that instills confidence and
                      trust in our services. To help protect you against common
                      crypto scams, we encourage all users to stay informed and
                      vigilant.
                    </p>

                    <h4>Beware of Common Crypto Scams</h4>

                    <h5>Phishing Attacks</h5>
                    <p>
                      Phishing scams often involve fraudulent websites designed
                      to trick you into providing sensitive information like
                      login credentials or wallet access. Scammers use emails,
                      SMS, social media, and search engine ads to make these
                      sites look legitimate. Always double-check the
                      authenticity of the sources before providing any
                      information.
                    </p>

                    <h5>Complete KYC:</h5>
                    <p>
                      It is imperative for users to complete their KYC
                      verification and provide accurate personal information.
                    </p>

                    <h5>Protect Your Personal Information</h5>
                    <p>
                      Never share sensitive details such as usernames,
                      passwords, or credit card numbers with unknown
                      individuals. Keep your personal information private to
                      avoid falling victim to scams.
                    </p>

                    <h5>Hover, Don’t Click</h5>
                    <p>
                      Be cautious with links in emails, especially unsolicited
                      or urgent ones. Before clicking, hover over the link to
                      verify its authenticity. If the alt text doesn’t match the
                      display text, avoid clicking. Watch out for poor grammar
                      or spelling errors, which can be red flags of a scam.
                    </p>

                    <h5>Fake Celebrity Endorsements</h5>
                    <p>
                      Scammers often pose as celebrities or claim endorsements
                      from well-known figures to capture attention. They may
                      promote fake cryptocurrencies that don’t exist, using
                      sophisticated tactics like polished websites and materials
                      that falsely include endorsements from public figures,
                      such as Elon Musk.
                    </p>

                    <h5>Giveaway Scams</h5>
                    <p>
                      Fraudsters frequently exploit social media to run fake
                      giveaway scams, using forged messages from companies and
                      high-profile individuals. These posts often include links
                      to fraudulent websites, which then ask you to “verify”
                      your address by sending cryptocurrency. Always verify the
                      legitimacy of giveaways before participating.
                    </p>

                    <h5>Pump and Dump Schemes</h5>
                    <p>
                      Pump and dump schemes involve artificially inflating the
                      price of a coin or token through hype on social media or
                      email blasts. Once the price is driven up, scammers sell
                      off their holdings, causing the value to plummet, often
                      within minutes. Be wary of sudden hype around obscure
                      tokens.
                    </p>

                    <h5>Too Good to Be True</h5>
                    <p>
                      Be skeptical of promises of extravagant rewards or
                      compensation. Avoid clicking on or downloading anything
                      from messages offering unrealistic retur
                    </p>

                    <h4>How to Stay Safe in the Crypto Environment</h4>
                    <h4>Your Responsibility to Guard Against Scammers</h4>
                    <h5>Take Your Time</h5>
                    <p>
                      Be patient and cautious when dealing with urgent requests
                      from unknown sources. Always double-check the authenticity
                      of the sender.
                    </p>

                    <h5>Research URLs Carefully</h5>
                    <p>
                      Inspect URLs closely for common typos or inconsistencies
                      that scammers use to create nearly identical fake
                      websites. Make sure the address matches the official site.
                    </p>
                    <p>
                      Our official website is{" "}
                      <a href="https://net1ex.com">https://net1ex.com</a>.
                      Always verify the URL in your browser's address bar before
                      entering any login details or 2FA codes. If you mistakenly
                      provide sensitive information to a malicious site, your
                      account could be compromised.
                    </p>

                    <h5>Avoid Unofficial Affiliations</h5>
                    <p>
                      Net1Ex has no partnerships with third-party websites,
                      especially those claiming to offer trading bots or
                      scripts. Entering your credentials on unofficial platforms
                      exposes your information to potential threats and can lead
                      to loss of assets. Ensure your email accounts and devices
                      are secure. Be particularly cautious when using public
                      resources like internet cafes, shared computers, or public
                      WiFi, as they pose higher risks.
                    </p>

                    <p>
                      Remember, maintaining the security of your account is a
                      shared responsibility. Always stay vigilant and protect
                      your information to keep your assets safe.
                    </p>

                    <h5>Prohibited Actions on Net1Ex:</h5>

                    <p>
                      Money laundering, fraudulent transactions, illegal
                      fundraising, and fraud are strictly prohibited.
                      Registering under false identities or providing fraudulent
                      KYC information will lead to account suspension, asset
                      freezing, or termination.
                    </p>

                    <p>
                      This risk disclosure does not cover all potential risks in
                      the cryptocurrency market. It is your responsibility to
                      thoroughly understand transaction rules, promotions,
                      agreements, and this risk disclosure before engaging in
                      cryptocurrency transactions.
                    </p>

                    <p>
                      Users are solely responsible for bearing trading risks on
                      Net1Ex. Net1Ex does not make any promises or guarantees
                      regarding user losses or gains.
                    </p>

                    <h5>User Commitment:</h5>

                    <p>
                      By using Net1Ex, you agree to the terms outlined in this
                      Risk Disclosure Agreement. Your commitment to adhering to
                      the national and regional laws and regulations of
                      Kyrgyzstan, and your registered residence. Any violations
                      or conduct triggering Net1Ex's risk-control measures may
                      result in account suspension, investigation, and legal
                      consequences, with all associated losses and liabilities
                      borne by the user.
                    </p>

                    <h5>Reminder:</h5>

                    <p>
                      Please be aware that by clicking to agree to this risk
                      disclosure, you acknowledge your understanding and
                      acceptance of cryptocurrency transaction risks and
                      potential losses. Net1Ex is not obligated to refund
                      cryptocurrency principal or interests or assume any other
                      responsibilities.
                    </p>

                    <p>
                      Thank you for choosing Net1Ex, and please trade
                      responsibly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
}

export default Home;
