import React, { useEffect } from "react";
import Header from "./Header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import Home_header from "./Home_header";
import useState from "react-usestateref";
import { Bars } from "react-loader-spinner";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import Sidebar_2 from "../pages/Sidebar_2";

function Home() {
  const [siteLoader, setSiteLoader] = useState(false);

  const [perpage, setperpage] = useState(5);
  const [activeOrderDatas, setActiveOders] = useState([]);
  const [tradeHistoryData, settradeHistory] = useState([]);
  const [cancelOrders, setcancelOrders] = useState([]);
  const [stopOrders, setstopOrders] = useState([]);
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [depositHistory, setdepositHistory] = useState([]);
  const [sessionHistory, setsessionHistory] = useState([]);
  const [TfaHistory, setTfaHistory] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);

  const [totalactive, settotalactive] = useState(0);
  const [currentPagecan, setCurrentPagecan] = useState(1);
  const [totalcan, settotalcan] = useState(0);
  const [currentPageHis, setcurrentPageHis] = useState(1);
  const [totalHist, settotalHist] = useState(0);
  const [currentPagestop, setCurrentPagestop] = useState(1);
  const [totalStop, settotalStop] = useState(0);

  const [depositcurrentpage, setdepositcurrentpage] = useState(1);
  const [deposittotalpage, setdepositTotalpages] = useState(0);

  const [withdrawcurrentpage, setwithdrawcurrentpage] = useState(1);
  const [withdrawtotalpage, setwithdrawTotalpages] = useState(0);

  const [logincurrentpage, setlogincurrentpage] = useState(1);
  const [logintotalpage, setloginTotalpages] = useState(0);

  const [tfacurrentpage, settfacurrentpage] = useState(1);
  const [tfatotalpage, settfaTotalpages] = useState(0);

  const recordPerPage = 5;
  const pageRange = 5;
  const recordPerPagecan = 5;
  const pageRangecan = 5;

  const recordPerPageHist = 5;
  const pageRangeHis = 5;

  const recordPerPageStop = 5;
  const pageRangeStop = 5;

  const recordPerPageLogin = 5;
  const pageRangeLogin = 5;

  const navigate = useNavigate();

  useEffect(() => {
    getActiveOrders(1);
    tradeHistory(1);
    getCancelOrders(1);
    getStopOrders(1);
    getdepositHistory(1);
    getwithdrawHistory(1);
    getLoginHistory(1);
    Tfa_History(1);
  }, [0]);

  const getActiveOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.getActiveOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotalactive(resp.count);
        setActiveOders(resp.result);
      } else {
      }
    } catch (error) {}
  };

  const tradeHistory = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.tradeHistory,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settradeHistory(resp.result);
        settotalHist(resp.count);
      } else {
      }
    } catch (error) {}
  };

  const getCancelOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.getCancelOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setcancelOrders(resp.result);
        settotalcan(resp.count);
      } else {
      }
    } catch (error) {}
  };

  const getStopOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.getStopOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotalStop(resp.count);
        setstopOrders(resp.result);
      } else {
      }
    } catch (error) {}
  };

  const orderCancel = async (cancelDatas) => {
    try {
      var obj = {
        _id: cancelDatas._id,
      };
      var data = {
        apiUrl: apiService.cancelOrder,
        payload: obj,
      };
      var fetchTradeHisotory = await postMethod(data);
      if (fetchTradeHisotory) {
        toast.success(
          "Order cancelled successfully, your amount credit to your wallet"
        );
        getActiveOrders(1);
      } else {
        toast.error("Please try again later");
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const getLoginHistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.getSessionHisotry,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setsessionHistory(resp.data.data);
        setloginTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const activePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getActiveOrders(pageNumber);
  };

  const cancelPageChange = (event, pageNumber) => {
    setCurrentPagecan(pageNumber); // call API to get data based on pageNumber
    getCancelOrders(pageNumber);
  };

  const orderhistoryactive = (pageNumber) => {
    setcurrentPageHis(pageNumber); // call API to get data based on pageNumber
    tradeHistory(pageNumber);
  };

  const orderstopactive = (event, pageNumber) => {
    setCurrentPagestop(pageNumber); // call API to get data based on pageNumber
    getStopOrders(pageNumber);
  };

  const getdepositHistory = async (page) => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.result);
      setdepositTotalpages(deposit_history_list.total);
      console.log("deposit_history_list.pages===", deposittotalpage);
    }
  };

  const depositrecordpage = 5;
  const depositpagerange = 5;
  const handlepagedeposit = (page) => {
    getdepositHistory(page);
    setdepositcurrentpage(page);
  };

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var withdraw_history_list = await postMethod(data);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
      setwithdrawTotalpages(withdraw_history_list.total);
    }
  };

  const withdrawrecordpage = 5;
  const withdrawpagerange = 5;
  const handlepagewithdraw = (page) => {
    getwithdrawHistory(page);
    setwithdrawcurrentpage(page);
  };

  const loginrecordpage = 5;
  const loginpagerange = 5;

  const handlepagelogin = (pageNumber) => {
    setlogincurrentpage(pageNumber); // call API to get data based on pageNumber
    getLoginHistory(pageNumber);
  };

  const Tfa_History = async (page) => {
    var data = {
      apiUrl: apiService.Tfa_History,
      payload: { perpage: 5, page: page },
    };
    setSiteLoader(true);
    var Tfa_History = await postMethod(data);
    setSiteLoader(false);
    if (Tfa_History) {
      setTfaHistory(Tfa_History.data.data);
      settfaTotalpages(Tfa_History.data.total);
    }
  };
  const tfarecordpage = 5;
  const tfapagerange = 5;
  const handlepagetfachange = (pageNumber) => {
    Tfa_History(pageNumber);
    settfacurrentpage(pageNumber);
  };

  const [mytrades, setmytrades] = useState("withdraw");
  const mytradesfunction = (newValue) => {
    setmytrades(newValue);
  };

  return (
    <main className="dashboard_page">
      <section>
        <Home_header />
      </section>
      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-lg-2">
              <Sidebar_2 />
            </div>
            <div className="col-lg-10">
              <section className="dashboard_content_section">
                <div className="container-fluid">
                  <div className="smart_dashboard_content">
                    <h2>Orders</h2>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="dash_board_profile_content">
                          <div class="lrtcss-ac8m87 e8w7gdo16 pt-0">
                            <section className="p2p_buy_sell_section w-100">
                              <div className="">
                                <div className="p2p_tabs mb-5">
                                  <div class="p2p_padding ">
                                    <ul
                                      class="nav nav-tabs history_tabs mb-3"
                                      id="myTabs"
                                    >
                                      <li class="nav-item">
                                        <a
                                          class="nav-link active"
                                          id="withdraw-tab"
                                          onClick={() =>
                                            mytradesfunction("withdraw")
                                          }
                                          data-bs-toggle="tab"
                                          data-bs-target="#withdraw"
                                          type="button"
                                          role="tab"
                                          aria-controls="withdraw"
                                          aria-selected="true"
                                        >
                                          Trade Open Orders
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          class="nav-link"
                                          id="trade-tab"
                                          onClick={() =>
                                            mytradesfunction("trade")
                                          }
                                          data-bs-toggle="tab"
                                          data-bs-target="#trade"
                                          type="button"
                                          role="tab"
                                          aria-controls="trade"
                                          aria-selected="true"
                                        >
                                          Cancel Orders
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          class="nav-link "
                                          id="deposit-tab"
                                          onClick={() =>
                                            mytradesfunction("deposit")
                                          }
                                          data-bs-toggle="tab"
                                          data-bs-target="#deposit"
                                          type="button"
                                          role="tab"
                                          aria-controls="deposit"
                                          aria-selected="true"
                                        >
                                          Stop orders
                                        </a>
                                      </li>
                                    </ul>

                                    <div class="p2p_tab tab-content  mt-2">
                                      <div
                                        id="withdraw"
                                        role="tabpanel"
                                        className={`tab-pane fade ${
                                          mytrades === "withdraw"
                                            ? "show active"
                                            : ""
                                        }`}
                                        aria-labelledby="withdraw-tab"
                                      >
                                        <div className="p2p_table table-responsive deposite_tabel_border">
                                          <table class="table   market">
                                            <thead>
                                              <tr className=" dark_table_bg"> 
                                                <th
                                                  scope="col"
                                                  className="starts"
                                                >
                                                  Date
                                                </th>
                                                <th scope="col">Pair</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Side</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Total</th>
                                                <th scope="col">Status</th>
                                                <th
                                                  scope="col"
                                                  className="ends"
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {stopOrders.length > 0 ? (
                                                stopOrders.map((item, i) => {
                                                  var dates = Moment(
                                                    item.createddate
                                                  ).format(
                                                    "DD.MM.YYYY hh:mm a"
                                                  );
                                                  return (
                                                    <tr className="history_tabel_bottom_border">
                                                      <td>{dates}</td>
                                                      <td>{item.pairName}</td>
                                                      <td>{item.ordertype}</td>
                                                      <td>{item.tradeType}</td>
                                                      <td>
                                                        {parseFloat(
                                                          item.filledAmount
                                                        ).toFixed(8)}{" "}
                                                        {item.firstSymbol}
                                                      </td>
                                                      <td>
                                                        {item.ordertype ==
                                                        "Stop"
                                                          ? parseFloat(
                                                              item.stoporderprice
                                                            ).toFixed(8)
                                                          : parseFloat(
                                                              item.price
                                                            ).toFixed(8)}{" "}
                                                        {item.toSymbol}
                                                      </td>
                                                      <td>
                                                        {item.ordertype ==
                                                        "Stop"
                                                          ? parseFloat(
                                                              item.filledAmount *
                                                                item.stoporderprice
                                                            ).toFixed(8)
                                                          : parseFloat(
                                                              item.filledAmount *
                                                                item.price
                                                            ).toFixed(8)}{" "}
                                                        {item.toSymbol}
                                                      </td>
                                                      <td>
                                                        {item.tradeType ==
                                                        "buy" ? (
                                                          <span className="text-green">
                                                            {item.status}
                                                          </span>
                                                        ) : (
                                                          <span className="text-red">
                                                            {item.status}
                                                          </span>
                                                        )}
                                                      </td>
                                                      <td>
                                                        <Button
                                                          onClick={() =>
                                                            orderCancel(item)
                                                          }
                                                        >
                                                          Cancel
                                                        </Button>
                                                      </td>
                                                    </tr>
                                                  );
                                                })
                                              ) : (
                                                <td colSpan={9}>
                                                  <div className="empty_data">
                                                    <div className="empty_data_img">
                                                      <img
                                                        src={require("../images/No-data.png")}
                                                      />
                                                    </div>

                                                    <div className="no_records_text">
                                                      No Records Found
                                                    </div>
                                                  </div>
                                                </td>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        {activeOrderDatas &&
                                        activeOrderDatas.length > 0 ? (
                                          <div className="pagination">
                                            <Stack spacing={2}>
                                              <Pagination
                                                count={Math.ceil(
                                                  totalactive / recordPerPage
                                                )}
                                                page={currentPage}
                                                onChange={activePageChange}
                                                renderItem={(item) => (
                                                  <PaginationItem
                                                    slots={{
                                                      previous: ArrowBackIcon,
                                                      next: ArrowForwardIcon,
                                                    }}
                                                    {...item}
                                                  />
                                                )}
                                              />
                                            </Stack>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div
                                        id="trade"
                                        role="tabpanel"
                                        className={`tab-pane fade ${
                                          mytrades === "trade"
                                            ? "show active"
                                            : ""
                                        }`}
                                        aria-labelledby="trade-tab"
                                      >
                                        <div className="p2p_table table-responsive deposite_tabel_border">
                                          <table class="table   market">
                                            <thead>
                                              <tr className="tabel_bg">
                                                <th
                                                  scope="col"
                                                  className="starts"
                                                >
                                                  Date
                                                </th>
                                                <th scope="col">Pair</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Side</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Total</th>
                                                <th
                                                  scope="col"
                                                  className="ends"
                                                >
                                                  Status
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {cancelOrders.length > 0 ? (
                                                cancelOrders.map((item, i) => {
                                                  var total =
                                                    item.ordertype == "Stop"
                                                      ? +item.filledAmount *
                                                        +item.stoporderprice
                                                      : +item.filledAmount *
                                                        +item.price;
                                                  var price =
                                                    item.ordertype == "Stop"
                                                      ? +item.stoporderprice
                                                      : +item.price;
                                                  return (
                                                    <tr className="history_tabel_bottom_border">
                                                      <td>
                                                        {item.createddate}
                                                      </td>
                                                      <td>{item.pairName}</td>
                                                      <td>{item.ordertype}</td>
                                                      <td>{item.tradeType}</td>
                                                      <td>
                                                        {item.filledAmount}{" "}
                                                        {item.firstSymbol}
                                                      </td>
                                                      <td>
                                                        {price} {item.toSymbol}
                                                      </td>
                                                      <td>
                                                        {total} {item.toSymbol}
                                                      </td>
                                                      <td>
                                                        <span className="text-red">
                                                          Cancelled
                                                        </span>
                                                      </td>
                                                    </tr>
                                                  );
                                                })
                                              ) : (
                                                <td colSpan={9}>
                                                  <div className="empty_data">
                                                    <div className="empty_data_img">
                                                      <img
                                                        src={require("../images/No-data.png")}
                                                      />
                                                    </div>

                                                    <div className="no_records_text">
                                                      No Records Found
                                                    </div>
                                                  </div>
                                                </td>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        {cancelOrders &&
                                        cancelOrders.length > 0 ? (
                                          <div className="pagination">
                                            <Stack spacing={2}>
                                              <Pagination
                                                count={Math.ceil(
                                                  totalcan / recordPerPagecan
                                                )}
                                                page={currentPagecan}
                                                onChange={cancelPageChange}
                                                z
                                                renderItem={(item) => (
                                                  <PaginationItem
                                                    slots={{
                                                      previous: ArrowBackIcon,
                                                      next: ArrowForwardIcon,
                                                    }}
                                                    {...item}
                                                  />
                                                )}
                                              />
                                            </Stack>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div
                                        id="deposit"
                                        role="tabpanel"
                                        className={`tab-pane fade ${
                                          mytrades === "deposit"
                                            ? "show active"
                                            : ""
                                        }`}
                                        aria-labelledby="deposit-tab"
                                      >
                                        <div className="p2p_table table-responsive deposite_tabel_border">
                                          <table class="table   market">
                                            <thead>
                                              <tr className="tabel_bg">
                                                <th
                                                  scope="col"
                                                  className="starts"
                                                >
                                                  Date
                                                </th>
                                                <th scope="col">Pair</th>
                                                <th scope="col">Side</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Stop Price</th>
                                                <th scope="col">Limit Price</th>
                                                <th scope="col">Total</th>
                                                <th scope="col">Status</th>
                                                <th
                                                  scope="col"
                                                  className="ends"
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {stopOrders.length > 0 ? (
                                                stopOrders.map((item, i) => {
                                                  var dates = Moment(
                                                    item.createddate
                                                  ).format(
                                                    "DD.MM.YYYY hh:mm a"
                                                  );
                                                  return (
                                                    <tr className="history_tabel_bottom_border">
                                                      <td>{dates}</td>
                                                      <td>{item.pairName}</td>
                                                      <td>{item.tradeType}</td>
                                                      <td>
                                                        {parseFloat(
                                                          item.filledAmount
                                                        ).toFixed(8)}{" "}
                                                        {item.firstSymbol}
                                                      </td>
                                                      <td>
                                                        {parseFloat(
                                                          item.price
                                                        ).toFixed(8)}{" "}
                                                        {item.toSymbol}
                                                      </td>
                                                      <td>
                                                        {parseFloat(
                                                          item.stoporderprice
                                                        ).toFixed(8)}{" "}
                                                        {item.toSymbol}
                                                      </td>
                                                      <td>
                                                        {parseFloat(
                                                          item.filledAmount *
                                                            item.stoporderprice
                                                        ).toFixed(8)}
                                                      </td>
                                                      <td>
                                                        {item.tradeType ==
                                                        "buy" ? (
                                                          <span className="text-green">
                                                            {item.status}
                                                          </span>
                                                        ) : (
                                                          <span className="text-red">
                                                            {item.status}
                                                          </span>
                                                        )}
                                                      </td>
                                                      <td>
                                                        <Button
                                                          onClick={() =>
                                                            orderCancel(item)
                                                          }
                                                        >
                                                          Cancel
                                                        </Button>
                                                      </td>
                                                    </tr>
                                                  );
                                                })
                                              ) : (
                                                <td colSpan={9}>
                                                  <div className="empty_data">
                                                    <div className="empty_data_img">
                                                      <img
                                                        src={require("../images/No-data.png")}
                                                      />
                                                    </div>

                                                    <div className="no_records_text">
                                                      No Records Found
                                                    </div>
                                                  </div>
                                                </td>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        {stopOrders && stopOrders.length > 0 ? (
                                          <div className="pagination">
                                            <Stack spacing={2}>
                                              <Pagination
                                                count={Math.ceil(
                                                  totalStop / recordPerPageStop
                                                )}
                                                page={currentPagestop}
                                                onChange={orderstopactive}
                                                renderItem={(item) => (
                                                  <PaginationItem
                                                    slots={{
                                                      previous: ArrowBackIcon,
                                                      next: ArrowForwardIcon,
                                                    }}
                                                    {...item}
                                                  />
                                                )}
                                              />
                                            </Stack>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      )}
      {/* <Footer /> */}
    </main>
  );
}

export default Home;
