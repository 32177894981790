import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    setActiveLink(path);
  }, [location]);

  const isActive = (path) => {
    return activeLink === path ? "active-link" : "";
  };
  return (
    <>
      <section className="side_bar_section side_bar_section2">
        <Link
          to="/wallets"
          className={`dashboard_title ${isActive("wallets")}`}
        >
          <div className="dash_title ">
            <img
              src={require("../images/landing_new/wallet.png")}
              className="dashboard_icon"
            />
            <h3 className="dash_title_text">Wallets</h3>
          </div>
        </Link>
        <Link
          to="/deposit"
          className={`dashboard_title ${isActive("deposit")}`}
        >
          <div className="dash_title">
            <img
              src={require("../images/landing_new/Deposit.png")}
              className="dashboard_icon"
            />
            <h3 className="dash_title_text">Deposit</h3>
          </div>
        </Link>
        
        <Link
          to=""
          className={`dashboard_title ${isActive("voucher")}`}
        >
          {" "}
          <div className="dash_title  ">
            <img
              src={require("../images/landing_new/voucher.png")}
              className="dashboard_icon support_icon"
            />
            <h3 className="dash_title_text">Voucher</h3>
          </div>
        </Link>
        <Link
          to="/tradeHistory"
          className={`dashboard_title ${isActive("tradeHistory")}`}
        >
          <div className="dash_title">
            <img
              src={require("../images/landing_new/Order.png")}
              className="dashboard_icon"
            />
            <h3 className="dash_title_text">Orders</h3>
          </div>
        </Link>
        <Link
          to=""
          className={`dashboard_title ${isActive("Trading")}`}
        >
          <div className="dash_title  ">
            <img
              src={require("../images/landing_new/Trding fees.png")}
              className="dashboard_icon support_icon"
            />
            <h3 className="dash_title_text">Trading Fees</h3>
          </div>
        </Link>
        <Link
          to=""
          className={`dashboard_title ${isActive("Trading")}`}
        >
          <div className="dash_title  ">
            <img
              src={require("../images/landing_new/Transation.png")}
              className="dashboard_icon support_icon"
            />
            <h3 className="dash_title_text">Transactions</h3>
          </div>
        </Link>
      </section>
    </>
  );
};

export default Sidebar;
