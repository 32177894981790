import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Home_header.js";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import Moment from "moment";
import { Bars } from "react-loader-spinner";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-dropdown/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import { Tooltip } from "react-tooltip";

function P2p() {
  useEffect(() => {
    getp2pstatus();
  }, []);
  const [p2pstatus, setp2pstatus, p2pstatusref] = useState("");
  const getp2pstatus = async () => {
    setSiteLoader(true);
    try {
      const data = {
        apiUrl: apiService.getP2Pstatus,
      };

      const response = await getMethod(data);
      setSiteLoader(false);

      if (response.status) {
        setp2pstatus(response.p2pstatus);
      }
    } catch (err) {
      console.error("Error fetching currencies:", err);
    }
  };

  const navigate = useNavigate();

  const [p2pOrders, setP2POrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectPayment, setselectPayment] = useState("");
  const [amount, setAmount] = useState("");
  const [orderType, setOrderType] = useState("buy");
  const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
  const [fiatCurrencies, setFiatCurrencies] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState("");
  const [selectedFiat, setSelectedFiat] = useState("");
  const [profileData, setProfileData, profileDataRef] = useState("");
  const [isBuy, setIsBuy, isBuyref] = useState("");
  const [siteLoader, setSiteLoader] = useState(true);
  const [isIndexVal, setIsIndexVal] = useState("");

  const preferPayment = [
    { value: "All Payment", text: "All Payment" },
    { key: "imps", text: "IMPS", value: "IMPS" },
    { key: "upid", text: "UPID", value: "UPID" },
    { key: "paytm", text: "Paytm", value: "Paytm" },
    { key: "bankTransfer", text: "Bank Transfer", value: "BankTransfer" },
  ];

  useEffect(() => {
    getAllP2POrders();
    getAllCurrency();
    getProfile();
  }, []);

  useEffect(() => {
    filterOrders();
  }, [
    paymentMethod,
    orderType,
    selectedCrypto,
    selectedFiat,
    p2pOrders,
    amount,
  ]);

  const getAllP2POrders = async () => {
    try {
      setSiteLoader(true);
      const data = {
        apiUrl: apiService.p2pGetOrder,
        payload: {
          currency: selectedCrypto || selectedFiat,
        },
      };
      const resp = await postMethod(data);
      if (resp.status) {
        setP2POrders(resp.Message);
        setFilteredOrders(resp.Message);

        console.log(resp, "resp");
      }
      setSiteLoader(false);
    } catch (error) {
      console.error("Error fetching P2P orders:", error);
    }
  };

  const getAllCurrency = async () => {
    setSiteLoader(true);

    try {
      const data = { apiUrl: apiService.getP2Pcurrency };
      const resp = await getMethod(data);
      if (resp && resp.data) {
        const cryptoArray = resp.data
          .filter((currency) => currency.coinType === "1")
          .map((currency) => ({
            key: currency._id,
            text: currency.currencySymbol,
            value: currency.currencySymbol,
            image: {
              avatar: true,
              src: currency.Currency_image,
            },
          }));

        const fiatArray = resp.data
          .filter((currency) => currency.coinType === "2")
          .map((currency) => ({
            key: currency._id,
            text: currency.currencySymbol,
            value: currency.currencySymbol,
            image: {
              avatar: true,
              src: currency.Currency_image,
            },
          }));

        setCryptoCurrencies(cryptoArray);
        setFiatCurrencies(fiatArray);
        setSiteLoader(false);
      }
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  };

  const getProfile = async () => {
    try {
      setSiteLoader(true);

      const data = { apiUrl: apiService.getUserDetails };
      const resp = await getMethod(data);

      if (resp.status) {
        setProfileData(resp.data);
      }
      setSiteLoader(false);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const filterOrders = () => {
    setSiteLoader(true);

    let filtered = p2pOrders;

    if (paymentMethod) {
      filtered = filtered.filter(
        (order) => order.paymentMethod === paymentMethod
      );
    }

    if (orderType) {
      filtered = filtered.filter((order) => order.orderType != orderType);
    }

    if (selectedCrypto) {
      filtered = filtered.filter(
        (order) => order.firstCurrency === selectedCrypto
      );
    }

    if (selectedFiat) {
      console.log(selectedFiat, filtered);
      filtered = filtered.filter(
        (order) => order.secondCurrency == selectedFiat
      );
    }

    if (amount) {
      filtered = filtered.filter((order) => order.price === amount);
    }

    setFilteredOrders(filtered);
    setSiteLoader(false);
  };

  const handleClick = (i, option) => {
    console.log(i, option);
    setIsBuy(option);
    setIsIndexVal(i);
  };

  const [payAmount, setPayAmount] = useState("");
  const [receiveAmount, setReceiveAmount] = useState("");

  const handlePayAmountChange = (e) => {
    const value = e.target.value;
    // Convert to a number for comparison
    const numericValue = parseFloat(value);

    // Validate the amount entered by the user
    if (
      !isNaN(numericValue) &&
      numericValue >= isBuy.fromLimit &&
      numericValue <= isBuy.toLimit
    ) {
      setPayAmount(value);
      // Calculate the receive amount (payAmount * price)
      const calculatedReceiveAmount = numericValue * parseFloat(isBuy.price);
      setReceiveAmount(calculatedReceiveAmount.toFixed(2));
    } else {
      toast.error("Enter the valid quantity");
      setPayAmount(value);
      // Calculate the receive amount (payAmount * price)
      const calculatedReceiveAmount = numericValue * parseFloat(isBuy.price);
      setReceiveAmount(calculatedReceiveAmount.toFixed(2));
    }
  };

  const handleCancel = () => {
    setPayAmount("");
    setReceiveAmount("");
    setIsIndexVal("");
  };

  const confirm_order_buy = async () => {
    console.log("ijkjknkn");
    setSiteLoader(true);

    try {
      console.log("buy");

      if (payAmount != "" && receiveAmount != "") {
        var obj = {
          qty: payAmount,
          total: receiveAmount,
          orderId: isBuy.orderId,
          type: "buy",
        };

        var data = {
          apiUrl: apiService.p2p_confirm_order,
          payload: obj,
        };
        var resp = await postMethod(data);
        setSiteLoader(false);

        if (resp.status) {
          toast.success(resp.Message);
          navigate(resp.link);
        } else {
          toast.error(resp.Message);
        }
        setSiteLoader(false);
      } else {
        toast.error("Please enter valid quantity");
      }
    } catch (error) {
      console.log(error, "ijknkkijjkijkijmki");
    }
  };

  const confirm_order_sell = async () => {
    console.log("iiknjdnsdnsd");
    setSiteLoader(true);

    try {
      var obj = {};
      obj.qty = payAmount;
      obj.paymentMethod = selectPayment;

      obj.total = receiveAmount;
      obj.orderId = isBuy.orderId;
      obj.type = "sell";

      if (obj.qty != "" && obj.total != "") {
        var data = {
          apiUrl: apiService.p2p_confirm_sellorder,
          payload: obj,
        };
        setSiteLoader(true);

        var resp = await postMethod(data);
        setSiteLoader(false);

        if (resp.status) {
          toast.success(resp.Message);
          navigate(resp.link);
          window.location.href = resp.link;
        } else {
          toast.error(resp.Message);
        }
        setSiteLoader(false);
      } else {
        setSiteLoader(false);

        toast.error("Please enter quantity");
      }
    } catch (error) {
      setSiteLoader(false);
    }
  };

  return (
    <>
      <main className="nextex_p2p_main">
        <section>
          <Header />
        </section>

        {siteLoader == true ? (
          <div className="dashboard_content_section loadercss">
            <Bars
              height="40"
              width="40"
              color="#1062fe"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <section className="nextex_p2p_section">
            <div className="container">
              <div className="nextex_p2p_section_content">
                <ul
                  class="nav nav-tabs p2p_order_tabs"
                  id="myTab"
                  role="tablist"
                >
                  <div className="">
                    <li class="nav-item" role="presentation">
                      <Link to="/p2p">
                        <button class="nav-link active" id="home-tab">
                          <i class="ri-p2p-line"></i> P2P Offers
                        </button>
                      </Link>
                    </li>
                  </div>
                  <div className="p2p_order_tab2">
                    <li class="nav-item" role="presentation">
                      <Link to="/p2porder">
                        <button class="nav-link" id="profile-tab">
                          <i class="ri-file-list-2-line"></i> Orders
                        </button>
                      </Link>
                    </li>
                    <li class="nav-item" role="presentation">
                      <Link to="/p2poffers">
                        <button class="nav-link" id="contact-tab">
                          <i class="ri-file-text-line"></i> My Offers
                        </button>
                      </Link>
                    </li>
                    <li class="nav-item" role="presentation">
                      <Link to="/p2paccount">
                        <button class="nav-link" id="account-tab">
                          <i class="ri-account-box-line"></i> My P2P Account
                        </button>
                      </Link>
                    </li>

                    {p2pstatusref.current == "Deactive" ? (
                      ""
                    ) : (
                      <Link to="/postad">
                        {" "}
                        <li>
                          <button
                            className="create_offer_btn"
                            // type="button"
                            // data-bs-toggle="modal"
                            // data-bs-target="#createModal"
                          >
                            {" "}
                            <i class="ri-add-large-line"></i> Create Offer
                          </button>
                        </li>
                      </Link>
                    )}
                  </div>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabindex="0"
                  >
                    <div>
                      <ul
                        class="nav nav-pills buy_sell_title_card mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <h4 className="want-text">I want to</h4>
                        <div className="buy_sell_tabs">
                          <li class="nav-item" role="presentation">
                            <button
                              class="buy_content_tab nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              onClick={() => setOrderType("buy")}
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Buy
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="sell_content_tab nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              onClick={() => setOrderType("sell")}
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              Sell
                            </button>
                          </li>
                        </div>
                        <div className="your_receive_dropdown">
                          <h4 className="you_receive">You Receive</h4>
                          <Dropdown
                            placeholder="Crypto "
                            fluid
                            selection
                            options={cryptoCurrencies}
                            onChange={(e, { value }) =>
                              setSelectedCrypto(value)
                            }
                          />
                        </div>
                        <div className="your_receive_dropdown ">
                          <h4 className="you_receive">You Pay</h4>

                          <Dropdown
                            placeholder="Fiat "
                            fluid
                            selection
                            options={fiatCurrencies}
                            onChange={(e, { value }) => setSelectedFiat(value)}
                          />
                          {/* <Select
                            options={fiatCurrencies}
                            onChange={(e, { value }) => setSelectedFiat(value)}
                          className="country_dropdown_p2p"
                        /> */}
                        </div>
                        <div className="your_receive_dropdown ">
                          <h4 className="you_receive">Payment</h4>
                          <Dropdown
                            placeholder="All payment method"
                            fluid
                            selection
                            options={preferPayment}
                            onChange={(e, { value }) => setPaymentMethod(value)}
                            value={paymentMethod}
                          />
                        </div>
                        {/* 
                      <div className="top_merchant_checkbox">
                        <input
                          class="PrivateSwitchBase-input css-1m9pwf3"
                          name="topMerchant"
                          type="checkbox"
                          data-indeterminate="false"
                          value="false"
                        />
                        <p>Top Merchant</p>
                      </div> */}
                      </ul>

                      {/* P2P Offers */}
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                          tabindex="0"
                        >
                          <div className="table-responsive table-cont">
                            <table className="table pt-4">
                              <thead>
                                <tr className="stake-head">
                                  <th>Merchant</th>
                                  <th className="table_center_text">Price</th>
                                  <th className="table_center_text">
                                    Available | Limits
                                  </th>
                                  <th className="table_center_text">
                                    Payment Methods
                                  </th>
                                  <th className="table_action">Trade </th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredOrders && filteredOrders.length > 0 ? (
                                  filteredOrders.map((options, i) => (
                                    <tr>
                                      <td className="table-flex">
                                        <div className="table-opt-name">
                                          <h4 className="opt-name font-satoshi font_14">
                                            <span className="optprofile">
                                              {" "}
                                              {options.displayname[0]}
                                            </span>
                                            {options.displayname}
                                            <br />
                                          </h4>
                                          <p className="order_content_text">
                                            {" "}
                                            {`${options.orders_count} Volume | ${options.rating} % Transaction rate`}
                                            {/* <br />
                                        <span> {options.optsubname}</span> */}
                                          </p>
                                        </div>
                                      </td>

                                      <td className="opt-nowrap opt-price font_14 table_center_text pad-left-23">
                                        {options.price}{" "}
                                        <span className="opt-price-span">
                                          {options?.secondCurrency}
                                        </span>
                                      </td>

                                      <td className="opt-term font-satoshi font_14 table_center_text">
                                        {options && options.available_qty}{" "}
                                        {options && options.firstCurrency}
                                        <br />
                                        {options && options.fromLimit} -{" "}
                                        {options && options.toLimit}{" "}
                                        {options && options.firstCurrency}
                                      </td>

                                      <td className="opt-nowrap opt-term font_14 table_center_text pad-left-23">
                                        <span className="opt-pay">
                                          {options && options.paymentMethod}{" "}
                                        </span>
                                      </td>

                                      <td className="opt-btn-flex table_action">
                                        {options.user_id ==
                                          profileDataRef.current._id ? (
                                          <Link
                                            className="action_btn"
                                            to={`/p2p/order/${options.orderId}`}
                                          >
                                            View
                                          </Link>
                                        ) : (
                                          <buton
                                            className="action_btn"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#buy_usdt_modal"
                                            onClick={() =>
                                              handleClick(i, options)
                                            }
                                          >
                                            Buy
                                          </buton>
                                        )}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colspan="5">
                                      {" "}
                                      <div className="no data">
                                        <img
                                          src={require("../images/landing_new/no_data_asset.png")}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                          tabindex="0"
                        >
                          <div className="table-responsive table-cont">
                            <table className="table pt-4">
                              <thead>
                                <tr className="stake-head">
                                  <th>Merchant</th>
                                  <th className="table_center_text">Price</th>
                                  <th className="table_center_text">
                                    Available | Limits
                                  </th>
                                  <th className="table_center_text">
                                    Payment Methods
                                  </th>
                                  <th className="table_action">Trade </th>
                                </tr>
                              </thead>

                              <tbody>
                                {filteredOrders && filteredOrders.length > 0 ? (
                                  filteredOrders.map((options, i) => (
                                    <tr>
                                      <td className="table-flex">
                                        <div className="table-opt-name">
                                          <h4 className="opt-name font-satoshi font_14">
                                            <span className="optprofile">
                                              {" "}
                                              {options.displayname[0]}
                                            </span>
                                            {options.displayname}
                                            <br />
                                          </h4>
                                          <p className="order_content_text">
                                            {" "}
                                            {`${options.orders_count} Volume | ${options.rating} % Transaction rate`}
                                            {/* <br />
                                        <span> {options.optsubname}</span> */}
                                          </p>
                                        </div>
                                      </td>

                                      <td className="opt-nowrap opt-price font_14 table_center_text pad-left-23">
                                        {options.price}{" "}
                                        <span className="opt-price-span">
                                          {options?.secondCurrency}
                                        </span>
                                      </td>

                                      <td className="opt-term font-satoshi font_14 table_center_text">
                                        {options && options.available_qty}{" "}
                                        {options && options.firstCurrency}
                                        <br />
                                        {options && options.fromLimit} -{" "}
                                        {options && options.toLimit}{" "}
                                        {options && options.firstCurrency}
                                      </td>

                                      <td className="opt-nowrap opt-term font_14 table_center_text pad-left-23">
                                        <span className="opt-pay">
                                          {options && options.paymentMethod}{" "}
                                        </span>
                                      </td>

                                      <td className="opt-btn-flex table_action">
                                        {options.user_id ==
                                          profileDataRef.current._id ? (
                                          <Link
                                            className="action_btn Sell_btn"
                                            to={`/p2p/order/${options.orderId}`}
                                          >
                                            View
                                          </Link>
                                        ) : (
                                          <buton
                                            className="action_btn Sell_btn"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#buy_usdt_modal"
                                            onClick={() =>
                                              handleClick(i, options)
                                            }
                                          >
                                            Sell
                                          </buton>
                                        )}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colspan="8">
                                      {" "}
                                      <div className="no data">
                                        <img
                                          src={require("../images/landing_new/no_data_asset.png")}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}

                                {/* {filteredOrders.map((options, i) => (

                                

                              ))} */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Orders */}
                </div>
              </div>
            </div>
          </section>
        )}

        {/*<!--Buy USDT Modal--->  */}
        <div
          class="modal fade p2p-buy-modal"
          id="buy_usdt_modal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title-deposit" id="exampleModalLabel">
                  {orderType == "buy" ? "Buy" : "Sell"}
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div>
                  {isBuyref.current != "" ? (
                    <div className="row">
                      <div className="col-lg-7">
                        <div className="buy_popup_content">
                          <div className="table-opt-name">
                            <h4 className="opt-name font-satoshi font_14">
                              <span className="optprofile">
                                {" "}
                                {isBuyref.current?.displayname[0]}
                              </span>
                              {isBuyref.current?.displayname}
                              <br />
                            </h4>
                            <p className="order_content_text">
                              {" "}
                              {`${isBuyref.current.orders_count} Volume | ${isBuyref.current.rating} % Transaction rate`}
                              {/* <br />
                                        <span> {options.optsubname}</span> */}
                            </p>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="Inr_price">
                                <h4>
                                  {isBuyref.current.price}{" "}
                                  {isBuyref.current?.secondCurrency}
                                </h4>
                                <p>Price</p>
                              </div>
                              <div className="Inr_price">
                                <h4>{isBuyref.current.pay_duration} min</h4>
                                <p>Payment Time Limit</p>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="Inr_price">
                                <h4 className="payment_method">
                                  {isBuyref.current.paymentMethod}
                                </h4>
                                <p>Payment Method</p>
                              </div>
                              <div className="Inr_price">
                                <h4>
                                  {" "}
                                  {isBuyref.current.available_qty}{" "}
                                  {isBuyref.current.firstCurrency}
                                </h4>
                                <p>Available</p>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="Inr_price">
                                <h4>
                                  {" "}
                                  {isBuyref.current &&
                                    isBuyref.current.fromLimit}{" "}
                                  -{" "}
                                  {isBuyref.current && isBuyref.current.toLimit}{" "}
                                  {isBuyref.current &&
                                    isBuyref.current.firstCurrency}
                                </h4>
                                <p>Limit</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="quantity_buy_card">
                          <h3>
                            Enter quantity to{" "}
                            {orderType == "buy" ? "Buy" : "Sell"}{" "}
                          </h3>
                          <div className="enter_amt_usdt">
                            <input
                              id="quantity-sell"
                              type="text"
                              placeholder="Enter Amount"
                              value={payAmount}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (
                                  value.length <= 30 &&
                                  /^[0-9]*$/.test(value)
                                ) {
                                  handlePayAmountChange(e);
                                }
                              }}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                            />
                            <p>{isBuyref.current.firstCurrency}</p>
                          </div>
                          <h3>You will pay</h3>
                          <div className="enter_amt_usdt">
                            <input
                              type="number"
                              value={receiveAmount}
                              readOnly
                              placeholder="0.00"
                            />
                          </div>
                          <div className="p2p_buy_cancel_btns">
                            <button
                              onClick={handleCancel}
                              className="btnn_cancel"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              Cancel
                            </button>

                            {orderType == "buy" ? (
                              <Link
                                type="submit"
                                onClick={() => confirm_order_buy()}
                                className="p2p_buy_new"
                              >
                                Buy
                                {isBuyref.current.firstCurrency}
                              </Link>
                            ) : (
                              <button
                                className="btnn_sell"
                                onClick={() => confirm_order_sell()}
                              >
                                Sell {isBuyref.current.firstCurrency}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default P2p;
