import React, { useEffect } from "react";
import Home_header from "./Home_header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

function Home() {
  return (
    <main className="dashboard_page">
      <section>
        <Home_header />
      </section>

      <section className="dashboard_content_section">
        <div className="container">
          <div className="smart_dashboard_content">
            <h2>
              Net1Ex –<span>Risk Disclosure</span>{" "}
            </h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_board_profile_content">
                  <div className="contentpage">
                    <h4>Dear Valued Net1Ex Users,</h4>

                    <p>
                      We want to emphasize the importance of understanding the
                      substantial risks associated with cryptocurrency trading.
                      The cryptocurrency market is inherently volatile and
                      unpredictable. Before engaging in any cryptocurrency
                      transactions, it is crucial that you carefully assess your
                      risk tolerance and make informed decisions.
                    </p>

                    <h4>
                      Key Points to Consider when Trading Cryptocurrencies on
                      the Net1Ex Platform:
                    </h4>

                    <h5>KYC Requirements:</h5>
                    <p>
                      Services such as Spot, Savings, and vote-to-list on the
                      Net1Ex platform are exclusively accessible to verified
                      Net1Ex users who have completed Know Your Customer (KYC)
                      verification.
                    </p>

                    <h5>Complete KYC:</h5>
                    <p>
                      It is imperative for users to complete their KYC
                      verification and provide accurate personal information.
                    </p>

                    <h5>Account Security:</h5>
                    <p>
                      Safeguard your account and password diligently. Never
                      share your account information or password with anyone
                      else. Any losses incurred due to negligence in this regard
                      will be the user's sole responsibility.
                    </p>

                    <h4>Risk Categories:</h4>

                    <h5>Policy Risk:</h5>
                    <p>
                      Changes in national laws, regulations, or macro-policies
                      can impact cryptocurrency transactions and potentially
                      lead to losses.
                    </p>

                    <h5>Compliance Risk:</h5>
                    <p>
                      Violation of national laws or regulations during
                      cryptocurrency transactions can result in losses.
                    </p>

                    <h5>Market Risk:</h5>
                    <p>
                      Cryptocurrency markets operate around the clock, and
                      prices can fluctuate significantly. Users should be
                      prepared for both positive and negative returns.
                    </p>

                    <h5>Trading Risk:</h5>
                    <p>
                      Successful transactions require mutual agreement between
                      parties; Net1Ex does not guarantee transaction success.
                    </p>

                    <h5>Force Majeure Risk:</h5>
                    <p>
                      Unforeseeable events like natural disasters, cyber
                      attacks, or other unavoidable circumstances can disrupt
                      Net1Ex operations and lead to user losses. Net1Ex bears no
                      civil liability for losses incurred under such conditions.
                    </p>

                    <h5>Delisting Risk:</h5>
                    <p>
                      Delisting may occur due to various reasons, potentially
                      causing user losses.
                    </p>

                    <h5>Technical Risk:</h5>
                    <p>
                      Though rare, technical faults during cryptocurrency
                      transactions can impact user interests.
                    </p>

                    <h5>Operation Risk:</h5>
                    <p>
                      Operational errors, such as transferring funds to the
                      wrong account, can result in losses.
                    </p>

                    <h5>Account Frozen Risk:</h5>
                    <p>
                      Accounts may be frozen or confiscated by judicial
                      institutions in case of debt defaults or alleged crimes.
                    </p>

                    <h5>Prohibited Actions on Net1Ex:</h5>

                    <p>
                      Money laundering, fraudulent transactions, illegal
                      fundraising, and fraud are strictly prohibited.
                      Registering under false identities or providing fraudulent
                      KYC information will lead to account suspension, asset
                      freezing, or termination.
                    </p>

                    <p>
                      This risk disclosure does not cover all potential risks in
                      the cryptocurrency market. It is your responsibility to
                      thoroughly understand transaction rules, promotions,
                      agreements, and this risk disclosure before engaging in
                      cryptocurrency transactions.
                    </p>

                    <p>
                      Users are solely responsible for bearing trading risks on
                      Net1Ex. Net1Ex does not make any promises or guarantees
                      regarding user losses or gains.
                    </p>

                    <h5>User Commitment:</h5>

                    <p>
                      By using Net1Ex, you agree to the terms outlined in this
                      Risk Disclosure Agreement. Your commitment to adhering to
                      the national and regional laws and regulations of
                      Kyrgyzstan, and your registered residence. Any violations
                      or conduct triggering Net1Ex's risk-control measures may
                      result in account suspension, investigation, and legal
                      consequences, with all associated losses and liabilities
                      borne by the user.
                    </p>

                    <h5>Reminder:</h5>

                    <p>
                      Please be aware that by clicking to agree to this risk
                      disclosure, you acknowledge your understanding and
                      acceptance of cryptocurrency transaction risks and
                      potential losses. Net1Ex is not obligated to refund
                      cryptocurrency principal or interests or assume any other
                      responsibilities.
                    </p>

                    <p>
                      Thank you for choosing Net1Ex, and please trade
                      responsibly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
}

export default Home;
