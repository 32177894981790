import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Home_header.js";
import { Bars } from "react-loader-spinner";
import "semantic-ui-css/semantic.min.css";
import Moment from "moment";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";

function P2p() {
  useEffect(() => {
    getp2pstatus();
  }, []);
  const [p2pstatus, setp2pstatus, p2pstatusref] = useState("");
  const getp2pstatus = async () => {
    setPageloader(true);

    try {
      const data = {
        apiUrl: apiService.getP2Pstatus,
      };

      const response = await getMethod(data);
      setPageloader(false);

      if (response.status) {
        setp2pstatus(response.p2pstatus);
      }
    } catch (err) {
      console.error("Error fetching currencies:", err);
    }
  };

  const [pageLoader, setPageloader] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setPageloader(true);

    setTimeout(() => {
      setPageloader(false);
    }, 3000);
  }, [0]);

  const navpage = async (link) => {
    navigate("/p2p/order/" + link);
  };

  useEffect(() => {
    getp2pHistory(1); // Load first page initially
  }, []);

  const [p2pHistory, setp2pHistory, p2pHistoryref] = useState([]);
  const [historycurrentpage, sethistorycurrentpage, historycurrentpageref] =
    useState(1);
  const [historytotalpage, sethistoryTotalpages, historytotalpageref] =
    useState(0);

  const getp2pHistory = async (page = 1) => {
    setPageloader(true);

    var data = {
      apiUrl: apiService.p2pHistory,
      payload: { limit: 5, page },
    };
    var p2p_orders_list = await postMethod(data);
    setPageloader(false);

    if (p2p_orders_list.status) {
      setp2pHistory(p2p_orders_list.returnObj.Message);
      sethistoryTotalpages(Math.ceil(p2p_orders_list.returnObj.total / 5)); // Adjust total pages calculation

      console.log(p2pHistoryref.current, "p2p_orders_list");
    }
  };

  const handlePageChange2 = (event, value) => {
    sethistorycurrentpage(value);
    getp2pHistory(value); // Fetch data for the selected page
  };

  return (
    <>
      <main className="nextex_p2p_main">
        <section>
          <Header />
        </section>

        {pageLoader == true ? (
          <div className="dashboard_content_section loadercss">
            <Bars
              height="40"
              width="40"
              color="#1062fe"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <section className="nextex_p2p_section">
            <div className="container">
              <div className="nextex_p2p_section_content">
                <ul
                  class="nav nav-tabs p2p_order_tabs"
                  id="myTab"
                  role="tablist"
                >
                  <div className="">
                    <li class="nav-item" role="presentation">
                      <Link to="/p2p">
                        <button class="nav-link " id="home-tab">
                          <i class="ri-p2p-line"></i> P2P Offers
                        </button>
                      </Link>
                    </li>
                  </div>
                  <div className="p2p_order_tab2">
                    <li class="nav-item" role="presentation">
                      <Link to="/p2porder">
                        <button class="nav-link active" id="profile-tab">
                          <i class="ri-file-list-2-line"></i> Orders
                        </button>
                      </Link>
                    </li>
                    <li class="nav-item" role="presentation">
                      <Link to="/p2poffers">
                        <button class="nav-link" id="contact-tab">
                          <i class="ri-file-text-line"></i> My Offers
                        </button>
                      </Link>
                    </li>
                    <li class="nav-item" role="presentation">
                      <Link to="/p2paccount">
                        <button class="nav-link" id="account-tab">
                          <i class="ri-account-box-line"></i> My P2P Account
                        </button>
                      </Link>
                    </li>

                    {p2pstatusref.current == "Deactive" ? (
                      ""
                    ) : (
                      <Link to="/postad">
                        {" "}
                        <li>
                          <button
                            className="create_offer_btn"
                            // type="button"
                            // data-bs-toggle="modal"
                            // data-bs-target="#createModal"
                          >
                            {" "}
                            <i class="ri-add-large-line"></i> Create Offer
                          </button>
                        </li>
                      </Link>
                    )}
                  </div>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="profile-tab-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    tabindex="0"
                  >
                    <div>
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                          tabindex="0"
                        >
                          <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                              <button
                                class="nav-link active"
                                id="orders-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#orders-tab-pane"
                                type="button"
                                role="tab"
                                aria-controls="orders-tab-pane"
                                aria-selected="false"
                              >
                                History
                              </button>
                            </li>
                          </ul>
                          <div class="tab-content" id="myTabContent">
                            <div
                              class="tab-pane fade show active"
                              id="order-tab-pane"
                              role="tabpanel"
                              aria-labelledby="order-tab"
                              tabindex="0"
                            >
                              <div className="table-responsive table-cont">
                                <table className="table pt-4">
                                  <thead>
                                    <tr className="stake-head">
                                      <th>Currency </th>
                                      <th className="table_center_text">
                                        Date & Time
                                      </th>

                                      <th className="table_center_text">
                                        Price
                                      </th>
                                      <th className="table_center_text">
                                        Fiat Amount
                                      </th>
                                      <th className="table_center_text">
                                        Order Type
                                      </th>

                                      <th className="table_center_text">
                                        Status
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {p2pHistoryref.current &&
                                    p2pHistoryref.current.length > 0 ? (
                                      p2pHistoryref.current.map((item, i) => {
                                        var status = "";
                                        if (item.status == 0)
                                          status = "Confirmed";
                                        if (item.status == 1) status = "Paid";
                                        else if (item.status == 2)
                                          status = "Completed";
                                        else if (item.status == 3)
                                          status = "Cancelled";

                                        return (
                                          <tr
                                            key={i}
                                            onClick={() =>
                                              navpage(item.orderId)
                                            }
                                          >
                                            <td className="table-flex d-flex">
                                              <img
                                                src={
                                                  item.firstCurrency
                                                    .Currency_image
                                                }
                                                width="30px"
                                                height="30px"
                                                alt={
                                                  item.firstCurrency
                                                    .currencyName
                                                }
                                              />
                                              <div className="table-opt-name">
                                                <h4 className="opt-name font_14">
                                                  {
                                                    item.firstCurrency
                                                      .currencyName
                                                  }
                                                </h4>
                                                <h3 className="opt-sub font_14">
                                                  {
                                                    item.firstCurrency
                                                      .currencySymbol
                                                  }
                                                </h3>
                                              </div>
                                            </td>
                                            <td className="opt-percent font_14 table_center_text pad-left-23 text-nowrap">
                                              {Moment(item.datetime).format(
                                                "lll"
                                              )}
                                            </td>
                                            <td className="opt-term font_14 table_center_text pad-left-23">
                                              {parseFloat(
                                                item.askAmount
                                              ).toFixed(4)}
                                            </td>
                                            <td className="opt-term font_14 table_center_text pad-left-23">
                                              {parseFloat(
                                                item.askPrice
                                              ).toFixed(4)}
                                            </td>
                                            <td
                                              className={`opt-term font_14 table_center_text pad-left-23 ${
                                                item.type === "buy"
                                                  ? "text-green"
                                                  : "text-sell-red"
                                              }`}
                                            >
                                              {item.type}
                                            </td>
                                            <td className="opt-btn-flex opt-term table-action pad-left-23">
                                              {status}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colspan="8">
                                          {" "}
                                          <div className="no data">
                                            <img
                                              src={require("../images/landing_new/no_data_asset.png")}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div
                              class="tab-pane fade"
                              id="orders-tab-pane"
                              role="tabpanel"
                              aria-labelledby="orders-tab"
                              tabindex="0"
                            >
                              <div className="table-responsive table-cont">
                                <table className="table pt-4">
                                  <thead>
                                    <tr className="stake-head">
                                      <th>Date Created I Order ID</th>
                                      <th className="table_center_text">
                                        Type
                                      </th>
                                      <th className="table_center_text">
                                        Crypto Amount
                                      </th>
                                      <th className="table_center_text">
                                        Price
                                      </th>
                                      <th className="table_center_text">
                                        Fiat Amount
                                      </th>
                                      <th className="table_center_text">
                                        Status
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {p2pHistoryref.current &&
                                    p2pHistoryref.current.length > 0 ? (
                                      p2pHistoryref.current.map((item, i) => {
                                        var status = "";
                                        if (item.status == 0)
                                          status = "Confirmed";
                                        if (item.status == 1) status = "Paid";
                                        else if (item.status == 2)
                                          status = "Completed";
                                        else if (item.status == 3)
                                          status = "Cancelled";

                                        return (
                                          <tr
                                            key={i}
                                            onClick={() =>
                                              navpage(item.orderId)
                                            }
                                          >
                                            <td className="table-flex d-flex">
                                              <img
                                                src={
                                                  item.firstCurrency
                                                    .Currency_image
                                                }
                                                alt={
                                                  item.firstCurrency
                                                    .currencyName
                                                }
                                                width="30px"
                                                height="30px"
                                              />
                                              <div className="table-opt-name">
                                                <h4 className="opt-name font_14">
                                                  {
                                                    item.firstCurrency
                                                      .currencyName
                                                  }
                                                </h4>
                                                <h3 className="opt-sub font_14">
                                                  {
                                                    item.firstCurrency
                                                      .currencySymbol
                                                  }
                                                </h3>
                                              </div>
                                            </td>
                                            <td className="opt-percent font_14 table_center_text pad-left-23 text-nowrap">
                                              {Moment(item.datetime).format(
                                                "lll"
                                              )}
                                            </td>
                                            <td className="opt-term font_14 table_center_text pad-left-23">
                                              {parseFloat(
                                                item.askAmount
                                              ).toFixed(8)}
                                            </td>
                                            <td className="opt-term font_14 table_center_text pad-left-23">
                                              {parseFloat(
                                                item.askPrice
                                              ).toFixed(2)}
                                            </td>
                                            <td
                                              className={`opt-term font_14 table_center_text pad-left-23 ${
                                                item.type === "buy"
                                                  ? "text-green"
                                                  : "text-sell-red"
                                              }`}
                                            >
                                              {item.type}
                                            </td>
                                            <td className="opt-btn-flex opt-term table-action pad-left-23 text-center">
                                              {status}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colspan="8">
                                          {" "}
                                          <div className="no data">
                                            <img
                                              src={require("../images/landing_new/no_data_asset.png")}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
}

export default P2p;
