import React, { useEffect } from "react";
import { Button } from "@mui/material";
import useState from "react-usestateref";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const [siteData, setSiteData] = useState({});
  const [loginCheck, setloginCheck] = useState(false);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
    fetch_site_data();
  }, [0]);

  const fetch_site_data = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      console.log(resp.data, "=--=-=-resp-=-=-=-=-=resp=-=-");
      setSiteData(resp.data);
    } catch (error) {}
  };

  const initialFormValue = {
    email: "",
  };

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validationnErr, setvalidationnErr] = useState("");

  const { email } = formValue;

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };
  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is a required field!";
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address!";
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9._%+-]*[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
        values.email
      )
    ) {
      errors.email =
        "Invalid email address! Email must contain at least one character.";
      setemailValidate(true);
    } else if (values.email.length > 254) {
      errors.email = "Email address is too long!";
      setemailValidate(true);
    } else {
      setemailValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };
  const addSubscriber = async () => {
    if (emailValidateref.current === false) {
      var data = {
        apiUrl: apiService.addSubscriber,
        payload: formValue,
      };
      var resp = await postMethod(data);
      setFormValue(initialFormValue);
      if (resp.status == true) {
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } else {
    }
  };
  return (
    <section className="smart-footer-section pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="smart-footer-about">
              <h4>About</h4>
              <div className="dark_blue_line"></div>

              <p>
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
              </p>
              <p>
                {" "}
                <Link to="/terms-conditions" target="_blank">
                  Terms Of Condition
                </Link>
              </p>
              <p>
                <Link to="/about" target="_blank">
                  About Us{" "}
                </Link>
              </p>
              <p>
                {" "}
                <Link to="/risk-disclouser" target="_blank">
                  Risk Disclosure{" "}
                </Link>{" "}
              </p>
              <p>
                {" "}
                <Link to="/terms-of-use" target="_blank">
                  Terms Of Use
                </Link>{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="smart-footer-about">
              <h4>Services</h4>
              <div className="dark_blue_line"></div>

              <p>
                {" "}
                <Link to="/trade/NET1_USDN"> Trade</Link>
              </p>
              <p>
                {" "}
                <Link to="/p2p">P2P</Link>
              </p>
              <p>
                {" "}
                <Link to="/staking"> Staking</Link>
              </p>

              {/* <p>Swap</p> */}
            </div>
          </div>
          <div className="col-lg-2">
            {" "}
            <div className="smart-footer-about">
              <h4>Contact Us</h4>
              <div className="dark_blue_line"></div>
              <p>
                <Link to="" target="_blank">
                  Help Center/ FAQ{" "}
                </Link>
              </p>
              {/* <p>
                <Link to=" " target="_blank">
                  Career
                </Link>
              </p>
              <p>
                {" "}
                <Link to="" target="_blank">
                  Submit a Request
                </Link>
              </p> */}
            </div>
            {/* <div className="smart-footer-Update">
              <h4>Stay tuned for Update</h4>
              <div className="smart-footer-input">
                <input
                  type="email"
                  placeholder="your email address"
                  value={email}
                  name="email"
                  onChange={handleChange}
                />
                <button onClick={addSubscriber} className="smart-footer-input-btn"> Subscribe</button>
              </div>
              {emailValidateref.current == true ? (
                <p className="text-danger mt-2"> {validationnErr.email} </p>
              ) : (
                ""
              )}
              <p className="mt-2">
                * Will send you weekly updates for your better finance
                management.
              </p>
            </div> */}
          </div>
          <div className="col-lg-2">
            {" "}
            <div className="smart-footer-about">
              <h4>Other</h4>
              <div className="dark_blue_line"></div>
              <p>
                <Link to="/listing" target="_blank">
                  Listing
                </Link>
              </p>
              <p>
                <Link to="/token_info" target="_blank">
                  Token Information
                </Link>
              </p>
              <p>
                {" "}
                <Link to="/trust" target="_blank">
                  Trust
                </Link>
              </p>
            </div>
          </div>

          <div className="footer-social-content col-lg-3">
            <h4>Community</h4>
            <div className="dark_blue_line"></div>
            <div className="footer-social-link">
              {" "}
              <Link>
                {" "}
                <img src={require("../images/landing/community-1.webp")} />
              </Link>
              <Link>
                {" "}
                <img src={require("../images/landing/community-2.webp")} />
              </Link>
              <Link>
                {" "}
                <img src={require("../images/landing/community-3.webp")} />
              </Link>
              <Link>
                {" "}
                <img src={require("../images/landing/community-4.webp")} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right-text-foot row">
        <div className="copy-right-text-footer col-lg-3">
          {/* {siteData.copy_right_text} */}
          Copyright ©2024 NetEx. All rights reserved
        </div>
      </div>
    </section>
  );
};

export default Footer;
