import React, { useEffect } from "react";
import Home_header from "./Home_header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

function Home() {
  return (
    <main className="dashboard_page">
      <section>
        <Home_header />
      </section>

      <section className="dashboard_content_section">
        <div className="container">
          <div className="smart_dashboard_content">
            <h2>
              Net1Ex –<span>Token Information</span>{" "}
            </h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_board_profile_content">
                  <div className="contentpage">
                    <h4>BTC – Bitcoin </h4>

                    <p>
                      Bitcoin is the first decentralized cryptocurrency, created
                      in 2008 by the pseudonymous Satoshi Nakamoto. It enables
                      peer-to-peer transactions without intermediaries, using
                      blockchain technology to ensure transparency and security.
                      With a capped supply of 21 million coins, Bitcoin is often
                      seen as "digital gold" and a hedge against inflation. Its
                      decentralized nature and limited supply have made it a
                      popular store of value. Despite price volatility and
                      regulatory challenges, Bitcoin remains the most valuable
                      cryptocurrency, pioneering the way for digital assets and
                      transforming how we view money and financial systems.
                    </p>

                    <div className="Supply_content">
                      <div>
                        <p>Circulating Supply:</p>
                        <p>Total Supply: </p>
                        <p>Whitepaper:</p>
                        <p>Website:</p>
                        <p>Explorer : </p>
                      </div>
                      <div>
                        <p>19,259,006</p>
                        <p>21,000,000</p>
                        <p>
                          <a href="https://bitcoin.org/bitcoin.pdf">
                            https://bitcoin.org/bitcoin.pdf
                          </a>
                        </p>
                        <p>
                          <a href="https://bitcoin.org/en ">
                            https://bitcoin.org/en{" "}
                          </a>
                        </p>
                        <p>
                          <a href="  https://blockchair.com/bitcoin ">
                            {" "}
                            https://blockchair.com/bitcoin{" "}
                          </a>
                        </p>
                      </div>
                    </div>
                    <h4>NET1 – Network1 </h4>

                    <p>
                      Network1 is a blockchain ecosystem designed for fast,
                      scalable, and secure transactions. It aims to provide a
                      robust platform for decentralized applications (dApps),
                      smart contracts, and digital assets. Network1's
                      architecture focuses on low transaction costs and high
                      throughput, making it ideal for both developers and users.
                      With a strong emphasis on security and interoperability,
                      it supports seamless integration with other blockchain
                      networks. Its native stablecoin, NUSD, facilitates
                      transactions within the ecosystem, offering stability and
                      ease of use. Network1 strives to enhance the blockchain
                      experience by combining efficiency, accessibility, and
                      innovation in one platform.
                    </p>

                    <div className="Supply_content">
                      <div>
                        <p>Circulating Supply: </p>
                        <p>Total Supply: </p>
                        <p>Whitepaper:</p>
                        <p>Website:</p>
                        <p>Explorer : </p>
                      </div>
                      <div>
                        <p>-</p>
                        <p>21,000,000</p>
                        <p>
                          <a href="https://networkchain.gitbook.io/network1-whitepaper ">
                            https://networkchain.gitbook.io/network1-whitepaper
                          </a>
                        </p>
                        <p>
                          <a href="https://network1.tech">
                            https://network1.tech
                          </a>
                        </p>
                        <p>
                          <a href="   https://net1scan.com ">
                            {" "}
                            https://net1scan.com{" "}
                          </a>
                        </p>
                      </div>
                    </div>
                    <h4>USDT – Tether </h4>

                    <p>
                      Tether (USDT) is a popular stablecoin pegged to the US
                      dollar, designed to maintain a 1:1 value ratio. It
                      provides the benefits of cryptocurrency, like fast
                      transactions and blockchain security, while minimizing the
                      volatility seen in other digital assets. Tether is widely
                      used for trading, remittances, and as a safe haven during
                      market fluctuations. It operates on multiple blockchains,
                      including Ethereum and Tron, enhancing its accessibility
                      and liquidity. By combining the stability of fiat with the
                      advantages of digital currencies, Tether has become a
                      crucial tool for traders and investors in the crypto
                      ecosystem.
                    </p>

                    <div className="Supply_content">
                      <div>
                        <p>Circulating Supply: </p>
                        <p>Total Supply: </p>

                        <p>Website:</p>
                        <p>Explorer : </p>
                      </div>
                      <div>
                        <p>118,225,843,000</p>
                        <p>-</p>

                        <p>
                          <a href="https://tether.to/en  ">
                            https://tether.to/en
                          </a>
                        </p>
                        <p>
                          <a href=" https://tether.to/en/transparency/?tab=usdt ">
                            https://tether.to/en/transparency/?tab=usdt
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
}

export default Home;
