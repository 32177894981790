import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Moment from "moment";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { toast } from "react-toastify";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { socket } from "./context/socket";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import {
  removeAuthToken,
  getAuthToken,
  getSocketToken,
} from "../core/lib/localStorage";
import { removeAuthorization } from "../core/service/axios";
import { ModalBody, ModalFooter } from "reactstrap";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import useState from "react-usestateref";
import { Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";

const Header = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [modal1, setModal1] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const drawerWidth = 240;
  const [profileData, setprofileData] = useState("");
  const [isOpen, setIsOpen, isOpenref] = useState(true);
  const [notifications, setNotification] = React.useState("");
  const [notification, setnotification] = useState("");

  const [loginCheck, setloginCheck] = useState(false);
  const goto1 = () => setModal1(!modal1);
  const [dropstatus, setdropstatus] = useState(false);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [isBuy, setIsBuy] = useState(false);
  const [isTrade, setIsTrade] = useState(false);
  // const [isBuy, setIsBuy] = useState(false);

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };
  const classes = useState();
  const dropdowns = async () => {
    if (dropstatus == true) {
      setdropstatus(false);
    } else {
      setdropstatus(true);
    }
  };

  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      if (resp.data.undermaintenenceStatus == "DeActive") {
        navigate("/undermaintanance");
        localStorage.setItem("sitestats", resp.data.undermaintenenceStatus);
      } else {
      }
    } catch (error) {}
  };
  const fetchTheme = () => {
    const theme = localStorage.getItem("theme");
    if (theme !== null) {
      if (theme === "dark") {
        setIsOpen(true);
        document.body.classList.add("dark");
        document.body.classList.remove("light");
      } else {
        setIsOpen(false);
        document.body.classList.add("light");
        document.body.classList.remove("dark");
      }
    } else {
      localStorage.setItem("theme", "dark");
      setIsOpen(true);
      document.body.classList.add("dark");
    }
  };
  const getNotifications = async () => {
    try {
      var data = {
        apiUrl: apiService.getnotifications,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setNotification(resp.Message);
      } else {
      }
    } catch (error) {}
  };

  const getnotify = async () => {
    try {
      var data = {
        apiUrl: apiService.getnotification,
      };
      var resp = await postMethod(data);

      if (resp.status) {
        setnotification(resp.data.data);
        console.log(notification);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchTfaData();
    getnotify();

    socket.on("sitesettings", function (res) {
      console.log(res);

      fetchTfaData();
    });
    fetchTheme();

    let token_socket = localStorage.getItem("socket_token");
    if (token_socket) {
      socket.connect();
      let socketToken = token_socket.split("_")[0];
      socket.on("socketResponse" + socketToken, function (res) {
        console.log("notify response====", res);
        if (res.Reason == "notify") {
          getNotifications();
        }

        if (res.Message == "account_deactivate") {
          toast.error("Your account deactivated by admin");
          logout();
        }
      });
    }

    let userToken = localStorage.getItem("user_token");

    if (userToken) {
      setloginCheck(true);
      getNotifications();
    } else {
      setloginCheck(false);
    }
  }, [0]);
  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const toggle1 = () => {
    setModal1(!modal1);
  };
  const zIndex1 = 2000000;

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);

      if (resp.status == true) {
        setprofileData(resp.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");

    if (userToken != null) {
      getProfile();
    }
  }, [0]);

  const logout = async () => {
    var data = {
      apiUrl: apiService.logout,
    };
    var resp = await getMethod(data);
    if (resp) {
      await removeAuthorization();
      await removeAuthToken();
      localStorage.clear();
      navigate("/login");
    }
  };

  const toggleTheme = () => {
    const newTheme = isOpen ? "light" : "dark";
    localStorage.setItem("theme", newTheme);
    window.location.reload(); // newly added to resolve recaptcha theme change
    setIsOpen(!isOpen);

    if (newTheme === "dark") {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }

    var pathsplit = window.location.pathname.split("/");

    if (pathsplit.length > 0) {
      if (pathsplit[1] == "trade") {
        socket.emit("loadchart", newTheme);
      }
    }
  };

  useEffect(() => {
    isOpenref.current = isOpen;
  }, [isOpen]);

  const logout_website = async () => {
    try {
      var data = {
        apiUrl: apiService.logout_website,
      };
      var resp = await postMethod(data);
      if (resp) {
        window.location.href = "/login";
        await removeAuthorization();
        await removeAuthToken();
        localStorage.clear();
      }
    } catch (err) {}
  };

  const handleBuy = () => {
    setIsBuy((prevBuy) => !prevBuy);
  };

  const handleTrade = () => {
    setIsTrade((prevTrade) => !prevTrade);
  };

  return (
    <section className="smart-header-section">
      <React.Fragment>
        <AppBar sx={{ background: "#fff" }} className="header_padidng">
          <Toolbar sx={{ height: "51px" }}>
            <div className="imag smart-header-nav-list1">
              <Link className="nav_brand" to="/">
                <img
                  src={require("../images/NextEx_Logo.png")}
                  className="smart_bull__navlogo"
                />
                <img
                  src={require("../images/Netex_dark.png")}
                  className="netex_dark_logo"
                />
              </Link>

              {loginCheck == false ? (
                <div id="dash">
                  <div className="buy_crypto_dropdown">
                    <div class="dropdown" id="dash">
                      <button
                        class="dropdown-toggle buy_crypto_toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Buy Crypto
                      </button>
                      <ul
                        class="dropdown-menu header_buy_dropdown"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li>
                          <a class="dropdown-item buy_w_card" href="#">
                            <img
                              src={require("../images/landing_new/Buy_with_card.png")}
                              className="p2p_icon_drawer2"
                            />
                            <div className="buy_w_card_content">
                              <h3>Buy With Card</h3>
                              <p>Visa ,Mastercard, others</p>
                            </div>
                            <span class="comming_soon">Soon</span>
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item buy_w_card mt-3" href="/p2p">
                            <img
                              src={require("../images/landing_new/header_p2p_icon.png")}
                              className="p2p_icon_drawer2"
                            />
                            <div className="buy_w_card_content">
                              <h3>P2P Trading</h3>
                              <p>
                                Buy & sell crypto with direct fiat transfers
                              </p>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="dropdown" id="dash">
                      <button
                        class="dropdown-toggle buy_crypto_toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Trade
                      </button>
                      <ul
                        class="dropdown-menu header_buy_dropdown"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li>
                          <Link class="dropdown-item buy_w_card">
                            <img
                              src={require("../images/landing_new/swap_icon.png")}
                              className="p2p_icon_drawer2"
                            />
                            <div className="buy_w_card_content">
                              <h3>Convert</h3>
                              <p>
                                Easy & fast exchange, low fetchTradeHisotory
                              </p>
                            </div>
                            <span class="comming_soon">Soon</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/trade/NET1_USDT"
                            class="dropdown-item buy_w_card mt-3"
                          >
                            <img
                              src={require("../images/landing_new/trade_icon.png")}
                              className="p2p_icon_drawer2"
                            />
                            <div className="buy_w_card_content">
                              <h3>Spot Trading</h3>
                              <p>Advanced trading mode with chart</p>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <Link to="" id="dash">
                      <div className="buy_crypto_dropdown">
                        <div class="dropdown" id="dash">
                          <button
                            class="dropdown-toggle buy_crypto_toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            More
                          </button>
                          <ul
                            class="dropdown-menu header_buy_dropdown"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li className="more_drop_list">
                              <Link to="/listing">Lisiting</Link>
                            </li>
                            <li className="more_drop_list">
                              <Link to="/token_info">Token Information</Link>
                            </li>
                            <li className="more_drop_list">
                              <Link to="/privacy-policy">Privacy Policy</Link>
                            </li>
                            <li className="more_drop_list">
                              <Link to="/terms-conditions">
                                Terms And Conditions
                              </Link>
                            </li>
                            <li className="more_drop_list">
                              <Link to="/about">About Us</Link>
                            </li>
                            <li className="more_drop_list">
                              <Link to="/trust">Trust</Link>
                            </li>
                            <li className="more_drop_list">
                              <Link to="/terms-of-use">Terms Of Use</Link>
                            </li>
                            <li className="more_drop_list">
                              <Link to="/risk-disclouser">Risk Disclouser</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Link>

                    <Link className="netex-card-link">
                      Net1Ex Card <span className="comming_soon">Soon</span>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="buy_crypto_dropdown">
                  <div className="dropdown" id="dash">
                    <button
                      className="dropdown-toggle buy_crypto_toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Buy Crypto
                    </button>
                    <ul
                      className="dropdown-menu header_buy_dropdown"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <a className="dropdown-item buy_w_card" href="#">
                          <img
                            src={require("../images/landing_new/Buy_with_card.png")}
                            className="p2p_icon_drawer2"
                          />
                          <div className="buy_w_card_content">
                            <h3>Buy With Card</h3>
                            <p>Visa ,Mastercard, others</p>
                          </div>
                          <span className="comming_soon">Soon</span>
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item buy_w_card mt-3" href="/p2p">
                          <img
                            src={require("../images/landing_new/header_p2p_icon.png")}
                            className="p2p_icon_drawer2"
                          />
                          <div className="buy_w_card_content">
                            <h3>P2P Trading</h3>
                            <p>Buy & sell crypto with direct fiat transfers</p>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="dropdown" id="dash">
                    <button
                      className="dropdown-toggle buy_crypto_toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Trade
                    </button>
                    <ul
                      className="dropdown-menu header_buy_dropdown"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <Link className="dropdown-item buy_w_card">
                          <img
                            src={require("../images/landing_new/swap_icon.png")}
                            className="p2p_icon_drawer2"
                          />
                          <div className="buy_w_card_content">
                            <h3>Convert</h3>
                            <p>Easy & fast exchange, low fetchTradeHisotory</p>
                          </div>
                          <span class="comming_soon">Soon</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/trade/NET1_USDN"
                          class="dropdown-item buy_w_card mt-3"
                        >
                          <img
                            src={require("../images/landing_new/trade_icon.png")}
                            className="p2p_icon_drawer2"
                          />
                          <div className="buy_w_card_content">
                            <h3>Spot Trading</h3>
                            <p>Advanced trading mode with chart</p>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <Link to="" id="dash">
                    <div className="buy_crypto_dropdown">
                      <div className="dropdown" id="dash">
                        <button
                          className="dropdown-toggle buy_crypto_toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          More
                        </button>
                        <ul
                          className="dropdown-menu header_buy_dropdown"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li className="more_drop_list">
                            <Link to="/listing">Lisiting</Link>
                          </li>
                          <li className="more_drop_list">
                            <Link to="/token_info">Token Information</Link>
                          </li>
                          <li className="more_drop_list">
                            <Link to="/privacy-policy">Privacy Policy</Link>
                          </li>
                          <li className="more_drop_list">
                            <Link to="/terms-conditions">
                              Terms And Conditions
                            </Link>
                          </li>
                          <li className="more_drop_list">
                            <Link to="/about">About Us</Link>
                          </li>
                          <li className="more_drop_list">
                            <Link to="/trust">Trust</Link>
                          </li>
                          <li className="more_drop_list">
                            <Link to="/terms-of-use">Terms Of Use</Link>
                          </li>
                          <li className="more_drop_list">
                            <Link to="/risk-disclouser">Risk Disclouser</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>

                  <Link className="netex-card-link">
                    Net1Ex Card <span className="comming_soon">Soon</span>
                  </Link>
                </div>
              )}
            </div>

            <div className="nav_link_section mr-auto ml-5">
              {loginCheck == false ? (
                <div id="dash">
                  <Link
                    to="/login"
                    // to="/"
                    className="Butn_header smart-header-nav-login-btn"
                  >
                    Login
                  </Link>
                  <Link
                    to="/register"
                    // to="/"
                    className="Butn_header mr-0 only_border smart-header-nav-reg-btn"
                  >
                    Register
                  </Link>

                  <a
                    to="#"
                    onClick={toggleTheme}
                    className="Butn_header only_border"
                  >
                    {isOpen ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="light-dark"
                          viewBox="0 0 24 24"
                          id="lightmode"
                        >
                          <path
                            d="M7 12c0 2.8 2.2 5 5 5s5-2.2 5-5-2.2-5-5-5-5 2.2-5 5zm5-3c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm1-4V3c0-.6-.4-1-1-1s-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1zm6.1-.1c-.4-.4-1-.4-1.4 0l-1.4 1.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l1.4-1.4c.4-.3.4-1 0-1.4zM21 11h-2c-.6 0-1 .4-1 1s.4 1 1 1h2c.6 0 1-.4 1-1s-.4-1-1-1zm-3.3 5.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.4 1.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-1.4-1.4zM11 19v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1s-1 .4-1 1zm-6.1.1c.2.2.5.3.7.3s.5-.1.7-.3l1.4-1.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-1.4 1.4c-.4.3-.4 1 0 1.4zM2 12c0 .6.4 1 1 1h2c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1zm4.3-7.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.4 1.4c.2.3.5.4.8.4s.5-.1.7-.3c.4-.4.4-1 0-1.4L6.3 4.9z"
                            fill="#0d6efd"
                            class="color000000 svgShape"
                          ></path>
                        </svg>
                      </>
                    ) : (
                      <i class="ri-moon-line margin_x"></i>
                    )}
                  </a>
                </div>
              ) : (
                <div
                  id="dash"
                  className="d-flex align-items-center asset_deposit"
                >
                  <div className="deposit_button">
                    <button
                      type="button"
                      class=""
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Deposit
                    </button>
                  </div>

                  <div class="btn-group more-wrapper">
                    <button
                      class="more-select bell-notify"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa-solid fa-bell fa-shake"></i>
                    </button>

                    <ul class="dropdown-menu notify-dropdown">
                      <div className="notify-contents">
                        <div className="notify-head-wrapper">
                          <h5>Notifications</h5>
                          <Link to="/history">
                            <button>
                              View All <i class="ri-arrow-right-s-line"></i>
                            </button>
                          </Link>
                        </div>

                        {notification &&
                          notification.map((options, i) => {
                            return (
                              <div className="notify-container">
                                <Link
                                  className="nav-notify-content "
                                  to={options.link}
                                >
                                  <h6 className="nav-notify">
                                    {" "}
                                    {options.message}{" "}
                                  </h6>

                                  <div className="time-notify">
                                    {Moment(options.createdAt).fromNow()}
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                      </div>
                    </ul>
                  </div>

                  <Link to="/assets" id="dash" className="asset_title">
                    Assets
                  </Link>

                  {/* <!-- Modal --> */}
                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered modal_max_width">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1
                            class="modal-title-deposit"
                            id="exampleModalLabel"
                          >
                            Deposit
                          </h1>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body padding_modal">
                          <h3 className="have_crypto_text">I have crypto</h3>
                          <a href="/deposit">
                            <div className="spot_features_box deposit-box">
                              <div className="spot_features_card">
                                <img
                                  src={require("../images/landing_new/deposit_crypto_icon.png")}
                                />
                                <div className="spot_features_box_content">
                                  <h3>Deposit Crypto</h3>
                                  <p>To your exchange wallet address</p>
                                </div>
                              </div>
                              <div className="next_icon1">
                                <i class="ri-arrow-right-s-line"></i>
                              </div>
                            </div>
                          </a>
                          <h3 className="have_crypto_text">
                            I don't have crypto
                          </h3>
                          <div className="spot_features_box deposit-box">
                            <div className="spot_features_card">
                              <img
                                src={require("../images/landing_new/buy_card.png")}
                              />
                              <div className="spot_features_box_content">
                                <h3>Buy Crypto with Card</h3>
                                <p>Visa, Mastercard, and others</p>
                              </div>
                              <span class="comming_soon">Soon</span>
                            </div>
                            <div className="next_icon1">
                              <i class="ri-arrow-right-s-line"></i>
                            </div>
                          </div>
                          <a href="">
                            <div className="spot_features_box deposit-box">
                              <div className="spot_features_card">
                                <img
                                  src={require("../images/landing_new/deposit_cash.png")}
                                />
                                <div className="spot_features_box_content">
                                  <h3>Deposit Cash</h3>
                                  <p>Add EUR to your balance</p>
                                </div>
                                <span class="comming_soon">Soon</span>
                              </div>
                              <div className="next_icon1">
                                <i class="ri-arrow-right-s-line"></i>
                              </div>
                            </div>
                          </a>

                          <a href="/p2p">
                            <div className="spot_features_box deposit-box">
                              <div className="spot_features_card">
                                <img
                                  src={require("../images/landing_new/p2p_icon.png")}
                                />
                                <div className="spot_features_box_content">
                                  <h3>Buy Crypto on P2P</h3>
                                  <p>
                                    Direct bank & mobile transfers to other
                                    users
                                  </p>
                                </div>
                              </div>
                              <div className="next_icon1">
                                <i class="ri-arrow-right-s-line"></i>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <Link>
                    {" "}
                    <div className="dropdown1">
                      <button onClick={dropdowns}>
                        <div className="dropdown-email-add">
                          <img
                            src={require("../images/dashboard/profile_img.png")}
                          />
                          <i class="ri-arrow-down-s-line"></i>
                        </div>
                      </button>
                      <div
                        onClick={dropdowns}
                        className={
                          dropstatus == true
                            ? `dropdown-content1 d-block `
                            : `dropdown-content1`
                        }
                      >
                        <div className="content">
                          <div className="profile_icon_dropdown_content">
                            <img
                              src={require("../images/landing_new/profile_icon.png")}
                              className="profile_icon_dropdown"
                            />
                            <div className="">
                              <h5>d****@gmail.com</h5>
                              <p>
                                ID 752968 <i class="ri-file-copy-line"></i>
                              </p>
                            </div>
                          </div>
                          <Link to="/dashboard" className="">
                            <div className="security_drop_title">
                              <img
                                src={require("../images/landing_new/dashboard_icon.png")}
                              />
                              <h4>Dashboard</h4>
                            </div>
                          </Link>
                          <Link to="/security" className="mb-3">
                            <div className="security_drop_title">
                              <img
                                src={require("../images/dashboard/security.png")}
                              />
                              <h4>Security</h4>
                            </div>
                          </Link>
                          <Link to="/kyc" className="mb-3">
                            {" "}
                            <div className="security_drop_title Identity Verification">
                              <img
                                src={require("../images/dashboard/kyc.png")}
                              />
                              <h4>Identity Verification</h4>
                            </div>
                          </Link>
                          <Link to="/staking" className="">
                            {" "}
                            <div className="security_drop_title Identity Verification">
                              <img
                                src={require("../images/landing_new/stake.png")}
                              />
                              <h4>Staking</h4>
                            </div>
                          </Link>
                          {/* <Link to="/history"> */}
                          <Link to="/history">
                            <div className="security_drop_title">
                              <img
                                src={require("../images/dashboard/history.png")}
                              />
                              <h4>History</h4>
                            </div>
                          </Link>
                          {/* <Link to="/tradehistory"> */}
                          {/* <Link to="/tradehistory">
                            <div className="security_drop_title">
                              <img
                                src={require("../images/dashboard/orders.png")}
                              />
                              <h4>Orders</h4>
                            </div>
                          </Link> */}

                          <Link to="/support">
                            <div className="security_drop_title">
                              <img
                                src={require("../images/dashboard/support.png")}
                              />
                              <h4>Support</h4>
                            </div>
                          </Link>
                          <Link className="log_out_background">
                            {/* {" "}to="#" onClick={logout}  */}
                            <div className="security_drop_title ">
                              <img
                                src={require("../images/dashboard/logout.png")}
                              />
                              <h4
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModallogut"
                              >
                                {" "}
                                Logout
                              </h4>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>

                  <a
                    to="#"
                    onClick={toggleTheme}
                    className="Butn_header only_border"
                  >
                    {isOpen ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="light-dark"
                          viewBox="0 0 24 24"
                          id="lightmode"
                        >
                          <path
                            d="M7 12c0 2.8 2.2 5 5 5s5-2.2 5-5-2.2-5-5-5-5 2.2-5 5zm5-3c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm1-4V3c0-.6-.4-1-1-1s-1 .4-1 1v2c0 .6.4 1 1 1s1-.4 1-1zm6.1-.1c-.4-.4-1-.4-1.4 0l-1.4 1.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l1.4-1.4c.4-.3.4-1 0-1.4zM21 11h-2c-.6 0-1 .4-1 1s.4 1 1 1h2c.6 0 1-.4 1-1s-.4-1-1-1zm-3.3 5.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.4 1.4c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-1.4-1.4zM11 19v2c0 .6.4 1 1 1s1-.4 1-1v-2c0-.6-.4-1-1-1s-1 .4-1 1zm-6.1.1c.2.2.5.3.7.3s.5-.1.7-.3l1.4-1.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-1.4 1.4c-.4.3-.4 1 0 1.4zM2 12c0 .6.4 1 1 1h2c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1zm4.3-7.1c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l1.4 1.4c.2.3.5.4.8.4s.5-.1.7-.3c.4-.4.4-1 0-1.4L6.3 4.9z"
                            fill="#0d6efd"
                            class="color000000 svgShape"
                          ></path>
                        </svg>
                      </>
                    ) : (
                      <i class="ri-moon-line margin_x"></i>
                    )}
                  </a>
                </div>
              )}
            </div>

            {isMatch ? (
              <>
                <React.Fragment>
                  <Drawer
                    anchor="left"
                    open={openDrawer}
                    sx={{
                      display: { xs: "block", sm: "block" },
                      "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                        zIndex: zIndex1,
                      },
                    }}
                    onClose={() => setOpenDrawer(false)}
                  >
                    <List className="">
                      <ListItemButton className="drawer_logo">
                        {" "}
                        <ListItemIcon>
                          <ListItemText>
                            <Link className="nav_brand  " to="/">
                              {" "}
                              <img
                                src={require("../images/NextEx_Logo.png")}
                                className="smart_bull__navlogo"
                              />
                              <img
                                src={require("../images/Netex_dark.png")}
                                className="netex_dark_logo"
                              />
                            </Link>
                          </ListItemText>
                        </ListItemIcon>
                      </ListItemButton>

                      {loginCheck == false ? (
                        <div className="drawclass">
                          {/* <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              ATBB8XNeftkyrrcCrfWjNdeyhNsDEC7DDD12{" "}
                              <ListItemText>
                                <Link
                                  to="/trade/NET1_USDN"
                                  className="link_tage smart-header-nav-login-btn nav-item-trade"
                                >
                                  <i class="ri-line-chart-line"></i>
                                  Trade
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton> */}
                          {/*
                      
                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                <Link
                                  to="/market"
                                  className="link_tage smart-header-nav-login-btn nav-item-trade"
                                >
                                  <i class="ri-pulse-line"></i>
                                  Market
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>
                      */}
                          <ListItemButton>
                            <ListItemIcon>
                              <div
                                className="drawer-dropdown"
                                onClick={handleBuy}
                              >
                                <ListItemText className="drawer-dropdown-title">
                                  Buy Crypto{" "}
                                  {isBuy && (
                                    <span className="margin-l">
                                      <i class="fa-solid fa-angle-up"></i>
                                    </span>
                                  )}
                                  <span className="margin-l">
                                    <i class="fa-solid fa-chevron-down"></i>
                                  </span>
                                </ListItemText>
                                {isBuy && (
                                  <div className="drawer-dropdown-content">
                                    <Link to="/security">Buy With Card</Link>
                                    <Link to="/p2p">P2P Trading</Link>
                                  </div>
                                )}
                              </div>
                            </ListItemIcon>
                          </ListItemButton>
                          <ListItemButton>
                            <ListItemIcon>
                              <div
                                className="drawer-dropdown"
                                onClick={handleTrade}
                              >
                                <ListItemText className="drawer-dropdown-title">
                                  Trade{" "}
                                  {isTrade && (
                                    <span className="margin-trade-l">
                                      <i class="fa-solid fa-angle-up"></i>
                                    </span>
                                  )}
                                  <span className="margin-trade-l">
                                    <i class="fa-solid fa-chevron-down"></i>
                                  </span>
                                </ListItemText>
                                {isTrade && (
                                  <div className="drawer-dropdown-content">
                                    <Link to="">
                                      Convert{" "}
                                      <span className="comming_soon">Soon</span>
                                    </Link>
                                    <Link to="/trade/NET1_USDN">
                                      Spot Trading
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </ListItemIcon>
                          </ListItemButton>

                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              {/* ATBB8XNeftkyrrcCrfWjNdeyhNsDEC7DDD12{" "} */}
                              <ListItemText>
                                <Link
                                  to="/login"
                                  className="link_tage smart-header-nav-login-btn nav-item-trade"
                                >
                                  {/* <i class="ri-login-box-line"></i> */}
                                  Login
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>
                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                {/* <Link
                                  to="/register"
                                  className="link_tage nav-item-trade"
                                >
                                  <i class="ri-user-shared-2-fill"></i>
                                  Register
                                </Link> */}{" "}
                                <Link
                                  to="/register"
                                  // to="/"
                                  className="Butn_header mr-0 only_border smart-header-nav-reg-btn"
                                >
                                  Register
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>
                        </div>
                      ) : (
                        // Drawer List
                        <div className="drawclass">
                          {" "}
                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                <Link
                                  to="/dashboard"
                                  className="link_tage smart-header-nav-login-btn nav-item-trade"
                                >
                                  <img
                                    src={require("../images/landing_new/dashboard_icon.png")}
                                    className="p2p_icon_drawer2"
                                  />
                                  Dashboard
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>
                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                <Link
                                  to="/trade/NET1_USDN"
                                  className="link_tage nav-item-trade"
                                >
                                  <i class="ri-line-chart-line"></i>
                                  Trade
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>
                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                <Link
                                  to="/p2p"
                                  className="link_tage nav-item-trade"
                                >
                                  <img
                                    src={require("../images/landing_new/p2p_icon.png")}
                                    className="p2p_icon_drawer"
                                  />
                                  P2P
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>
                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                <Link
                                  to="/Security"
                                  className="link_tage nav-item-trade"
                                >
                                  <i class="ri-file-shield-2-line m-0"></i>
                                  Security
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>
                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                <Link
                                  to="/assets"
                                  className="link_tage nav-item-trade"
                                >
                                  <i class="ri-wallet-line"></i>
                                  Assets
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>{" "}
                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                <Link
                                  to="/kyc"
                                  className="link_tage nav-item-trade"
                                >
                                  <i class="ri-user-follow-line"></i>
                                  Identity Verification
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>
                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                {/* <Link to="/deposit" className="link_tage nav-item-trade"> */}
                                <Link
                                  to="/deposit"
                                  className="link_tage nav-item-trade"
                                >
                                  <i class="ri-luggage-deposit-line"></i>
                                  Deposit
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>
                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                {/* <Link to="/withdraw" className="link_tage nav-item-trade"> */}
                                <Link
                                  to="/withdraw"
                                  className="link_tage nav-item-trade"
                                >
                                  <i class="ri-bank-line"></i>
                                  Withdrawal
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>
                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                {/* <Link to="/History" className="link_tage nav-item-trade"> */}
                                <Link
                                  to="/history"
                                  className="link_tage nav-item-trade"
                                >
                                  <i class="ri-history-line"></i>
                                  History
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>
                          {/* <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                {/* <Link to="/Orders" className="link_tage nav-item-trade">  
                                <Link
                                  to="/tradehistory"
                                  className="link_tage nav-item-trade"
                                >
                                  <i class="ri-file-history-line"></i>
                                  Orders
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton> */}
                          <ListItemButton>
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                {/* <Link to="/support" className="link_tage nav-item-trade"> */}
                                <Link
                                  to="/support"
                                  className="link_tage nav-item-trade"
                                >
                                  <i class="ri-questionnaire-line"></i>
                                  Support
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>{" "}
                          <ListItemButton className="logout_drawer_button">
                            {" "}
                            <ListItemIcon>
                              <ListItemText>
                                <Link
                                  to="#"
                                  onClick={logout}
                                  className="link_tage nav-item-trade"
                                >
                                  <i class="ri-logout-box-line"></i>
                                  Logout
                                </Link>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItemButton>
                        </div>
                      )}
                    </List>
                  </Drawer>
                  <IconButton
                    sx={{ color: "white", marginLeft: "auto" }}
                    onClick={() => setOpenDrawer(!openDrawer)}
                  >
                    <MenuIcon color="white" />
                  </IconButton>
                  <Modal
                    isOpen={modal1}
                    toggle={toggle1}
                    className="rem10 checkout_modal mt40"
                  >
                    <ModalBody className="p-0">
                      <div class="Frame-14722">
                        <span class="Item-Details h2">
                          <i class="ri-logout-box-line mr-2"></i> Logout{" "}
                        </span>
                        <div class="">
                          <i class="ri-close-fill  Close" onClick={goto1}></i>
                        </div>
                      </div>

                      <div className="py-2">
                        <div className="row flex-row px-3 py-1 ">
                          <div className="col-md-6 mt-2">
                            <div className="form_login_input">
                              <Button className="discardbtn" onClick={goto1}>
                                Discard
                              </Button>
                            </div>
                          </div>

                          <div className="col-md-6 mt-2">
                            <div className="form_login_input">
                              <Button>Logout</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                </React.Fragment>
              </>
            ) : (
              ""
            )}
          </Toolbar>
        </AppBar>
      </React.Fragment>

      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="exampleModallogut"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabellogout">
                <i class="ri-logout-box-line mr-2"></i> Log Out
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <p className="log_out_msg">
                Are you sure want to logout this session ?
              </p>
            </div>
            <div class="modal-footer log_out_popup">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Discard
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={logout_website}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
