import React, { useEffect } from "react";
import { Bars } from "react-loader-spinner";
import Home_header from "./Home_header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import Sidebar from "./Sidebar";

function Home() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];

  const [perpage, setperpage] = useState(5);
  const [activeOrderDatas, setActiveOders] = useState([]);
  const [tradeHistoryData, settradeHistory] = useState([]);
  const [cancelOrders, setcancelOrders] = useState([]);
  const [stopOrders, setstopOrders] = useState([]);
  const [withdrawHistory, setwithdrawHistory] = useState([]);
  const [depositHistory, setdepositHistory] = useState([]);
  const [sessionHistory, setsessionHistory] = useState([]);
  const [TfaHistory, setTfaHistory] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);

  const [totalactive, settotalactive] = useState(0);
  const [currentPagecan, setCurrentPagecan] = useState(1);
  const [totalcan, settotalcan] = useState(0);
  const [currentPageHis, setcurrentPageHis] = useState(1);
  const [totalHist, settotalHist] = useState(0);
  const [currentPagestop, setCurrentPagestop] = useState(1);
  const [totalStop, settotalStop] = useState(0);

  const [depositcurrentpage, setdepositcurrentpage] = useState(1);
  const [deposittotalpage, setdepositTotalpages] = useState(0);

  const [withdrawcurrentpage, setwithdrawcurrentpage] = useState(1);
  const [withdrawtotalpage, setwithdrawTotalpages] = useState(0);

  const [logincurrentpage, setlogincurrentpage] = useState(1);
  const [logintotalpage, setloginTotalpages] = useState(0);

  const [tfacurrentpage, settfacurrentpage] = useState(1);
  const [tfatotalpage, settfaTotalpages] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);

  const recordPerPage = 5;
  const pageRange = 5;
  const recordPerPagecan = 5;
  const pageRangecan = 5;

  const recordPerPageHist = 5;
  const pageRangeHis = 5;

  const recordPerPageStop = 5;
  const pageRangeStop = 5;

  const recordPerPageLogin = 5;
  const pageRangeLogin = 5;

  const navigate = useNavigate();

  useEffect(() => {
    getActiveOrders(1);
    tradeHistory(1);
    getCancelOrders(1);
    getStopOrders(1);
    getdepositHistory(1);
    getwithdrawHistory(1);
    getLoginHistory(1);
    Tfa_History(1);
  }, [0]);

  const getActiveOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.getActiveOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotalactive(resp.count);
        setActiveOders(resp.result);
      } else {
      }
    } catch (error) {}
  };

  const tradeHistory = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.tradeHistory,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settradeHistory(resp.result);
        settotalHist(resp.count);
      } else {
      }
    } catch (error) {}
  };

  const getCancelOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.getCancelOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setcancelOrders(resp.result);
        settotalcan(resp.count);
      } else {
      }
    } catch (error) {}
  };

  const getStopOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
      };
      var data = {
        apiUrl: apiService.getStopOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotalStop(resp.count);
        setstopOrders(resp.result);
      } else {
      }
    } catch (error) {}
  };

  const orderCancel = async (cancelDatas) => {
    try {
      var obj = {
        _id: cancelDatas._id,
      };
      var data = {
        apiUrl: apiService.cancelOrder,
        payload: obj,
      };
      var fetchTradeHisotory = await postMethod(data);
      if (fetchTradeHisotory) {
        showSuccessToast(
          "Order cancelled successfully, your amount credit to your wallet"
        );
        getActiveOrders(1);
      } else {
        showErrorToast("Please try again later");
      }
    } catch (error) {
      showErrorToast("Please try again later");
    }
  };

  const getLoginHistory = async (page) => {
    try {
      var payload = {
        perpage: loginrecordpage,
        page: page,
      };
      var data = {
        apiUrl: apiService.getSessionHisotry,
        payload: payload,
      };
      setSiteLoader(true);
      var resp = await postMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        setsessionHistory(resp.data.data);
        setloginTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const activePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getActiveOrders(pageNumber);
  };

  const cancelPageChange = (pageNumber) => {
    setCurrentPagecan(pageNumber); // call API to get data based on pageNumber
    getCancelOrders(pageNumber);
  };

  const orderhistoryactive = (event, pageNumber) => {
    setcurrentPageHis(pageNumber); // call API to get data based on pageNumber
    tradeHistory(pageNumber);
  };

  const orderstopactive = (pageNumber) => {
    setCurrentPagestop(pageNumber); // call API to get data based on pageNumber
    getStopOrders(pageNumber);
  };

  const getdepositHistory = async (page) => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var deposit_history_list = await postMethod(obj);
    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.result);
      setdepositTotalpages(deposit_history_list.total);
      console.log("deposit_history_list.pages===", deposittotalpage);
    }
  };

  const depositrecordpage = 5;
  const depositpagerange = 5;
  const handlepagedeposit = (event, page) => {
    getdepositHistory(page);
    setdepositcurrentpage(page);
  };

  const getwithdrawHistory = async (page) => {
    var data = {
      apiUrl: apiService.withdraw_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    var withdraw_history_list = await postMethod(data);
    if (withdraw_history_list) {
      setwithdrawHistory(withdraw_history_list.result);
      setwithdrawTotalpages(withdraw_history_list.pages);
    }
  };

  const withdrawrecordpage = 5;
  const withdrawpagerange = 5;

  const handlepagewithdraw = (event, page) => {
    getwithdrawHistory(page);
    setwithdrawcurrentpage(page);
  };

  const loginrecordpage = 5;
  const loginpagerange = 5;

  const handlepagelogin = (event, pageNumber) => {
    setlogincurrentpage(pageNumber);
    getLoginHistory(pageNumber);
  };

  const Tfa_History = async (page) => {
    var data = {
      apiUrl: apiService.Tfa_History,
      payload: { perpage: 5, page: page },
    };
    var Tfa_History = await postMethod(data);
    if (Tfa_History) {
      setTfaHistory(Tfa_History.data.data);
      settfaTotalpages(Tfa_History.data.total);
    }
  };
  const tfarecordpage = 5;
  const tfapagerange = 5;
  const handlepagetfachange = (event, pageNumber) => {
    Tfa_History(pageNumber);
    settfacurrentpage(pageNumber);
  };

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast("Address copied");
  };

  const [mytrades, setmytrades] = useState("home");
  const mytradesfunction = (newValue) => {
    setmytrades(newValue);
  };

  return (
    <main className="dashboard_page">
      <section>
        <Home_header />
      </section>
      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-lg-2">
              .
              <Sidebar />
            </div>
            <div className="col-lg-10">
              <section className="dashboard_content_section">
                <div className="container-fluid">
                  <div className="smart_dashboard_content2">
                    <h2>History</h2>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="dash_board_profile_content">
                          <div class="lrtcss-ac8m87 e8w7gdo16 pt-0">
                            <section className="p2p_buy_sell_section w-100">
                              <div className="">
                                <div className="p2p_tabs mb-5">
                                  <div class="p2p_padding ">
                                    <ul
                                      class="nav nav-tabs history_tabs mb-3"
                                      id="myTabs"
                                    >
                                      <li class="nav-item">
                                        <a
                                          class="nav-link active"
                                          id="home-tab"
                                          onClick={() =>
                                            mytradesfunction("home")
                                          }
                                          data-bs-toggle="tab"
                                          data-bs-target="#home"
                                          type="button"
                                          role="tab"
                                          aria-controls="home"
                                          aria-selected="true"
                                        >
                                          Login History
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          class="nav-link "
                                          id="tfa-tab"
                                          onClick={() =>
                                            mytradesfunction("tfa")
                                          }
                                          data-bs-toggle="tab"
                                          data-bs-target="#tfa"
                                          type="button"
                                          role="tab"
                                          aria-controls="tfa"
                                          aria-selected="true"
                                        >
                                          2FA History
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          class="nav-link "
                                          id="deposit-tab"
                                          onClick={() =>
                                            mytradesfunction("deposit")
                                          }
                                          data-bs-toggle="tab"
                                          data-bs-target="#deposit"
                                          type="button"
                                          role="tab"
                                          aria-controls="deposit"
                                          aria-selected="true"
                                        >
                                          Deposit History
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          class="nav-link "
                                          id="withdraw-tab"
                                          onClick={() =>
                                            mytradesfunction("withdraw")
                                          }
                                          data-bs-toggle="tab"
                                          data-bs-target="#withdraw"
                                          type="button"
                                          role="tab"
                                          aria-controls="withdraw"
                                          aria-selected="true"
                                        >
                                          Withdrawal History
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          class="nav-link"
                                          id="trade-tab"
                                          onClick={() =>
                                            mytradesfunction("trade")
                                          }
                                          data-bs-toggle="tab"
                                          data-bs-target="#trade"
                                          type="button"
                                          role="tab"
                                          aria-controls="trade"
                                          aria-selected="true"
                                        >
                                          Trade History
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          class="nav-link"
                                          id="trade-tab"
                                          onClick={() =>
                                            mytradesfunction("trade")
                                          }
                                          data-bs-toggle="tab"
                                          data-bs-target="#trade"
                                          type="button"
                                          role="tab"
                                          aria-controls="trade"
                                          aria-selected="true"
                                        >
                                          Open Order History
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          class="nav-link"
                                          id="trade-tab"
                                          onClick={() =>
                                            mytradesfunction("trade")
                                          }
                                          data-bs-toggle="tab"
                                          data-bs-target="#trade"
                                          type="button"
                                          role="tab"
                                          aria-controls="trade"
                                          aria-selected="true"
                                        >
                                          Cancel Order History
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          class="nav-link"
                                          id="trade-tab"
                                          onClick={() =>
                                            mytradesfunction("trade")
                                          }
                                          data-bs-toggle="tab"
                                          data-bs-target="#trade"
                                          type="button"
                                          role="tab"
                                          aria-controls="trade"
                                          aria-selected="true"
                                        >
                                          Stop Order History
                                        </a>
                                      </li>
                                    </ul>

                                    <div
                                      class="p2p_tab tab-content  mt-2"
                                      id="myTabContent"
                                    >
                                      <div
                                        id="deposit"
                                        role="tabpanel"
                                        className={`tab-pane fade ${
                                          mytrades === "deposit"
                                            ? "show active"
                                            : ""
                                        }`}
                                        aria-labelledby="deposit-tab"
                                      >
                                        <div className="p2p_table table-responsive deposite_tabel_border">
                                          <table class="table market">
                                            <thead>
                                              <tr className="tabel_bg">
                                                <th
                                                  scope="col"
                                                  className="starts"
                                                >
                                                  Date
                                                </th>
                                                <th scope="col">Currency</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">
                                                  Transaction Id
                                                </th>
                                                <th
                                                  scope="col"
                                                  className="ends"
                                                >
                                                  Status
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {depositHistory &&
                                              depositHistory.length > 0 ? (
                                                depositHistory.map(
                                                  (item, i) => {
                                                    return (
                                                      <tr className="history_tabel_bottom_border">
                                                        <td>
                                                          {Moment(
                                                            item.date
                                                          ).format(
                                                            "DD.MM.YYYY hh:mm a"
                                                          )}
                                                        </td>
                                                        <td>
                                                          {item.currencySymbol}
                                                        </td>
                                                        <td>
                                                          {parseFloat(
                                                            item.amount
                                                          ).toFixed(8)}
                                                        </td>
                                                        <td>
                                                          <span className="hash_code">
                                                            {item.txnid !=
                                                            undefined
                                                              ? item.txnid.slice(
                                                                  0,
                                                                  10
                                                                )
                                                              : "-"}{" "}
                                                            {""}
                                                          </span>
                                                          <i
                                                            class="fa fa-clone"
                                                            aria-hidden="true"
                                                            onClick={() =>
                                                              copy(item.txnid)
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          ></i>
                                                        </td>
                                                        <td>
                                                          <span className="text-green">
                                                            Completed
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <td colSpan={5}>
                                                  <div className="empty_data">
                                                    <div className="empty_data_img">
                                                      <img
                                                        src={require("../images/No-data.png")}
                                                      />
                                                    </div>

                                                    <div className="no_records_text">
                                                      No Records Found
                                                    </div>
                                                  </div>
                                                </td>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        {depositHistory &&
                                        depositHistory.length > 0 ? (
                                          <div className="pagination">
                                            <Stack spacing={2}>
                                              <Pagination
                                                count={Math.ceil(
                                                  deposittotalpage /
                                                    depositrecordpage
                                                )}
                                                page={depositcurrentpage}
                                                onChange={handlepagedeposit}
                                                renderItem={(item) => (
                                                  <PaginationItem
                                                    slots={{
                                                      previous: ArrowBackIcon,
                                                      next: ArrowForwardIcon,
                                                    }}
                                                    {...item}
                                                  />
                                                )}
                                              />
                                            </Stack>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        id="withdraw"
                                        role="tabpanel"
                                        className={`tab-pane fade ${
                                          mytrades === "withdraw"
                                            ? "show active"
                                            : ""
                                        }`}
                                        aria-labelledby="withdraw-tab"
                                      >
                                        <div className="tab_methods wallet_tab"></div>

                                        <div className="p2p_table table-responsive deposite_tabel_border">
                                          <table class="table   market">
                                            <thead>
                                              <tr className="tabel_bg">
                                                <th
                                                  scope="col"
                                                  className="starts"
                                                >
                                                  Transaction ID
                                                </th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Currency</th>
                                                <th scope="col">Date</th>
                                                <th
                                                  scope="col"
                                                  className="ends"
                                                >
                                                  Status
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {withdrawHistory.length > 0 ? (
                                                withdrawHistory.map(
                                                  (data, i) => {
                                                    return (
                                                      <tr className="history_tabel_bottom_border">
                                                        <td>
                                                          {" "}
                                                          {data.txn_id !=
                                                          undefined
                                                            ? data.txn_id.slice(
                                                                0,
                                                                10
                                                              )
                                                            : "-"}{" "}
                                                          {""}
                                                          <i
                                                            class="fa fa-clone"
                                                            aria-hidden="true"
                                                            onClick={() =>
                                                              copy(data.txn_id)
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          ></i>
                                                        </td>
                                                        <td>
                                                          {" "}
                                                          {data.amount ==
                                                          undefined
                                                            ? 0
                                                            : data.amount.toFixed(
                                                                4
                                                              )}
                                                        </td>
                                                        <td>{data.currency}</td>
                                                        <td>
                                                          {Moment(
                                                            data.date
                                                          ).format("lll")}
                                                        </td>
                                                        <td>
                                                          {data.txn_id !=
                                                          "--------" ? (
                                                            <span className="text-green">
                                                              Completed
                                                            </span>
                                                          ) : (
                                                            <span className="text-red">
                                                              Pending
                                                            </span>
                                                          )}
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <td colSpan={5}>
                                                  <div className="empty_data">
                                                    <div className="empty_data_img">
                                                      <img
                                                        src={require("../images/No-data.png")}
                                                      />
                                                    </div>

                                                    <div className="no_records_text">
                                                      No Records Found
                                                    </div>
                                                  </div>
                                                </td>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        {withdrawHistory &&
                                        withdrawHistory.length > 0 ? (
                                          <div className="pagination">
                                            <Stack spacing={2}>
                                              <Pagination
                                                count={Math.ceil(
                                                  withdrawtotalpage /
                                                    withdrawrecordpage
                                                )}
                                                page={withdrawcurrentpage}
                                                onChange={handlepagewithdraw}
                                                renderItem={(item) => (
                                                  <PaginationItem
                                                    slots={{
                                                      previous: ArrowBackIcon,
                                                      next: ArrowForwardIcon,
                                                    }}
                                                    {...item}
                                                  />
                                                )}
                                              />
                                            </Stack>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div
                                        id="trade"
                                        role="tabpanel"
                                        className={`tab-pane fade ${
                                          mytrades === "trade"
                                            ? "show active"
                                            : ""
                                        }`}
                                        aria-labelledby="trade-tab"
                                      >
                                        <div className="p2p_table table-responsive deposite_tabel_border">
                                          <table class="table   market">
                                            <thead>
                                              <tr className="tabel_bg">
                                                <th
                                                  scope="col"
                                                  className="starts"
                                                >
                                                  Date
                                                </th>
                                                <th scope="col">Pair</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Total</th>
                                                <th
                                                  scope="col"
                                                  className="ends"
                                                >
                                                  Status
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {tradeHistoryData.length > 0 ? (
                                                tradeHistoryData.map(
                                                  (item, i) => {
                                                    var datesHis = Moment(
                                                      item.created_at
                                                    ).format(
                                                      "DD.MM.YYYY hh:mm a"
                                                    );
                                                    return (
                                                      <tr className="history_tabel_bottom_border">
                                                        <td>{datesHis} </td>
                                                        <td>{item.pair} </td>
                                                        <td>{item.type} </td>
                                                        <td>
                                                          {" "}
                                                          {item.askPrice}{" "}
                                                        </td>
                                                        <td>
                                                          {" "}
                                                          {item.askAmount}{" "}
                                                        </td>
                                                        <td>{item.total}</td>
                                                        <td>
                                                          {item.type ==
                                                          "buy" ? (
                                                            <span className="text-green">
                                                              Filled
                                                            </span>
                                                          ) : (
                                                            <span className="text-red">
                                                              Filled
                                                            </span>
                                                          )}
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <td colSpan={7}>
                                                  <div className="empty_data">
                                                    <div className="empty_data_img">
                                                      <img
                                                        src={require("../images/No-data.png")}
                                                      />
                                                    </div>

                                                    <div className="no_records_text">
                                                      No Records Found
                                                    </div>
                                                  </div>
                                                </td>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        {tradeHistoryData &&
                                        tradeHistoryData.length > 0 ? (
                                          <div className="pagination">
                                            <Stack spacing={2}>
                                              <Pagination
                                                count={Math.ceil(
                                                  totalHist / recordPerPageHist
                                                )}
                                                page={currentPageHis}
                                                onChange={orderhistoryactive}
                                                renderItem={(item) => (
                                                  <PaginationItem
                                                    slots={{
                                                      previous: ArrowBackIcon,
                                                      next: ArrowForwardIcon,
                                                    }}
                                                    {...item}
                                                  />
                                                )}
                                              />
                                            </Stack>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div
                                        id="home"
                                        role="tabpanel"
                                        className={`tab-pane fade ${
                                          mytrades === "home"
                                            ? "show active"
                                            : ""
                                        }`}
                                        aria-labelledby="home-tab"
                                      >
                                        <div className="p2p_table table-responsive deposite_tabel_border">
                                          <table class="table   market">
                                            <thead>
                                              <tr className="tabel_bg">
                                                <th
                                                  scope="col"
                                                  className="starts"
                                                >
                                                  Date
                                                </th>
                                                <th scope="col">IP Address</th>
                                                <th
                                                  scope="col"
                                                  className="ends"
                                                >
                                                  Device
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {sessionHistory.length > 0 &&
                                              sessionHistory ? (
                                                sessionHistory.map(
                                                  (item, i) => {
                                                    return (
                                                      <tr className="history_tabel_bottom_border">
                                                        <td>
                                                          {Moment(
                                                            item.createdDate
                                                          ).format(
                                                            "DD.MM.YYYY hh:mm a"
                                                          )}
                                                        </td>
                                                        <td>
                                                          {item.ipAddress}
                                                        </td>
                                                        <td>{item.platform}</td>
                                                      </tr>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <td colSpan={3}>
                                                  <div className="empty_data">
                                                    <div className="empty_data_img">
                                                      <img
                                                        src={require("../images/No-data.png")}
                                                      />
                                                    </div>

                                                    <div className="no_records_text">
                                                      No Records Found
                                                    </div>
                                                  </div>
                                                </td>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        {sessionHistory &&
                                        sessionHistory.length > 0 ? (
                                          <>
                                            <div className="pagination">
                                              <Stack spacing={2}>
                                                <Pagination
                                                  count={Math.ceil(
                                                    logintotalpage /
                                                      loginrecordpage
                                                  )}
                                                  page={logincurrentpage}
                                                  onChange={handlepagelogin}
                                                  renderItem={(item) => (
                                                    <PaginationItem
                                                      slots={{
                                                        previous: ArrowBackIcon,
                                                        next: ArrowForwardIcon,
                                                      }}
                                                      {...item}
                                                    />
                                                  )}
                                                />
                                              </Stack>
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div
                                        id="tfa"
                                        role="tabpanel"
                                        className={`tab-pane fade ${
                                          mytrades === "tfa"
                                            ? "show active"
                                            : ""
                                        }`}
                                        aria-labelledby="tfa-tab"
                                      >
                                        <div className="p2p_table table-responsive deposite_tabel_border">
                                          <table class="table   market">
                                            <thead>
                                              <tr className="tabel_bg">
                                                <th
                                                  scope="col"
                                                  className="starts"
                                                >
                                                  Data & Time
                                                </th>
                                                <th scope="col">Ip Address</th>
                                                <th scope="col">Browser</th>
                                                <th
                                                  scope="col"
                                                  className="ends"
                                                >
                                                  Status
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {TfaHistory.length > 0 ? (
                                                TfaHistory.map((item, i) => {
                                                  return (
                                                    <tr className="history_tabel_bottom_border">
                                                      <td>
                                                        {item.createdDate}
                                                      </td>
                                                      <td>{item.ipAddress}</td>
                                                      <td>{item.browser}</td>
                                                      <td>
                                                        {item.status ==
                                                        "disabled" ? (
                                                          <span className="text-red">
                                                            Disabled
                                                          </span>
                                                        ) : (
                                                          <span className="text-green">
                                                            enabled
                                                          </span>
                                                        )}
                                                      </td>
                                                    </tr>
                                                  );
                                                })
                                              ) : (
                                                <td colSpan={4}>
                                                  <div className="empty_data">
                                                    <div className="empty_data_img">
                                                      <img
                                                        src={require("../images/No-data.png")}
                                                      />
                                                    </div>

                                                    <div className="no_records_text">
                                                      No Records Found
                                                    </div>
                                                  </div>
                                                </td>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        {TfaHistory && TfaHistory.length > 0 ? (
                                          <div className="pagination">
                                            <Stack spacing={2}>
                                              <Pagination
                                                count={Math.ceil(
                                                  tfatotalpage / tfarecordpage
                                                )}
                                                page={tfacurrentpage}
                                                onChange={handlepagetfachange}
                                                renderItem={(item) => (
                                                  <PaginationItem
                                                    slots={{
                                                      previous: ArrowBackIcon,
                                                      next: ArrowForwardIcon,
                                                    }}
                                                    {...item}
                                                  />
                                                )}
                                              />
                                            </Stack>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      )}
      {/* <Footer /> */}
    </main>
  );
}

export default Home;
