import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Landing_page from "./pages/Landing_page";
import After_Login_Landing from "./pages/After_Login_Landing";
import Login from "./pages/Login";
import Register from "./pages/Regiter";
import Forget from "./pages/Forget";
import History from "./pages/History";
import Tradehistory from "./pages/Tradehistory";
import Deposit from "./pages/Deposit";
import Withdraw from "./pages/Withdraw";
import Trade from "./pages/Trade";
import Market from "./pages/market";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import VerifyEmail from "./pages/verify_email";
import VerifyTFA from "./pages/verify_tfa";

import Dashboard from "./pages/Dashboard";
import Kyc from "./pages/Kyc";
import Secuirty from "./pages/Secuirty";
import User_profile from "./pages/User_profile";
import Change_Password from "./pages/Change_password";
import Authentication from "./pages/Authentication";
import Support from "./pages/Support";
import { removeAuthToken } from "../src/core/lib/localStorage";
import About_us from "./pages/About_us";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Risk_Disclouser from "./pages/Risk_Disclouser";
import Staking from "./pages/Staking";
import P2p from "./pages/P2p";
import Wallet from "./pages/Wallet";
import Convert from "./pages/Convert";
import "@fortawesome/fontawesome-free/css/all.min.css";
import TermsUse from "./pages/TermsUse";
import Listing from "./pages/Lisiting";
import Token_Info from "./pages/Token_Info";
import Trust from "./pages/Trust";
import PostAd from "./pages/Postad";
import P2PChat from "./pages/P2PChat";
import P2POrder from "./pages/P2POrder";
import P2POrders from "./pages/P2Porders";
import P2POffers from "./pages/P2POffers";
import P2PAccount from "./pages/P2PAccounts";

function App() {
  function RequireAuth({ children }) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Landing_page />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route path="/home" element={<After_Login_Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/trade/:pair" element={<Trade />} />
          <Route path="/register" element={<Register />} />
          <Route path="/about" element={<About_us />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/risk-disclouser" element={<Risk_Disclouser />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/terms-of-use" element={<TermsUse />} />
          <Route path="/listing" element={<Listing />} />
          <Route path="/token_info" element={<Token_Info />} />
          <Route path="/trust" element={<Trust />} />
          <Route
            path="/deposit"
            element={
              <RequireAuth>
                <Deposit />
              </RequireAuth>
            }
          />
          {/* <Route path="/market" element={<Market />} /> */}
          <Route
            path="/withdraw"
            element={
              <RequireAuth>
                <Withdraw />
              </RequireAuth>
            }
          />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/verify-tfa" element={<VerifyTFA />} />
          <Route path="/history" element={<History />} />
          <Route path="/tradeHistory" element={<Tradehistory />} />
          <Route
            path="/kyc"
            element={
              <RequireAuth>
                <Kyc />
              </RequireAuth>
            }
          />
          <Route
            path="/security"
            element={
              <RequireAuth>
                <Secuirty />
              </RequireAuth>
            }
          />
          <Route
            path="/userprofile"
            element={
              <RequireAuth>
                <User_profile />
              </RequireAuth>
            }
          />
          <Route
            path="/change_password"
            element={
              <RequireAuth>
                <Change_Password />
              </RequireAuth>
            }
          />
          <Route
            path="/authentication"
            element={
              <RequireAuth>
                <Authentication />
              </RequireAuth>
            }
          />
          <Route
            path="/support"
            element={
              <RequireAuth>
                <Support />
              </RequireAuth>
            }
          />
          <Route
            path="/staking"
            element={
              <RequireAuth>
                <Staking />
              </RequireAuth>
            }
          />{" "}
          <Route
            path="/p2p/chat/:id"
            element={
              <RequireAuth>
                {" "}
                <P2PChat />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/p2p/order/:id"
            element={
              <RequireAuth>
                {" "}
                <P2POrder />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/p2porder"
            element={
              <RequireAuth>
                {" "}
                <P2POrders />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/p2poffers"
            element={
              <RequireAuth>
                {" "}
                <P2POffers />{" "}
              </RequireAuth>
            }
          />
          <Route
            path="/p2paccount"
            element={
              <RequireAuth>
                {" "}
                <P2PAccount />{" "}
              </RequireAuth>
            }
          />
          <Route path="/p2p" element={<RequireAuth> <P2p /> </RequireAuth>} />
          <Route path="/assets" element={<RequireAuth><Wallet /></RequireAuth>} />
          <Route path="/convert" element={<RequireAuth><Convert /></RequireAuth>} />
          <Route path="/postad" element={<RequireAuth><PostAd /></RequireAuth>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
