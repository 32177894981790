import React, { useEffect } from "react";
import Home_header from "./Home_header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

function Home() {
  return (
    <main className="dashboard_page">
      <section>
        <Home_header />
      </section>

      <section className="dashboard_content_section">
        <div className="container">
          <div className="smart_dashboard_content">
            <h2>
              Welcome to <span>Net1Ex</span>{" "}
            </h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_board_profile_content">
                  <div className="contentpage">
                    <h3>The Fastest Crypto Trading Platform!</h3>

                    <p>
                      Net1Ex is a cutting-edge cryptocurrency exchange platform
                      designed to provide users with a seamless and secure
                      trading experience. Built with advanced technology and
                      user-centric features, Net1Ex offers a wide range of
                      digital assets, ensuring that both novice and experienced
                      traders can find the perfect fit for their trading needs.
                      The platform prides itself on its high-speed transaction
                      processing, robust security measures, and intuitive
                      interface, making crypto trading accessible and efficient
                      for everyone.
                    </p>
                    <p>
                      Net1Ex aims to empower traders with real-time market
                      insights, competitive trading fees, and a suite of tools
                      to enhance their trading strategies. Whether you’re
                      looking to trade major cryptocurrencies like Bitcoin and
                      Ethereum or explore emerging altcoins, Net1Ex provides a
                      comprehensive marketplace with 24/7 customer support and
                      educational resources to guide you every step of the way.
                    </p>

                    <h5>Values</h5>
                    <p>
                      Net1Ex emerged as a forward-thinking cryptocurrency
                      exchange platform, conceived to address the growing demand
                      for a secure, efficient, and user-friendly trading
                      environment in the rapidly evolving digital asset market.
                      Recognizing the complexities and barriers faced by
                      traders, the creators of Net1Ex envisioned a platform that
                      combines state-of-the-art technology with a deep
                      understanding of the crypto ecosystem.
                    </p>
                    <p>
                      Founded by a team of seasoned professionals with extensive
                      experience in blockchain technology, finance, and
                      cybersecurity, Net1Ex was developed to bridge the gap
                      between traditional trading systems and the new world of
                      digital currencies. The platform’s architecture is built
                      on robust blockchain principles, ensuring transparency,
                      decentralization, and top-tier security.
                    </p>
                    <p>
                      Since its inception, Net1Ex has consistently focused on
                      innovation and user satisfaction. It supports a wide
                      variety of cryptocurrencies and trading pairs, including
                      popular options like Bitcoin, Ethereum, and a growing list
                      of promising altcoins. The platform also offers unique
                      features like staking, liquidity pools, and futures
                      trading, allowing users to maximize their investment
                      potential.
                    </p>

                    <h5>Functionality</h5>
                    <p>
                      With a commitment to security, Net1Ex employs
                      industry-leading measures such as multi-signature wallets,
                      two-factor authentication, and cold storage for assets,
                      safeguarding users’ funds against potential threats. The
                      platform’s customer-first approach is further demonstrated
                      by its 24/7 support service and a comprehensive knowledge
                      base, ensuring users are well-informed and supported at
                      every step of their trading journey.
                    </p>
                    <p>
                      Net1Ex continues to evolve, driven by the mission to
                      democratize access to cryptocurrency trading and create a
                      reliable hub for all digital asset enthusiasts. As it
                      expands its offerings and user base, Net1Ex remains
                      dedicated to setting new standards in the crypto exchange
                      industry.
                    </p>

                    <h5>Disclaimer</h5>
                    <p>
                      The information provided by Net1Ex is for general
                      informational purposes only and does not constitute
                      financial, investment, legal, or professional advice.
                      Trading cryptocurrencies involves significant risk,
                      including the potential loss of all invested funds. The
                      prices of digital assets are highly volatile and can be
                      affected by various external factors, including market
                      sentiment, regulatory changes, and technological
                      developments. Net1Ex does not guarantee the accuracy,
                      completeness, or reliability of the information provided
                      on the platform. Users are encouraged to conduct their own
                      research and consult with a qualified professional before
                      making any investment decisions. Past performance of
                      digital assets does not guarantee future results. Net1Ex
                      is not responsible for any losses incurred due to trading
                      activities, the use of information or services provided on
                      the platform, or any technical issues that may arise.
                      Users should ensure they understand the risks associated
                      with trading cryptocurrencies and should only invest money
                      that they can afford to lose. By using Net1Ex, you
                      acknowledge and agree to this disclaimer and assume full
                      responsibility for your trading activities on the
                      platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
}

export default Home;
