import React, {useState, useEffect } from "react";
import useStateRef from "react-usestateref";
import Home_header from "./Home_header";
import Sidebar_2 from "./Sidebar_2";
import { Bars } from "react-loader-spinner";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const stakeOpt = [
  {
    id: 1,
    optprofile: "usdc_icon.png",
    optName: "USDC",
    optSub: "USD Coin",
    optPercentage: "$ 1.000",
    optTerm: "0 USDC",
    optusd: "≈ 0 EUR",
    optbalance: "Gcash",
    optAction: "Buy USDT",
    optAction2: "Sell USDT",
    optdeposit: "Deposit.png",
    optwithdraw: "Withdraw.png",
    optswap: "swap_icon.png",
    opttrade: "trade_icon.png",
  },
  {
    id: 2,
    optprofile: "EUR_icon.png",
    optName: "EUR",
    optSub: "Euro",
    optPercentage: "$ 1.000",
    optTerm: "0 EUR",
    optusd: "≈ 0 EUR",
    optbalance: "Gcash",
    optAction: "Buy USDT",
    optAction2: "Sell USDT",
    optdeposit: "Deposit.png",
    optwithdraw: "Withdraw.png",
    optswap: "swap_icon.png",
    opttrade: "trade_icon.png",
  },
  {
    id: 3,
    optprofile: "UNI_icon.png",
    optName: "UNI",
    optSub: "Uniswap",
    optPercentage: "$ 1.000",
    optTerm: "532.5",
    optusd: "≈ 0 EUR",
    optbalance: "Gcash",
    optAction: "Buy USDT",
    optAction2: "Sell USDT",
    optdeposit: "Deposit.png",
    optwithdraw: "Withdraw.png",
    optswap: "swap_icon.png",
    opttrade: "trade_icon.png",
  },
  {
    id: 4,
    optprofile: "pan_cake.png",
    optName: "CAKE",
    optSub: "Pancakeswap",
    optPercentage: "$ 1.000",
    optTerm: "532.5",
    optusd: "≈ 0 EUR",
    optbalance: "Gcash",
    optAction: "Buy USDT",
    optAction2: "Deposit.png",
    optdeposit: "Deposit.png",
    optwithdraw: "Withdraw.png",
    optswap: "swap_icon.png",
    opttrade: "trade_icon.png",
  },
  {
    id: 5,
    optprofile: "ARB_icon.png",
    optName: "ARB",
    optSub: "Arbitrum",
    optPercentage: "$ 1.000",
    optTerm: "532.5",
    optbalance: "Gcash",
    optusd: "≈ 0 EUR",
    optAction: "Buy USDT",
    optAction2: "Sell USDT",
    optdeposit: "Deposit.png",
    optwithdraw: "Withdraw.png",
    optswap: "swap_icon.png",
    opttrade: "trade_icon.png",
  },
  {
    id: 6,
    optprofile: "AAVE_icon.png",
    optName: "AAVE",
    optSub: "Aave",
    optPercentage: "$ 1.000",
    optTerm: "532.5",
    optusd: "≈ 0 EUR",
    optbalance: "Gcash",
    optAction: "Buy USDT",
    optAction2: "Sell USDT",
    optdeposit: "Deposit.png",
    optwithdraw: "Withdraw.png",
    optswap: "swap_icon.png",
    opttrade: "trade_icon.png",
  },
];
const Wallet = () => {
  const [siteLoader, setSiteLoader] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("ETH");
  const [value, setValue] = useState("");

  const selectToken = (e, { value }) => {
    setSelectedCurrency(value);
  };

  const changeHandler = (value) => {
    setValue(value);
  };

  const [perpage, setperpage] = useState(5);
  const [page, setpage] = useState(1);
  const [search, setsearch, searchref] = useStateRef("");
  const [balanceDetails, setbalanceDetails] = useState([]);
  const [balance_overallusdt, setbalance_overallusde] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [totalINRPrice, setToatalINRPrice] = useState(0);
  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [inorderPrice, setinorderPrice] = useState(0);
  const [balanceDatas, setbalanceDatas] = useStateRef([]);

  const recordPerPage = 5;

  const navigate = useNavigate();

  const handlePageChange = (event, value) => {
    console.log(value, "ujbjjnjn");

    setCurrentPage(value);
    var current_page = +value * 5;
    var prev_page = +current_page - 5;
    var resp_balance = [];
    for (var i = prev_page; i < current_page; i++) {
      resp_balance.push(balanceDatas[i]);
    }
    setbalanceDetails(resp_balance);
  };


  const getPortfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    var resp = await getMethod(data);
    if (resp.status == true) {
      // console.log(resp, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    } else {
    }
  };

  const getUserbalance = async (pages) => {
    setSiteLoader(false);

    var obj = {
      perpage: perpage,
      page: pages,
      // search: search,
    };

    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      setSiteLoader(false);
      console.log(resp.Message, "=-=-=-resp.Message=-=-=-");
      var balanceData = resp.Message;
      setbalanceDatas(balanceData);

      var current_page = +resp.current * 5;
      var prev_page = +current_page - 5;
      var resp_balance = [];
      for (var i = prev_page; i < current_page; i++) {
        resp_balance.push(balanceData[i]);
      }
      setbalanceDetails(resp_balance);
      var totalnumber = resp.total;
      settotal(resp.total);
      console.log(resp.total, "resp.totalresp.total");
      var balanceData = resp.balance;
    } else {
    }
  };

  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getPortfolio();
    getUserbalance(currentPage);
  }, [0]);

  const handleChange = (e) => {
    try {
      const sanitizedValue = e.target.value.replace(/\s/g, "");
      setsearch(sanitizedValue);
      if(searchref.current != "" && searchref.current != null && searchref.current != undefined)
      {
        console.log("call here")
        searchWalletList();
      }
      else
      {
        console.log("call there")
        getUserbalance(1);
      }
      
    } catch (error) {}
  };

  const searchWalletList = async () => {
    if (
      searchref.current !== "" &&
      searchref.current !== undefined &&
      searchref.current !== null
    ) {
      console.log("calll  1111")
      const regexPattern = new RegExp(searchref.current, "i");
      const searchWallet = balanceDatas.filter((data) =>
        regexPattern.test(data.currencysymbol)
      );
      // const searchWallet = balanceDatas.filter(data => data.currencysymbol.toLowerCase() === searchref.current.toLowerCase());
      if (searchWallet.length > 0) {
        setbalanceDetails(searchWallet);
        settotal(1);
      } else {
        getUserbalance(1);
      }
    } else {
      console.log("calll  2222")
      getUserbalance(1);
    }
  };

  return (
    <>
      {" "}
      <main className=" wallet_main_page">
        <div>
          <Home_header />
        </div>
        {siteLoader == true ? (
          <div className="dashboard_content_section loadercss">
            <Bars
              height="40"
              width="40"
              color="#1062fe"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-12">
                <section className="net_wallet_section">
                  <div className="container">
                    <div className="wallet_balance_content">
                      <h2>Assets</h2>

                      <div className="dwtc_content">
                        <Link to="/deposit">
                          <button>
                            <img
                              src={require("../images/landing_new/Deposit.png")}
                              className="icons_deposit"
                            />{" "}
                            Deposit
                          </button>
                        </Link>
                        <Link to="/withdraw">
                          <button>
                            <img
                              src={require("../images/landing_new/Withdraw.png")}
                              className="icons_deposit"
                            />{" "}
                            Withdrawal
                          </button>
                        </Link>
                        {/* 
                        <button>
                          <i class="ri-exchange-2-line"> </i>
                          Convert
                        </button> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="total_value_content">
                          <h3>Total Value</h3>

                          {/* <h4>
                            0 EUR <span>~ 0 BTC</span>
                          </h4>
                          <p>
                            {" "}
                            Spot <span>-EUR</span> P2P <span>-EUR</span>{" "}
                          </p> */}

                           <h4>
                           {AvailablePrice == "" ||
                            AvailablePrice == null ||
                            AvailablePrice == undefined
                              ? 0.0
                              : AvailablePrice.toFixed(4)}{" "} USD
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="aasets_wallet_content">
                      <h3>Assets List</h3>
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item asset_nav_item" role="presentation">
                          <button
                            class="nav-link asset_nav active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#home-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="home-tab-pane"
                            aria-selected="true"
                          >
                            Crypto
                          </button>
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="home-tab-pane"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                          tabindex="0"
                        >
                          <div className="spot_content">
                            <div className="spot_search_box">
                              <i class="ri-search-line"></i>
                              <input
                                aria-invalid="false"
                                autocomplete="off"
                                name="search"
                                placeholder="Search"
                                type="text"
                                class=""
                                onChange={handleChange}
                              />
                            </div>
                            {/* <div class="top_merchant_checkbox">
                              <input
                                class="PrivateSwitchBase-input css-1m9pwf3"
                                name="topMerchant"
                                type="checkbox"
                                data-indeterminate="false"
                                value="false"
                              />
                              <p>Hide 0 balances</p>
                            </div> */}
                          </div>

                          <div className="table-responsive table-cont">
                            <table className="table wallet_table pt-4">
                              <thead>
                                <tr className="stake-head">
                                  <th>Asset</th>
                                  <th className="table_center_text">
                                    In Order
                                  </th>
                                  <th className="table_center_text">
                                    Available
                                  </th>
                                  <th className="table_center_text">Total</th>
                                </tr>
                              </thead>

                              <tbody>
                              {balanceDetails && balanceDetails.length > 0 ? (
                                balanceDetails.map((item, i) => {
                                  if (!item) {
                                    console.error(`Item at index ${i} is undefined.`);
                                    return null; // Skip rendering this item
                                  }
                                  return (
                                    <tr key={i}>
                                      <td className="table-flex">
                                        <div className="table-opt-name wallet-opt-name">
                                          <h4 className="opt-name font-satoshi font_14">
                                            <span className="optprofile coins_icons">
                                              {" "}
                                              <img
                                                src={item.currencyImage}
                                              />
                                            </span>
                                            {item.currencySymbol}
                                            <br />
                                          </h4>
                                          <p className="order_content_text coin_sup_content">
                                            {" "}
                                            {item.currencySymbol}
                                          </p>
                                        </div>
                                      </td>
                                      <td className="opt-term table_center_text">
                                      {parseFloat(item.holdAmount).toFixed(4)}{" "}
                                      {item.currencysymbol}
                                        <br />
                                        ≈ {parseFloat(
                                          item.estimatedUSDThold
                                        ).toFixed(4)} USD
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text">
                                      {parseFloat(
                                          item.currencyBalance
                                        ).toFixed(4)}{" "}
                                        {item.currencysymbol}
                                        <br />
                                        ≈ {parseFloat(
                                          item.estimatedUSDTbalance
                                        ).toFixed(4)} USD
                                      </td>
                                      <td className="opt-term ">
                                      {parseFloat(
                                          item.currencyBalance +
                                            parseFloat(item.holdAmount)
                                        ).toFixed(4)}{" "}
                                        {item.currencysymbol}
                                        <br />
                                        ≈ {parseFloat(
                                          item.estimatedUSDTtotal
                                        ).toFixed(4)} USD
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan="4">
                                    <span className="w-100 text-center text-white d-block">
                                      No Records found!
                                    </span>
                                  </td>
                                </tr>
                              )}
                              </tbody>
                            </table>

                            {balanceDetails && balanceDetails.length > 0 ? (
                            <div className="pagination">
                              <Stack spacing={2}>
                                <Pagination
                                  count={Math.ceil(total / recordPerPage)}
                                  page={currentPage}
                                  onChange={handlePageChange}
                                  renderItem={(item) => (
                                    <PaginationItem
                                      slots={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                      }}
                                      {...item}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                          ) : (
                            ""
                          )}
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="profile-tab-pane"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                          tabindex="0"
                        >
                          <div className="spot_content">
                            <div className="spot_search_box">
                              <i class="ri-search-line"></i>
                              <input
                                aria-invalid="false"
                                autocomplete="off"
                                name="search"
                                placeholder="Search"
                                type="text"
                                class=""
                                value=""
                              />
                            </div>

                            <div class="top_merchant_checkbox">
                              <input
                                class="PrivateSwitchBase-input css-1m9pwf3"
                                name="topMerchant"
                                type="checkbox"
                                data-indeterminate="false"
                                value="false"
                              />
                              <p>Hide 0 balances</p>
                            </div>
                          </div>

                          <div className="table-responsive table-cont">
                            <table className="table wallet_table pt-4">
                              <thead>
                                <tr className="stake-head">
                                  <th>Asset</th>
                                  <th className="table_center_text">Total</th>
                                  <th className="table_center_text">
                                    Available
                                  </th>
                                  <th className="table_center_text">
                                    In Offer
                                  </th>
                                  <th className="table_center_text">
                                    In Order
                                  </th>
                                  <th className="table_action">Actions </th>
                                </tr>
                              </thead>

                              <tbody>
                                {stakeOpt.map((options) => {
                                  return (
                                    <tr key={options.id}>
                                      <td className="table-flex">
                                        <div className="table-opt-name wallet-opt-name">
                                          <h4 className="opt-name font-satoshi font_14">
                                            <span className="optprofile coins_icons">
                                              {" "}
                                              <img
                                                src={require(`../images/landing_new/${options.optprofile}`)}
                                              />
                                            </span>
                                            {options.optName}
                                            <br />
                                          </h4>
                                          <p className="order_content_text coin_sup_content">
                                            {" "}
                                            {options.optSub}
                                          </p>
                                        </div>
                                      </td>
                                      <td className="opt-term table_center_text">
                                        {options.optTerm}
                                        <br />
                                        {options.optusd}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text">
                                        {options.optTerm}
                                        <br />
                                        {options.optusd}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text">
                                        {options.optTerm}
                                        <br />
                                        {options.optusd}
                                      </td>
                                      <td className="opt-term ">
                                        {options.optTerm}
                                        <br />
                                        {options.optusd}
                                      </td>
                                      <td className="opt-btn-flex table_action wallet_tabel_button">
                                        <div className="wallet_tabel_btns">
                                          <img
                                            src={require(`../images/landing_new/${options.optdeposit}`)}
                                          />
                                          <img
                                            src={require(`../images/landing_new/${options.optwithdraw}`)}
                                          />
                                          <img
                                            src={require(`../images/landing_new/${options.optswap}`)}
                                          />
                                          <img
                                            src={require(`../images/landing_new/${options.opttrade}`)}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </>
        )}
      </main>
    </>
  );
};

export default Wallet;
