import React, { useEffect } from "react";
import Home_header from "./Home_header";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import { Bars } from "react-loader-spinner";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { postMethod } from "../core/service/common.api";
import { env } from "../core/service/envconfig";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";
import useState from "react-usestateref";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Typography from "@mui/material/Typography";

const Kyc = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const options = ["one", "two", "three"];

  const [idproof, setidproof, idproofref] = useState("");
  const [valididproof, setvalididproof] = useState(0);
  const [idproofLoad, setidproofLoad] = useState(false);

  const [addressProof, setaddressProof, addressProofref] = useState("");
  const [validaddressProof, setvalidaddressProof] = useState(0);
  const [addressProofLoad, setaddressProofLoad] = useState(false);

  const [addresIDFront, setaddresIDFront] = useState("");
  const [validaddresIDFront, setvalidaddresIDFront] = useState(0);
  const [addresIDFrontLoad, setaddresIDFrontLoad] = useState(false);

  const [addresIDBack, setaddresIDBack] = useState("");
  const [validaddresIDBack, setvalidaddresIDBack] = useState(0);
  const [addresIDBackLoad, setaddresIDBackLoad] = useState(false);

  const [photoproof, setphotoproof, photoproofref] = useState("");
  const [photoproofname, setphotoproofname, photoproofnameref] = useState("");
  const [idproofname, setidproofname, idproofnameref] = useState("");
  const [addressproofname, setaddressproofname, addressproofnameref] =
    useState("");

  const [validphotoproof, setvalidphotoproof] = useState(0);
  const [photoproofLoad, setphotoproofLoad] = useState(false);
  const [getKYCData, setgetKYCData] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [kycLoader, setkycLoader] = useState(false);
  const [user, Setuser] = useState("");
  const [idNumber, setidNumber, idNumberref] = useState("");
  const [idValidate, setidValidate, idValidateref] = useState(false);
  const [step_active, setstep_active, step_activeref] = useState("1");

  const value = {
    idNumber: "",
    AadhaarNumber: "",
  };
  const [formValue, setformValue] = useState(value);

  const imageUpload = (type, val) => {
    try {
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg "
        );
      } else if (fileSize > 10000000) {
        toast.error("Please upload a file smaller than 1 MB");
      } else {
        console.log("------");
        type == "idproof"
          ? setidproofLoad(true)
          : type == "addressProof"
          ? setaddressProofLoad(true)
          : type == "addressProofFront"
          ? setaddresIDFrontLoad(true)
          : type == "addressProofBack"
          ? setaddresIDBackLoad(true)
          : setphotoproofLoad(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            if (type == "idproof") {
              setidproofLoad(false);
              setvalididproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalididproof(1);
              }

              setidproof(data.secure_url);
              setidproofname(val.name);
            }
            if (type == "addressProof") {
              setaddressProofLoad(false);
              setvalidaddressProof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidaddressProof(1);
              }
              setaddressproofname(val.name);

              setaddressProof(data.secure_url);
            }
            if (type == "photoproof") {
              setphotoproofLoad(false);
              setvalidphotoproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidphotoproof(1);
              }
              setphotoproofname(val.name);

              setphotoproof(data.secure_url);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Please try again later");
          });
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const cancelData = () => {
    try {
      setphotoproofname("");
      setidproofname("");
      setaddressproofname("");
      setidproof("");
      setaddressProof("");
      setphotoproof("");
      setvalididproof(0);
      setvalidaddressProof(0);
      setvalidphotoproof(0);
    } catch (error) {}
  };
  const cancelidproof = () => {
    try {
      setidproofname("");
      setidproof("");
      setvalididproof(0);
    } catch (error) {}
  };
  const canceladdressproof = () => {
    try {
      setaddressproofname("");
      setaddressProof("");
      setvalidaddressProof(0);
    } catch (error) {}
  };
  const cancelphotoproof = () => {
    try {
      setphotoproofname("");
      setphotoproof("");
      setvalidphotoproof(0);
    } catch (error) {}
  };

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const uploadKYC = async () => {
    try {
      if (
        idproofref.current != "" &&
        addressProofref.current != "" &&
        photoproofref.current != "" &&
        idNumberref.current != null
      ) {
        var obj = {
          addressProof: idproofref.current,
          idproof: addressProofref.current,
          photoproof: photoproofref.current,
          IdNumber: idNumberref.current,
        };

        var data = {
          apiUrl: apiService.savekyc,
          payload: obj,
        };
        setkycLoader(true);
        var resp = await postMethod(data);
        if (resp.status) {
          setkycLoader(false);
          getKyc();
          showSuccessToast(resp.Message);
        } else {
          showErrorToast(resp.Message);
          setkycLoader(false);
        }
      } else {
        showErrorToast("Please give all proof");
      }
    } catch (error) {}
  };

  const save_id = async () => {
    try {
      console.log("idNumber", idNumberref.current);
      console.log("valididproof", valididproof);
      if (valididproof == 2 && idNumberref.current != null) {
        setstep_active("2");
      } else {
        showErrorToast("Please submit all the details!");
      }
    } catch (error) {}
  };

  const save_address = async () => {
    try {
      console.log("valididproof", validaddressProof);
      if (validaddressProof == 2) {
        setstep_active("3");
      } else {
        showErrorToast("Please submit all the details!");
      }
    } catch (error) {}
  };

  const getKyc = async () => {
    try {
      var data = {
        apiUrl: apiService.getKYC,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        Setuser(resp.datas.userDetails);
        console.log(user, "user");
        if (resp.datas.kycDetails) {
          setvalididproof(2);
          setvalidaddressProof(2);
          setvalidaddresIDFront(2);
          setvalidaddresIDBack(2);
          setvalidphotoproof(2);
        }
        if (Object.keys(resp.datas.kycDetails).length > 0) {
          var kycData = resp.datas.kycDetails;

          setgetKYCData(kycData);
          console.log(kycData.proof1, "kycData.proof1");
          setidproof(kycData.proof1);
          console.log(idproofref.current, "kycData.proof1");

          formValue.idNumber = kycData.IdNumber;
          setaddressProof(kycData.proof2);
          setaddresIDFront(kycData.proof3);
          setaddresIDBack(kycData.proof4);
          setphotoproof(kycData.proof3);
          var filetype_front = kycData.proof1.split(".").pop().trim();
          var filetype_back = kycData.proof2.split(".").pop().trim();
          var filetype_photoproof = kycData.proof3.split(".").pop().trim();
          if (
            filetype_front == "pdf" ||
            filetype_front == "doc" ||
            filetype_front == "docx"
          ) {
            setvalididproof(1);
          }
          if (
            filetype_back == "pdf" ||
            filetype_back == "doc" ||
            filetype_back == "docx"
          ) {
            setvalidaddressProof(1);
          }
          if (
            filetype_photoproof == "pdf" ||
            filetype_photoproof == "doc" ||
            filetype_photoproof == "docx"
          ) {
            setvalidphotoproof(1);
          }

          if (kycData.kycStatus == 3) {
            setvalididproof(2);
            setvalidaddressProof(2);
            setvalidphotoproof(2);
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getKyc();
  }, [0]);

  const getValue = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setformValue(formData);
  };

  const handleChange_id = async (e) => {
    e.preventDefault();
    if (e.target.value != null) {
      setidValidate(false);
      setidNumber(e.target.value);
      console.log("idNumber", idNumberref.current);
    } else {
      setidValidate(true);
    }
  };

  return (
    <main className="kyc_main">
      <div>
        <Home_header />
      </div>

      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-6">
              <section className="kyc_section px-3">
                <div className="container-fluid">
                  <div className="kyc_title kyc_title_2">
                    <h2>Verification</h2>
                    {/* <p>
              Status: <span>verified</span>
            </p> */}
                    {getKYCData.kycStatus == 3 ? (
                      <p>
                        Status :{" "}
                        <span className="kyctextcolorreject"> Rejected</span>
                      </p>
                    ) : getKYCData.kycStatus == 2 ? (
                      <p>
                        Status :{" "}
                        <span className="kyctextcolorpending"> Pending</span>
                      </p>
                    ) : getKYCData.kycStatus == 1 ? (
                      <p>
                        Status :{" "}
                        <span className="kyctextcolorverified"> Verified</span>
                      </p>
                    ) : (
                      <p>
                        Status :{" "}
                        <span className="kyctextcolorreject"> Not Upload</span>
                      </p>
                    )}
                    <p className="kyc_verify_text">
                      Complete identity verification to access all services. It
                      will require only a few minutes to complete
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-lg-11">
                      <div className="Individual_account">
                        <h3>Individual Account</h3>
                        {/* 
                        <div>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <Typography>
                                <div className="level1_content">
                                  <img
                                    src={require("../images/landing_new/level_1icon.png")}
                                  />
                                  <div className="level_text">
                                    <h3>Level 1: Basic</h3>
                                    <p>Account Verification</p>
                                  </div>
                                </div>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <div className="enabel_content">
                                  <h3>Enables:</h3>
                                  <div className="">
                                    <p>Deposit Crypto & Fiat</p>
                                    <p>Withdrawal Crypto & Fiat</p>
                                    <p>Spot Trading</p>
                                    <p>P2P Trading</p>
                                  </div>
                                  <h3>Limits:</h3>
                                  <div className="limits_content">
                                    <div className="w-50">
                                      <p>Deposit</p>
                                      <h5>Total</h5>
                                      <h4>15,000 USDN</h4>
                                    </div>
                                    <div className="w-50">
                                      <p>Withdrawal</p>
                                      <h5>Daily</h5>
                                      <h4>10,000 USDN</h4>
                                      <h5>Monthly</h5>
                                      <h4>15,000 USDN</h4>
                                      <h5>Total</h5>
                                      <h4>No limits</h4>
                                    </div>
                                  </div>
                                  <h3>Requirement:</h3>
                                  <div className="">
                                    <p>Phone Number</p>
                                    <p>ID Verification</p>
                                    <p>Liveness Check</p>
                                    <p>AML Questionnaire</p>
                                  </div>
                                </div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <Typography>
                                <div className="level1_content">
                                  <img
                                    src={require("../images/landing_new/level_2icon.png")}
                                  />
                                  <div className="level_text">
                                    <h3>Level 2: Advanced</h3>
                                    <p>Account Verification</p>
                                  </div>
                                </div>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <div className="enabel_content">
                                  <h3>Enables:</h3>
                                  <div className="">
                                    <p>Higher API Limits</p>
                                  </div>
                                  <h3>Limits:</h3>
                                  <div className="limits_content">
                                    <div className="w-50">
                                      <p>Deposit</p>
                                      <h5>Total</h5>
                                      <h4>15,000 USDN</h4>
                                    </div>
                                    <div className="w-50">
                                      <p>Withdrawal</p>
                                      <h5>Daily</h5>
                                      <h4>10,000 USDN</h4>
                                      <h5>Monthly</h5>
                                      <h4>15,000 USDN</h4>
                                      <h5>Total</h5>
                                      <h4>No limits</h4>
                                    </div>
                                  </div>
                                  <h3>Requirement:</h3>
                                  <div className="">
                                    <p>Proof of Address</p>
                                    <p>Source of Wealth</p>
                                    <p>Source of Funds</p>
                                  </div>
                                </div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div> */}

                        <div className="individual_kyc_box">
                          <div className="identification_features">
                            <div className="individual_title mb-3">
                              Unlock Exculsive Perks After Verification
                            </div>
                            <div className="features-box">
                              <h3 className="feature-title">Trading</h3>
                              <p className="text-yellow">
                                <i class="ri-check-line"></i>
                              </p>
                            </div>
                            <div className="features-box">
                              <h3 className="feature-title">Withdrawals</h3>
                              <p className="text-yellow">
                                <i class="ri-check-line"></i>
                              </p>
                            </div>
                            <div className="features-box">
                              <h3 className="feature-title">Crypto Deposit</h3>
                              <p className="text-yellow">
                                <i class="ri-check-line"></i>
                              </p>
                            </div>
                            <div className="features-box">
                              <h3 className="feature-title">P2P trading</h3>
                              <p className="text-yellow">
                                <i class="ri-check-line"></i>
                              </p>
                            </div>{" "}
                            <div className="features-box">
                              <h3 className="feature-title">Staking</h3>
                              <p className="text-yellow">
                                <i class="ri-check-line"></i>
                              </p>
                            </div>
                            {/* <div className="my-4">
                              {getKYCData.kycstatus == 2 ? (
                                <button
                                  disabled
                                  style={{ backgroundColor: "grey" }}
                                  className="action_btn opt-nowrap w-100 di"
                                  type="button"
                                >
                                  Pending
                                </button>
                              ) : getKYCData.kycstatus == 1 ? (
                                <Link to="/deposit">
                                  <button
                                    className="action_btn opt-nowrap w-100"
                                    type="button"
                                  >
                                    Deposit
                                  </button>
                                </Link>
                              ) : (
                                <button
                                  className="action_btn opt-nowrap w-100"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  Verify Now
                                </button>
                              )}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {getKYCData == "" ? (
              <div className="col-lg-3 complete_verify_section">
                <div className="complete_verify_content">
                  <img
                    src={require("../images/landing_new/verify_level_asset.png")}
                  />
                  <h3>Complete Individual Verification</h3>
                  <p>
                    Begin your adventure world by completing the verification
                  </p>

                  <button
                    className="verify_now_btn"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#myModal"
                  >
                    Verify Now
                  </button>
                </div>
              </div>
            ) : getKYCData.kycStatus == 3 || getKYCData.kycStatus == 0 ? (
              <div className="col-lg-3 complete_verify_section">
                <div className="complete_verify_content">
                  <img
                    src={require("../images/landing_new/verify_level_asset.png")}
                  />
                  <h3>Complete Individual Verification</h3>
                  <p>
                    Begin your adventure world by completing the verification
                  </p>

                  <button
                    className="verify_now_btn"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#myModal"
                  >
                    Verify Now
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row faq_margin">
              <div className="col-lg-6">
                <section className="faq-section">
                  <div className="faq_list">
                    <h3>FAQ</h3>
                    <div className="Accordion">
                      <Accordion
                        className="Accordionfaq"
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                      >
                        <AccordionSummary
                          expandIcon={expanded === "panel1" ? "-" : "+"}
                          aria-controls="panel1-content"
                          className="Accordionhead"
                          id="panel1-header"
                        >
                          1. What is identity verification?
                        </AccordionSummary>
                        <AccordionDetails className="Accordionans">
                          Identity verification is the process of confirming
                          that a person's identity matches the information
                          provided.
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        className="Accordionfaq"
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                      >
                        <AccordionSummary
                          expandIcon={expanded === "panel2" ? "-" : "+"}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          2. Why do I need to complete identity verification?
                        </AccordionSummary>
                        <AccordionDetails>
                          This helps prevent fraud and ensures that services are
                          provided to legitimate individuals.
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        className="Accordionfaq"
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                      >
                        <AccordionSummary
                          expandIcon={expanded === "panel3" ? "-" : "+"}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          3. Which documents and information are required for
                          identity verification?
                        </AccordionSummary>
                        <AccordionDetails>
                          The verification process typically involves providing
                          personal information along with supporting documents
                          for a national ID, address proof, and photo proof.
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        className="Accordionfaq"
                        expanded={expanded === "panel4"}
                        onChange={handleChange("panel4")}
                      >
                        <AccordionSummary
                          expandIcon={expanded === "panel4" ? "-" : "+"}
                          aria-controls="panel4-content"
                          id="panel4-header"
                        >
                          4. Where can I find help for issues regarding identity
                          verification?
                        </AccordionSummary>
                        <AccordionDetails>
                          Contact the exchange's customer support. Use this
                          link:{" "}
                          <Link to="/support">https://netex.io/support</Link>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          {/* <!-- Button trigger modal --> */}

          {/* <!-- Modal --> */}
          <div
            class="modal fade bd-example-modal-lg"
            id="myModal"
            role="dialog"
          >
            {step_activeref.current == "1" ? (
              <>
                <span>National Id</span>
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content select_coin">
                    <div class="modal-header kyc_modal_header">
                      <h4 class="modal-title ml-0">Verification</h4>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        x
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body pt-0">
                      <div className="check_radio">
                        <ul class="nav nav-tabs">
                          <li className="w-100">
                            <a data-toggle="tab" href="#menu2">
                              <div className="image_div">
                                <div className="cicler">
                                  <img
                                    src={require("../images/dashboard/cicler_assets.png")}
                                    className=""
                                  />
                                </div>
                                National ID
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="upload">
                        <div class="mb-6 pt-4">
                          <div class="formbold-mb-5 formbold-file-input">
                            {user.kycstatus == 0 ||
                            user.kycstatus == 3 ||
                            valididproof == 0 ? (
                              <input
                                type="file"
                                className="fileinput"
                                name="image"
                                onChange={(e) =>
                                  imageUpload("idproof", e.target.files[0])
                                }
                                id="file1"
                              />
                            ) : (
                              ""
                            )}

                            <label for="file1" className="file123">
                              {idproofLoad == false ? (
                                valididproof == 0 ? (
                                  <div>
                                    <span class="formbold-drop-file">
                                      <img
                                        className="cursor"
                                        src={require("../images/dashboard/proof_id.png")}
                                      />
                                    </span>
                                  </div>
                                ) : valididproof == 1 ? (
                                  <span className="">
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                  </span>
                                ) : (
                                  <img
                                    src={idproofref.current}
                                    className="img-fluid"
                                    alt="National Id Front"
                                  />
                                )
                              ) : (
                                <div
                                  class="spinner-border spinner_kyc "
                                  role="status"
                                >
                                  <i class="fa fa-spinner d-none"></i>{" "}
                                </div>
                              )}
                            </label>
                          </div>

                          {idproofnameref.current == "" ? (
                            ""
                          ) : (
                            <div class="formbold-file-list formbold-mb-5">
                              <div class="formbold-file-item d-flex justify-content-between">
                                <span class="formbold-file-name">
                                  {" "}
                                  {idproofnameref.current}{" "}
                                </span>
                                <button onClick={cancelidproof}>
                                  <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="upload_warn_text">
                        Please ensure that you enter your original National ID
                        number accurately. Providing false or incorrect
                        information is a violation of our terms and may lead to
                        serious consequences, including but not limited
                      </div>
                      <div className="upload_input">
                        <h3>Enter Your National Id</h3>
                        <input
                          type="text"
                          placeholder="National Id"
                          value={idNumberref.current}
                          onChange={handleChange_id}
                          className="upload-national-input"
                        />
                      </div>
                    </div>
                    <div class="modal-footer">
                      {getKYCData.kycStatus == 2 ||
                      getKYCData.kycStatus == 1 ? (
                        ""
                      ) : (
                        <div>
                          <Button
                            className="mx-2 primary-btn cancel_btn"
                            data-bs-dismiss="modal"
                            onClick={cancelData}
                          >
                            Cancel
                          </Button>
                          {kycLoader == false ? (
                            <Button
                              // data-bs-dismiss="modal"
                              onClick={save_id}
                              className="primary-btn"
                            >
                              Next
                            </Button>
                          ) : (
                            <Button
                              className="primary-btn"
                              data-bs-dismiss="modal"
                            >
                              Loading...
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {step_activeref.current == "2" ? (
              <>
                <span>Proof Of Address</span>
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content select_coin">
                    <div class="modal-header kyc_modal_header">
                      <h4 class="modal-title ml-0">Verification</h4>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        x
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body pt-0">
                      <div className="check_radio">
                        <ul class="nav nav-tabs">
                          <li className="w-100">
                            <a data-toggle="tab" href="#menu2">
                              <div className="image_div">
                                <div className="cicler">
                                  <img
                                    src={require("../images/dashboard/cicler_assets.png")}
                                    className=""
                                  />
                                </div>
                                Proof Of Address
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="upload">
                        <div class="mb-6 pt-4">
                          <div class="formbold-mb-5 formbold-file-input">
                            {user.kycstatus == 0 ||
                            user.kycstatus == 3 ||
                            validaddressProof == 0 ? (
                              <input
                                type="file"
                                className="fileinput"
                                name="image"
                                onChange={(e) =>
                                  imageUpload("addressProof", e.target.files[0])
                                }
                                id="file1"
                              />
                            ) : (
                              ""
                            )}

                            <label for="file1" className="file123">
                              {addressProofLoad == false ? (
                                validaddressProof == 0 ? (
                                  <div>
                                    <span class="formbold-drop-file">
                                      <img
                                        className="cursor"
                                        src={require("../images/dashboard/proof_id.png")}
                                      />
                                    </span>
                                  </div>
                                ) : validaddressProof == 1 ? (
                                  <span className="">
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                  </span>
                                ) : (
                                  <img
                                    src={addressProofref.current}
                                    className="img-fluid"
                                    alt="Address Proof Front"
                                  />
                                )
                              ) : (
                                <div
                                  class="spinner-border spinner_kyc "
                                  role="status"
                                >
                                  <i class="fa fa-spinner d-none"></i>{" "}
                                </div>
                              )}
                            </label>
                          </div>

                          {addressproofnameref.current == "" ? (
                            ""
                          ) : (
                            <div class="formbold-file-list formbold-mb-5">
                              <div class="formbold-file-item d-flex justify-content-between">
                                <span class="formbold-file-name">
                                  {" "}
                                  {addressproofnameref.current}{" "}
                                </span>
                                <button onClick={canceladdressproof}>
                                  <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      {getKYCData.kycStatus == 2 ||
                      getKYCData.kycStatus == 1 ? (
                        ""
                      ) : (
                        <div>
                          <Button
                            className="mx-2 primary-btn cancel_btn"
                            data-bs-dismiss="modal"
                            onClick={cancelData}
                          >
                            Cancel
                          </Button>
                          {kycLoader == false ? (
                            <Button
                              // data-bs-dismiss="modal"
                              onClick={save_address}
                              className="primary-btn"
                            >
                              Next
                            </Button>
                          ) : (
                            <Button
                              className="primary-btn"
                              data-bs-dismiss="modal"
                            >
                              Loading...
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {step_activeref.current == "3" ? (
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content select_coin">
                  <div class="modal-header kyc_modal_header">
                    <h4 class="modal-title ml-0">Verification</h4>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      x
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body pt-0">
                    <div className="check_radio">
                      <ul class="nav nav-tabs">
                        <li className="w-100">
                          <a data-toggle="tab" href="#menu2">
                            <div className="image_div">
                              <div className="cicler">
                                <img
                                  src={require("../images/landing_new/upload_icon.png")}
                                  className=""
                                />
                              </div>
                              Photo Proof
                              <p>(Take Selfie With Your Document)</p>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="upload">
                      <div class="mb-6 pt-4">
                        <div class="formbold-mb-5 formbold-file-input">
                          {user.kycstatus == 0 ||
                          user.kycstatus == 3 ||
                          validphotoproof == 0 ? (
                            <input
                              type="file"
                              className="fileinput"
                              name="image"
                              onChange={(e) =>
                                imageUpload("photoproof", e.target.files[0])
                              }
                              id="file1"
                            />
                          ) : (
                            ""
                          )}

                          <label for="file1" className="file123">
                            {photoproofLoad == false ? (
                              validphotoproof == 0 ? (
                                <div>
                                  <span class="formbold-drop-file">
                                    <img
                                      className="cursor"
                                      src={require("../images/dashboard/proof_id.png")}
                                    />
                                  </span>
                                </div>
                              ) : validphotoproof == 1 ? (
                                <span className="">
                                  <i class="bi bi-file-earmark-bar-graph"></i>
                                </span>
                              ) : (
                                <img
                                  src={photoproofref.current}
                                  className="img-fluid"
                                  alt="National Id Front"
                                />
                              )
                            ) : (
                              <div
                                class="spinner-border spinner_kyc "
                                role="status"
                              >
                                <i class="fa fa-spinner d-none"></i>{" "}
                              </div>
                            )}
                          </label>
                        </div>

                        {photoproofnameref.current == "" ? (
                          ""
                        ) : (
                          <div class="formbold-file-list formbold-mb-5">
                            <div class="formbold-file-item d-flex justify-content-between">
                              <span class="formbold-file-name">
                                {" "}
                                {photoproofnameref.current}{" "}
                              </span>
                              <button onClick={cancelphotoproof}>
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    {getKYCData.kycStatus == 2 || getKYCData.kycStatus == 1 ? (
                      ""
                    ) : (
                      <div>
                        <Button
                          className="mx-2 primary-btn cancel_btn"
                          data-bs-dismiss="modal"
                          onClick={cancelData}
                        >
                          Cancel
                        </Button>
                        {kycLoader == false ? (
                          <Button
                            data-bs-dismiss="modal"
                            onClick={uploadKYC}
                            className="primary-btn"
                          >
                            Upload
                          </Button>
                        ) : (
                          <Button
                            className="primary-btn"
                            data-bs-dismiss="modal"
                          >
                            Loading...
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </main>
  );
};

export default Kyc;
