import React from "react";
import Home_header from "./Home_header";
import { Bars } from "react-loader-spinner";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";

const Change_password = () => {
  const navigate = useNavigate();

  const back = () => {
    navigate("/security");
  };
  const initialFormValue = {
    oldpassword: "",
    password: "",
    confirmPassword: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);

  const { oldpassword, password, confirmPassword } = formValue;
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [oldpassvalidate, setoldpassvalidate, oldpassvalidateref] =
    useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [passHidnew, setPasshidenew] = useState(false);
  const [inputTypenew, setinputTypenew] = useState("password");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");

  const validate = async (values) => {
    const errors = {};
    if (values.oldpassword == "") {
      errors.oldpassword = "Old Password is required";
      setoldpassvalidate(true);
    } else {
      setoldpassvalidate(false);
    }

    if (values.password == "") {
      setpasswordValidate(true);
      errors.password = "Password is required";
    } else if (values.password.length < 8 || values.password.length > 45) {
      setpasswordValidate(true);
      errors.password =
        "New Password is required and shouldnot below 8 above 45 letters";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter alone digit character";
    } else if (values.password == values.oldpassword) {
      errors.password = "Old Password and New password should not be same";
      setpasswordValidate(true);
    } else {
      setpasswordValidate(false);
    }

    if (values.confirmPassword == "") {
      errors.confirmPassword = "Confirm password is a required";
      setconfirmPasswordValidate(true);
    } else if (values.confirmPassword != values.password) {
      setconfirmPasswordValidate(true);
      errors.confirmPassword = "Password and confirm password does not match";
    } else {
      setconfirmPasswordValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };
  const [buttonStatus, setButtonstatus] = useState(false);
  const [siteLoader, setSiteLoader] = useState(false);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
    if (
      confirmPasswordValidateref.current == false &&
      passwordValidateref.current == false &&
      oldpassvalidateref.current == false
    ) {
      setButtonstatus(true);
    } else {
      setButtonstatus(false);
    }
  };

  const formSubmit = async () => {
    validate(formValue);
    console.log(formValue, "formValue");
    if (
      confirmPasswordValidateref.current == false &&
      passwordValidateref.current == false &&
      oldpassvalidateref.current == false
    ) {
      var obj = {
        oldPass: formValue.oldpassword,
        password: formValue.password,
        cpass: formValue.confirmPassword,
      };

      var data = {
        apiUrl: apiService.changePassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        localStorage.clear();
        navigate("/login");
        window.location.reload(true);
      } else {
        toast.error(resp.Message);
      }
    }
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const passwordHidenewP = (data) => {
    if (data == "hide") {
      setPasshidenew(true);
      setinputTypenew("text");
    } else {
      setPasshidenew(false);
      setinputTypenew("password");
    }
  };

  return (
    <main className="user_profile_main h-100">
      <div>
        <Home_header />
      </div>

      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <section className="user_profile_section">
            <div className="container">
              <div className="profile_title" onClick={back}>
                <i class="fa-solid fa-angle-left"></i>Change Password
              </div>
              <div className="kyc_border"></div>
              <div className="row justify-content-center">
                <div className="col-lg-6 smart_login_row ">
                  <div className="card_login register_page_login_card user-profile p-4">
                    <h3>Change Password</h3>
                    <div className="steps_change_password">
                      <p>
                        {" "}
                        1.To protect your account security, keep your login
                        password safe
                        <br /> and do not disclose it to others.
                      </p>
                      <p>2. Please do not use your previous passwords.</p>
                    </div>
                    <div>
                      <label className="lable_text">Old Password</label>
                      <div class="form_login_input position_eye password_border">
                        <input
                          type={inputType}
                          autocomplete="off"
                          name="oldpassword"
                          placeholder="Old Password"
                          className="form-control"
                          maxLength={40}
                          value={oldpassword}
                          onChange={handleChange}
                        />
                        <div className="eye_content_pass">
                          {passHide == false ? (
                            <i
                              className="fa-regular fa-eye-slash icons"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          ) : (
                            <i
                              className="fa-regular fa-eye icons"
                              onClick={() => passwordHide("show")}
                            ></i>
                          )}
                        </div>
                      </div>
                      {oldpassvalidate == true ? (
                        <small className="errorcss">
                          {validationnErr.oldpassword}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <label className="lable_text">New Password</label>
                      <div class="form_login_input position_eye password_border">
                        <input
                          type={inputTypenew}
                          autocomplete="off"
                          name="password"
                          maxLength={40}
                          placeholder="New Password"
                          className="form-control"
                          value={password}
                          onChange={handleChange}
                        />
                        <div className="eye_content_pass">
                          {passHidnew == false ? (
                            <i
                              className="fa-regular fa-eye-slash icons"
                              onClick={() => passwordHidenewP("hide")}
                            ></i>
                          ) : (
                            <i
                              className="fa-regular fa-eye icons"
                              onClick={() => passwordHidenewP("show")}
                            ></i>
                          )}
                        </div>
                      </div>
                      {passwordValidate == true ? (
                        <small className="errorcss">
                          {" "}
                          {validationnErr.password}{" "}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <label className="lable_text">Confirm Password</label>
                      <div class="form_login_input position_eye password_border">
                        <input
                          type={inputTypeconf}
                          autocomplete="off"
                          name="confirmPassword"
                          maxLength={40}
                          placeholder="Confirm Password"
                          className="form-control"
                          value={confirmPassword}
                          onChange={handleChange}
                        />
                        <div className="eye_content_pass">
                          {passHidconf == false ? (
                            <i
                              className="fa-regular fa-eye-slash icons"
                              onClick={() => passwordHideconf("hide")}
                            ></i>
                          ) : (
                            <i
                              className="fa-regular fa-eye icons"
                              onClick={() => passwordHideconf("show")}
                            ></i>
                          )}
                        </div>
                      </div>
                      {confirmPasswordValidate == true ? (
                        <small className="errorcss">
                          {" "}
                          {validationnErr.confirmPassword}{" "}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="profile_submit_btn">
                      {buttonLoader == false ? (
                        <button onClick={formSubmit}>Submit</button>
                      ) : (
                        <button>loading...</button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </main>
  );
};

export default Change_password;
