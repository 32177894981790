import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Home_header.js";
import "semantic-ui-css/semantic.min.css";
import Moment from "moment";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";

function P2p() {
    useEffect(() => {
        getp2pstatus();
    }, []);
    const [p2pstatus, setp2pstatus, p2pstatusref] = useState("");
    const getp2pstatus = async () => {
        try {
            const data = {
                apiUrl: apiService.getP2Pstatus,
            };

            const response = await getMethod(data);
            if (response.status) {
                setp2pstatus(response.p2pstatus);
            }
        } catch (err) {
            console.error("Error fetching currencies:", err);
        }
    };
    const [pageLoader, setPageloader] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        setPageloader(true);

        setTimeout(() => {
            setPageloader(false);
        }, 1000);
    }, [0]);

    const [p2pOrders1, setp2pOrders, p2pOrdersref] = useState([]);
    const [p2pcurrentpage, setp2pcurrentpage, p2pcurrentpageref] = useState(1);
    const [p2ptotalpages, setp2pTotalpages, p2ptotalpageref] = useState(0);

    useEffect(() => {
        getp2pOrders(1); // Load first page initially
    }, []);

    const getp2pOrders = async (page = 1) => {
        setPageloader(true);
        try {
            var data = {
                apiUrl: apiService.p2pOrders,
                payload: { FilPerpage: 5, FilPage: page },
            };
            var p2p_orders_list = await postMethod(data);
            setPageloader(false);

            if (p2p_orders_list.status === true) {
                setp2pOrders(p2p_orders_list.returnObj.Message);
                setp2pTotalpages(p2p_orders_list.returnObj.pages);
                setp2pcurrentpage(page);
            }
        } catch (error) {
            console.error("Error fetching P2P orders:", error);
        }
    };

    const navpage = async (link) => {
        navigate("/p2p/order/" + link);
    };

    return (
        <>
            <main className="nextex_p2p_main">
                <section>
                    <Header />
                </section>
                {pageLoader == true ? (
                    <div className="dashboard_content_section loadercss">
                        <Bars
                            height="40"
                            width="40"
                            color="#1062fe"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <section className="nextex_p2p_section">
                        <div className="container">
                            <div className="nextex_p2p_section_content">
                                <ul
                                    class="nav nav-tabs p2p_order_tabs"
                                    id="myTab"
                                    role="tablist"
                                >
                                    <div className="">
                                        <li class="nav-item" role="presentation">
                                            <Link to="/p2p">
                                                <button class="nav-link " id="home-tab">
                                                    <i class="ri-p2p-line"></i> P2P Offers
                                                </button>
                                            </Link>
                                        </li>
                                    </div>
                                    <div className="p2p_order_tab2">
                                        <li class="nav-item" role="presentation">
                                            <Link to="/p2porder">
                                                <button class="nav-link " id="profile-tab">
                                                    <i class="ri-file-list-2-line"></i> Orders
                                                </button>
                                            </Link>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <Link to="/p2poffers">
                                                <button class="nav-link active" id="contact-tab">
                                                    <i class="ri-file-text-line"></i> My Offers
                                                </button>
                                            </Link>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <Link to="/p2paccount">
                                                <button class="nav-link" id="account-tab">
                                                    <i class="ri-account-box-line"></i> My P2P Account
                                                </button>
                                            </Link>
                                        </li>

                                        {p2pstatusref.current == "Deactive" ? (
                                            ""
                                        ) : (
                                            <Link to="/postad">
                                                {" "}
                                                <li>
                                                    <button className="create_offer_btn">
                                                        {" "}
                                                        <i class="ri-add-large-line"></i> Create Offer
                                                    </button>
                                                </li>
                                            </Link>
                                        )}
                                    </div>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div
                                        class="tab-pane fade show active"
                                        id="contact-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="contact-tab"
                                        tabindex="0"
                                    >
                                        <div className="table-responsive table-cont">
                                            <table className="table pt-4">
                                                <thead>
                                                    <tr className="stake-head">
                                                        <th>Date Created</th>
                                                        <th className="table_center_text">Currency</th>
                                                        <th className="table_center_text">
                                                            Quantity (Crypto)
                                                        </th>
                                                        <th className="table_center_text">Price (Fiat)</th>
                                                        <th className="table_center_text">Unit</th>
                                                        <th className="table_center_text">Order Type</th>
                                                        <th className="table_action">
                                                            Status{" "}
                                                            <span className="span_color">
                                                                <i class="ri-information-2-line"></i>
                                                            </span>{" "}
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {p2pOrdersref.current &&
                                                        p2pOrdersref.current.length > 0 ? (
                                                        p2pOrdersref.current.map((item, i) => (
                                                            <tr key={i} onClick={() => navpage(item.orderId)}>
                                                                <td className="table-flex d-flex">
                                                                    <img
                                                                        src={item.fromCurrency.Currency_image}
                                                                        alt=""
                                                                        width="30px"
                                                                        height="30px"
                                                                    />
                                                                    <div className="table-opt-name">
                                                                        <h4 className="opt-name font_14">
                                                                            {item.fromCurrency.currencyName}
                                                                        </h4>
                                                                        <h3 className="opt-sub font_14">
                                                                            {item.fromCurrency.currencySymbol}
                                                                        </h3>
                                                                    </div>
                                                                </td>
                                                                <td className="opt-percent font_14 table_center_text pad-left-23 text-nowrap">
                                                                    {Moment(item.createdAt).format("lll")}
                                                                </td>
                                                                <td className="opt-term font_14 table_center_text pad-left-23">
                                                                    {parseFloat(item.totalAmount).toFixed(4)}
                                                                </td>
                                                                <td className="opt-term font_14 table_center_text pad-left-23">
                                                                    {parseFloat(item.price).toFixed(4)}
                                                                </td>
                                                                <td className="opt-term font_14 table_center_text pad-left-23 text-nowrap">
                                                                    {parseFloat(item.fromLimit).toFixed(4)} -{" "}
                                                                    {parseFloat(item.toLimit).toFixed(4)}{" "}
                                                                    {item.firstCurrency}
                                                                </td>
                                                                <td
                                                                    className={`opt-term font_14 table_center_text pad-left-23 ${item.orderType === "buy"
                                                                            ? "text-green"
                                                                            : "text-sell-red"
                                                                        }`}
                                                                >
                                                                    {item.orderType}
                                                                </td>
                                                                <td className="opt-btn-flex opt-term table-action pad-left-23 text-center">
                                                                    {item.status}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colspan="8">
                                                                {" "}
                                                                <div className="no data">
                                                                    <img
                                                                        src={require("../images/landing_new/no_data_asset.png")}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </main>
        </>
    );
}

export default P2p;
