import React, { useEffect } from "react";
import Home_header from "./Home_header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

function Home() {
  return (
    <main className="dashboard_page">
      <section>
        <Home_header />
      </section>

      <section className="dashboard_content_section">
        <div className="container">
          <div className="smart_dashboard_content">
            <h2>
              Net1Ex P2P -<span>Terms of Use</span>{" "}
            </h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_board_profile_content">
                  <div className="contentpage">
                    <h4>Net1Ex Peer-to-peer (P2P)</h4>

                    <p>
                      By subscribing to Peer-to-Peer service available at our
                      crypto exchange{" "}
                      <a href="https://net1ex.com/p2p">
                        https://net1ex.com/p2p
                      </a>{" "}
                      or such other URL as may be designated by Net1Ex from time
                      to time, as well as any mobile apps or other related
                      services or applications thereto (“Net1Ex P2P”), you
                      acknowledge that you have read, understood and accepted
                      all of the terms and conditions in these Peer-to-Peer
                      Product Terms (“P2P Terms”), and you acknowledge and agree
                      that you will be bound by and will comply with these P2P
                      Terms. If you do not understand and accept these P2P Terms
                      in their entirety, you should not subscribe or use the
                      services provided by Net1Ex.
                    </p>

                    <h4>A. Definitions</h4>

                    <p>
                      <b> “Advertiser”</b> means a user publishing an
                      Advertisement. An Advertiser may be also referred to as a
                      “Maker” from time to time.
                    </p>
                    <p>
                      <b> "Advertisement"</b>means an offer published on Net1Ex
                      P2P that includes the amount of Digital Assets that the
                      user would like to trade, the price for such Digital
                      Assets, the payment method that the user would like to use
                      when trading, the Order Limit, the Payment Duration and
                      other applicable terms and conditions for the offer as
                      determined by the user publishing the offer.
                    </p>
                    <p>
                      <b>“Buyer” </b> means a user trading on Net1Ex P2P to
                      purchase Digital Assets, either as an Advertiser or a
                      Taker.
                    </p>
                    <p>
                      <b>“Cash Merchant”</b> has the meaning given to it in
                      clause 14.
                    </p>
                    <p>
                      <b> “Order” </b> means a promised trade of Digital Assets
                      on Net1Ex P2P that the Advertiser and the Taker have
                      agreed upon based on the terms and conditions listed in
                      the relevant Advertisement. An Order represents an
                      acceptance of the offer to trade published in an
                      Advertisement.
                    </p>
                    <p>
                      <b>“Order Limit"</b> means the minimum and maximum amount
                      of Digital Assets or Fiat Currency that Takers can trade
                      in a single Order, as determined in a specific
                      Advertisement by the Advertiser.
                    </p>
                    <p>
                      <b> “P2P Terms”</b> means these Peer-to-Peer Product Terms
                      and Conditions.
                    </p>
                    <p>
                      <b>“Payment Duration”</b> means the time period set by the
                      Advertiser within which the Fiat Currency transfer in
                      consideration of the Digital Assets should take place.
                    </p>
                    <p>
                      <b> “Seller”</b> means a user trading on Net1Ex P2P to
                      sell Digital Assets, either as an Advertiser or a Taker.
                    </p>
                    <p>
                      <b>“Taker”</b> means a user who places an Order to an
                      Advertisement previously published by an Advertiser.
                    </p>
                    <p>
                      <b>“Third Party Content”</b> has the meaning given to it
                      in clause 22.
                    </p>
                    <p>
                      <b>“Transfer Fees”</b> means the sum of all fees or
                      charges imposed by the payment service provider of the
                      payment method selected by the parties in consideration
                      for the transfer of Fiat Currency from the Buyer to the
                      Seller.
                    </p>

                    <h4>B. Overview of Net1Ex P2P</h4>
                    <p>
                      1. Net1Ex P2P is a platform that allows users to directly
                      trade Digital Assets with other users. You may trade on
                      Net1Ex P2P to buy or sell Digital Assets as an Advertiser
                      or a Taker. As an Advertiser, you are allowed to have up
                      to 2 buy or 2 sell active offers for the same fiat-crypto
                      pair on Net1Ex P2P to buy or sell Digital Assets
                      (“Advertisement”). An Advertisement includes the amount of
                      Digital Assets that you would like to trade, the price
                      that you would like to trade for your Digital Assets, the
                      Order Limit, the payment method that you would like to
                      use, the Payment Duration and other applicable terms and
                      conditions for your offer. In consideration for the use of
                      Net1Ex P2P, we may charge Advertiser fees. We may adjust
                      the fees at our sole discretion from time to time
                    </p>
                    <p>
                      2. You may either be a Buyer or a Seller of Digital Assets
                      on Net1Ex P2P regardless of whether you are acting as a
                      Advertiser or a Taker.
                    </p>
                    <p>
                      3. We may require you to satisfy certain eligibility
                      criteria to be able to publish an Advertisement on Net1Ex
                      P2P, as may be amended by Net1Ex from time to time.
                    </p>
                    <p>
                      4. You understand and agree that placing an Order to an
                      Advertisement on Net1Ex P2P represents your acceptance of
                      the offer published in that Advertisement and constitutes
                      a promise to trade Digital Assets. In such a case, Net1Ex
                      locks the amount of Digital Assets indicated in the Order
                      until both parties of the transaction confirm successful
                      completion of the Fiat Currency transfer to be performed
                      in consideration of the Digital Assets. You understand
                      that the locked amount of Digital Assets cannot be used in
                      any way whatsoever unless the Order is canceled or the
                      Digital Assets are released to the Buyer upon confirmation
                      of the Fiat Currency transfer by both the Buyer and the
                      Seller.{" "}
                    </p>
                    <p>
                      5. Net1Ex is not a party to any transaction that you may
                      enter into on Net1Ex P2P as you will be directly trading
                      with other users. Net1Ex does not have any control
                      whatsoever over the trading amount, price, payment method,
                      Order Limit, Payment Duration and terms and conditions of
                      each Advertisement as they are entirely set by the
                      Advertiser at its own discretion. Net1Ex only facilitates
                      the transactions entered into on Net1Ex P2P as described
                      in Article 4 above
                    </p>

                    <h4>C. Choice of Payment Method</h4>
                    <p>
                      6. Payment methods available on Net1Ex P2P are displayed
                      as options available to users to perform the Fiat Currency
                      transfer in consideration of the trade of the Digital
                      Assets. Unless expressly provided otherwise, Net1Ex does
                      not have any form of relationship with the payment methods
                      listed on Net1Ex P2P. Availability of a payment method on
                      Net1Ex P2P shall not be construed as a recommendation,
                      vetting or endorsement by Net1Ex.
                    </p>
                    <p>
                      7.To be able to trade on Net1Ex P2P, each Advertiser must
                      indicate one or multiple payment method(s) for their
                      Advertisements, which must be a payment method available
                      in Net1Ex P2P and each Taker placing an Order to such
                      Advertisement must agree to use one of the payment
                      method(s) indicated by the Advertiser for the Fiat
                      Currency transfer to be completed in consideration of the
                      trade of the Digital Assets.
                    </p>
                    <p>
                      8. Each party trading on Net1Ex P2P must have a payment
                      method with an account owner name that is identical to the
                      name appearing on their Net1Ex Account, as confirmed
                      during the identity verification process. It is prohibited
                      to use any payment method where the account owner name is
                      not identical to the name appearing on your Net1Ex
                      Account. Net1Ex reserves the right to suspend or terminate
                      your access to Net1Ex P2P if it is identified that you
                      used a payment method with an account owner name that is
                      not identical to the name appearing on your Net1Ex
                      Account.
                    </p>
                    <p>
                      9. You understand and agree that by placing an Order to an
                      Advertisement on Net1Ex P2P either as a Buyer or a Seller,
                      you authorize Net1Ex to display your username/ merchant
                      name and share your full name as confirmed in the identity
                      verification process of your Net1Ex Account with the
                      counterparty user that you are trading with.
                    </p>
                    <p>
                      10. If you are a Buyer, you are required to make the Fiat
                      Currency transfer in consideration of such Digital Assets
                      after placing an Order within the Payment Duration
                      indicated in the Advertisement. The Fiat Currency to be
                      transferred to the Seller will not be deducted by Net1Ex,
                      you are solely responsible for making the transfer
                      yourself outside of Net1Ex P2P, using one of the payment
                      methods indicated in the Advertisement. Once you complete
                      the Fiat Currency transfer, you are required to confirm
                      such transfer by clicking the “Transferred, notify seller”
                      button. You may lose access to all or part of Net1Ex P2P
                      in case you repetitively cancel Orders after placing them
                      or in case your Orders are repetitively getting canceled
                      automatically due to your failure to make Fiat Currency
                      transfer within the Payment Duration.
                    </p>
                    <p>
                      11.If you are a Seller, you are solely responsible for
                      verifying the Fiat Currency transfer made by the Buyer in
                      consideration of the Digital Assets and for providing
                      instruction to Net1Ex to release the Digital Assets for
                      which an Order was placed. To release such Digital Assets,
                      you should click “Confirm release” after you fully receive
                      the Fiat Currency payment from the Buyer. We recommend you
                      to confirm the release of Digital Assets within fifteen
                      (15) minutes, and in any case within a reasonable period
                      of time upon receiving the Fiat Currency payment from the
                      Buyer in full. By clicking “Confirm release” you are
                      confirming that you successfully received the Fiat
                      Currency transferred from the Buyer and you are
                      instructing Net1Ex to release Digital Assets held in
                      escrow to the Buyer for completion of the Order. This
                      action is final and cannot be reversed. To avoid financial
                      loss, do not confirm release of the Digital Assets under
                      any circumstances before you ensure that you receive from
                      the Buyer the corresponding Fiat Currency in full. All
                      payments are final upon completion unless otherwise
                      required by law. In case the account holder information of
                      the Buyer that you see on the payment method through which
                      the Fiat Currency transfer was made is inconsistent with
                      the user’s verified identity information appearing on
                      Net1Ex P2P, you should not release the Digital Assets and
                      make a refund of any Fiat Currency that you may have
                      received. You may lose access to all or part of Net1Ex P2P
                      in case you repetitively fail to confirm the release of
                      Digital Assets in a timely manner despite receipt of the
                      Fiat Currency transfer from the Buyer.
                    </p>
                    <p>
                      12. By trading on Net1Ex P2P, the Buyer agrees to bear any
                      Transfer Fees that may be charged by the respective
                      payment service provider for the payment method selected
                      by the parties for the Fiat Currency transfer to be made
                      in consideration of the Digital Assets, unless the Seller
                      has explicitly agreed to bear such additional fees or
                      costs. Therefore, the Fiat Currency to be transferred by
                      the Buyer as consideration of the traded Digital Assets
                      must be inclusive of any Transfer Fees to ensure that the
                      Seller receives the full Fiat Currency amount as appearing
                      in the Order (e.g. if the order amount is 10,000 USD, and
                      an additional 5 USD is charged by the payment service
                      provider of the selected payment method, 10,005 USD should
                      be paid by the Buyer, instead of 10,000 USD). In case such
                      Transfer Fees are charged by the respective payment
                      service provider to the Seller, the Seller should clearly
                      disclose them in the Advertisement (if the Seller is the
                      Advertiser) or provide the Buyer the proof of such
                      Transfer Fees in prior (if the Seller is the Taker).
                    </p>
                    <p>
                      13.Depending on your location and the value of the Digital
                      Assets that you would like to trade on Net1Ex P2P, you may
                      be able to do the Fiat Currency transfer in cash or you
                      may be able to submit an application to post
                      Advertisements where the Payment Method is set as cash
                      (“Cash Merchant”). You may need to agree to additional
                      terms and conditions to become a Cash Merchant. You
                      understand and agree that by choosing to make the Fiat
                      Currency transfer in cash may expose you to unpredictable
                      security risks, such as robbery and counterfeit bills. In
                      case the offender is a Net1Ex verified user, we will block
                      withdrawals and services for the Net1Ex Account of such
                      user, however, Net1Ex is not liable in any way whatsoever
                      (including but not limited to, liability in contract, tort
                      including negligence, misrepresentation, restitution or
                      otherwise) for any losses, damages, loss of profits, loss
                      of sales or business, loss of agreements or contracts,
                      loss of anticipated savings, loss of or damage to goodwill
                      and indirect or consequential loss that may result from
                      these risks. You must contact your local law enforcement
                      authorities to protect your legal rights and interests and
                      seek compensation from the offender.
                    </p>

                    <h4>D. T+1 / T+2 Withdrawal Waiting Period</h4>

                    <p>
                      14. You may be subject to a waiting period between 24 to
                      48 hours to withdraw the Digital Assets that you purchased
                      on Net1Ex P2P in case you have been trading with certain
                      Fiat Currencies, as may be determined by Net1Ex in its
                      sole discretion. The waiting period shall start upon the
                      release of the Digital Assets to you. Any other trading
                      activity you may perform with your Net1Ex Account will not
                      be affected during the withdrawal waiting period.
                    </p>
                    <h4>E. Appeal Procedure</h4>

                    <p>
                      15.As Net1Ex is not a party to the Digital Assets trade
                      between the Buyer and the Seller, Net1Ex has neither the
                      right nor the obligation to resolve any disputes between
                      the Buyer and the Seller and is not liable for any loss
                      you may incur in connection with a completed payment.
                    </p>
                    <p>
                      16.However, in case of a conflict during a trade on Net1Ex
                      P2P that the parties cannot solve by directly contacting
                      each other through the Chat Service made available by
                      Net1Ex, Net1Ex offers both parties the opportunity to file
                      an appeal. You understand and agree that the appeal
                      mechanism offered by Net1Ex is not a representation,
                      undertaking, or guarantee from Net1Ex in any way
                      whatsoever to resolve the disputes between the parties and
                      has the sole purpose of offering a mediation channel to
                      the users. Net1Ex reserves the right to suspend or
                      terminate access to Net1Ex P2P for users who are
                      uncooperative during the appeal process. You may lose
                      access to all or part of Net1Ex P2P in case an
                      Advertisement that you have posted has multiple ongoing
                      appeals. Net1Ex reserves the right to suspend or block
                      users who intentionally submit falsified proof within the
                      appeal process. If the appellee declines to cooperate or
                      fails to provide any evidence to counter the appeal, the
                      resolution will be in favor of the appellant.
                    </p>
                    <p>
                      17.You understand and agree that Net1Ex shall not be
                      liable for any asset losses arising from non-compliance
                      with instructions or failure to submit requested proofs
                      within the specified timeframe.
                    </p>
                    <p>
                      18.For secure P2P transactions and dispute resolution, use
                      the Net1Ex Exchange P2P platform exclusively. Net1Ex
                      doesn't facilitate and mediate private trades, and assumes
                      no liability for them.
                    </p>
                    <p>
                      19. Net1Ex hereby reserves the right of any final
                      interpretations and judgments for the sanctioning of
                      appeal cases. Reopening of appeals will be determined at
                      the sole discretion of Net1Ex on a case-by-case basis.
                    </p>
                    <h4>
                      F. Ratings, usernames and Communications between users
                    </h4>
                    <p>
                      20. After completing a Digital Asset trade on Net1Ex P2P,
                      you may be asked to rate and write feedback for the
                      counterparty to share your experience. The rating and
                      feedback mechanism intends to create a respectful, safe,
                      and transparent environment for all users. Your rating is
                      visible to any user trading on Net1Ex P2P and this may
                      affect whether or not they decide to trade with you. You
                      can see your current rating on Net1Ex P2P. You may lose
                      access to all or part of Net1Ex P2P if your rating is
                      below the acceptable level of rating to be determined by
                      Net1Ex in its sole discretion. You will be notified in
                      case your Account is subject to such restriction and we
                      may share information that may help you improve your
                      rating.
                    </p>

                    <p>
                      21. You shall not include the name “Net1Ex”, names of any
                      banks, payment service providers or any other wordings
                      that may be misleading or sensitive in your username.
                      Net1Ex hereby declares and enforces a strict prohibition
                      on the utilization of pseudonyms deemed inappropriate by
                      its standards, especially those of a political, racist,
                      pornographic, insulting, violent nature, or those
                      promoting drugs and/or weapons.
                    </p>
                    <p>
                      22. You shall not share any personal information, social
                      network details or other contact information in the terms
                      and conditions for an Advertisement, when using the Chat
                      Service or in any other way when trading with other users
                      on Net1Ex P2P. You acknowledge and consent to the
                      possibility of chats being subject to monitoring by
                      Net1Ex. In the event that it is determined that the chats
                      have been utilized for purposes other than those intended,
                      your accounts may be subject to suspension or blocking.
                      This measure is implemented to maintain platform
                      integrity, ensure compliance with terms of service, and
                      prevent misuse or abuse of the platform.
                    </p>
                    <p>
                      23. In using Net1Ex P2P, you may view content or utilize
                      services provided by third parties, including hyperlinks
                      to the third parties websites and services of such parties
                      (the “Third Party Content''). We do not endorse, adopt or
                      control any Third Party Content and will have no
                      responsibility or liability for such Third Party Content
                      whatsoever. Your dealings or correspondence with such
                      third parties are solely between you and the third
                      parties. We are not responsible or liable for any loss or
                      damage of any sort incurred as a result of any such
                      dealings and you understand that your usage of the Third
                      Party Content, and your interactions with third parties’,
                      are solely at your own risk.
                    </p>

                    <h4>G. Suspicious Trading Behaviour</h4>
                    <p>
                      24. You acknowledge that our decision to take certain
                      actions, including limiting, suspending, or terminating
                      your access to Net1Ex P2P may be based on confidential
                      criteria that are essential to our risk management and
                      security protocols, including but not limited to
                      circumstances in which we are unable to provide you with
                      information about the reasons for such limitation,
                      suspension or termination, whilst an investigation is
                      ongoing (by us and/or a third party such as the police).
                      You agree that Net1Ex is under no obligation to disclose
                      the details of its risk management and security procedures
                      to you.
                    </p>
                    <p>
                      25. We will restore your access to Net1Ex P2P as soon as
                      possible once the reasons for the suspension no longer
                      exist. However, we are under no obligation to notify you
                      if and when such suspension will be lifted.
                    </p>
                    <p>
                      26. You acknowledge that we reserve the right to disclose
                      your contact details to any Regulatory Authority if we
                      reasonably suspect that your Net1Ex Account is being used
                      for any illegal, fraudulent or unauthorized activity.
                      Net1Ex reserves the right to deactivate the Advertisement
                      if any risk and/or technical issue arises.
                    </p>

                    <h4>H. Risk Disclosure</h4>
                    <p>
                      27. Digital Assets prices can be volatile. These
                      fluctuations may affect the value of Digital Assets you
                      buy or sell on Net1Ex P2P, which may be lower or higher at
                      the time of your investment decisions and Net1Ex is not
                      liable for any losses you may incur.
                    </p>
                    <p>
                      28. You agree that, if any balances or other amounts are
                      incorrectly or erroneously transferred (as detected by
                      Net1Ex in our reasonable discretion) or alleged to have
                      been incorrectly or erroneously transferred, to your
                      account via a P2P transaction, and/or if there are any
                      fraudulent activities in connection with the relevant
                      transaction, we reserve the sole right to claw-back or
                      reverse any such balance, amount, or transaction (in each
                      case in our reasonable discretion and subject to any
                      mandatory provisions of any applicable laws).
                    </p>

                    <h4>I. Miscellaneous</h4>
                    <p>
                      29. All terms and references used in these P2P Terms and
                      which are defined and construed in the Net1Ex Terms of Use
                      (the “Terms of Use”), but are not defined or construed in
                      these P2P Terms, shall have the same meaning and
                      construction in these P2P Terms.
                    </p>
                    <p>
                      30. These P2P Terms are to be read together with the Terms
                      of Use. In the event of any conflict or inconsistency
                      between these P2P Terms and the Terms of Use, the order of
                      precedence in which the documents are to be read is first
                      the P2P Terms and then the Terms of Use.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
}

export default Home;
