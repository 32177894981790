import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Home_header.js";
import { Bars } from "react-loader-spinner";
import "semantic-ui-css/semantic.min.css";
import { toast } from "react-toastify";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import { Tooltip } from "react-tooltip";

function P2p() {
    useEffect(() => {
        getp2pstatus();
        getaccountdata();
    }, []);
    const [p2pstatus, setp2pstatus, p2pstatusref] = useState("");
    const [p2pAccount, setp2pAccount, p2pAccountref] = useState("");

    const getp2pstatus = async () => {
        try {
            setPageloader(true);

            const data = {
                apiUrl: apiService.getP2Pstatus,
            };

            const response = await getMethod(data);
            setPageloader(false);

            if (response.status) {
                setp2pstatus(response.p2pstatus);
            }
        } catch (err) {
            console.error("Error fetching currencies:", err);
        }
    };

    const getaccountdata = async () => {
        try {
            setPageloader(true);

            const data = {
                apiUrl: apiService.p2pAccountdata,
            };

            const response = await getMethod(data);
            setPageloader(false);

            if (response.status) {
                setp2pAccount(response.data);
                console.log(p2pAccountref.current, "iiiknkinkjk");
            }
        } catch (err) {
            console.error("Error fetching currencies:", err);
        }
    };

    const initialBankValue = {
        AccountHolderName: "",
        AccountNumber: "",
        IFSCCode: "",
        BankName: "",
        BranchName: "",
        BranchAddress: "",
        gpay_number: "",
        paytm_number: "",
    };
    const [BankValue, setBankValue] = useState(initialBankValue);
    const [
        AccountHolderNameErr,
        SetAccountHolderNameErr,
        AccountHolderNameErrref,
    ] = useState(false);
    const [AccountNumberErr, SetAccountNumberErr, AccountNumberErrref] =
        useState(false);
    const [IFSCCodeErr, SetIFSCCodeErr, IFSCCodeErrref] = useState(false);
    const [BankNameErr, SetBankNameErr, BankNameErrref] = useState(false);
    const [BranchNameErr, SetBranchNameErr, BranchNameErrref] = useState(false);
    const [validationnErr, setvalidationnErr] = useState("");
    const [BranchAddressErr, SetBranchAddressErr, BranchAddressErrref] =
        useState(false);
    const [Bankdetails, SetBankdetails] = useState([]);
    const [pageLoader, setPageloader] = useState(true);

    const {
        AccountHolderName,
        AccountNumber,
        IFSCCode,
        BankName,
        BranchName,
        BranchAddress,
        gpay_number,
        paytm_number,
    } = BankValue;

    const navigate = useNavigate();

    const handleChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...BankValue, ...{ [name]: value } };
        setBankValue(formData);
    };

    const defaultBank = async (editData) => {
        try {
            var data = {
                apiUrl: apiService.defaultBanks,
                payload: editData,
            };
            var resp = await postMethod(data);
            if (resp.status) {
                toast.success(resp.Message);
                getbankdetails();
            } else {
                toast.error(resp.Message);
            }
        } catch (error) { }
    };

    const validate = async (values) => {
        const errors = {};
        if (values.AccountHolderName == "") {
            errors.AccountHolderName = "Holder Name is require !";
            SetAccountHolderNameErr(true);
        } else if (
            values.AccountHolderName.length < 5 ||
            values.AccountHolderName.length > 25
        ) {
            errors.AccountHolderName =
                "Account Holder Name must hava an 5 to 25 characters!";
            SetAccountHolderNameErr(true);
        } else {
            SetAccountHolderNameErr(false);
        }
        if (values.AccountNumber == "") {
            errors.AccountNumber = "Account Number is require !";
            SetAccountNumberErr(true);
        } else if (
            values.AccountNumber.length < 5 ||
            values.AccountNumber.length > 25
        ) {
            errors.AccountNumber = "Account Number  must hava an 5 to 25 Digits!";
            SetAccountNumberErr(true);
        } else {
            SetAccountNumberErr(false);
        }
        if (values.IFSCCode == "") {
            errors.IFSCCode = "IFSC Code is require !";
            SetIFSCCodeErr(true);
        } else if (values.IFSCCode.length < 5 || values.IFSCCode.length > 25) {
            errors.IFSCCode = "IFSC Code must have an 5 to 25 characters!";
            SetIFSCCodeErr(true);
        } else {
            SetIFSCCodeErr(false);
        }
        if (values.BankName == "") {
            errors.BankName = "Bank Name is require !";
            SetBankNameErr(true);
        } else if (values.BankName.length < 5 || values.BankName.length > 25) {
            errors.BankName = "Bank Name must have an 5 to 25 characters!";
            SetBankNameErr(true);
        } else {
            SetBankNameErr(false);
        }
        if (values.BranchName == "") {
            errors.BranchName = "Branch Name is require !";
            SetBranchNameErr(true);
        } else if (values.BranchName.length < 5 || values.BranchName.length > 25) {
            errors.BranchName = "Branch Name must have an 5 to 25 !";
            SetBranchNameErr(true);
        } else {
            SetBranchNameErr(false);
        }
        if (values.BranchAddress == "") {
            errors.BranchAddress = "Branch Address is require !";
            SetBranchAddressErr(true);
        } else if (
            values.BranchAddress.length < 5 ||
            values.BranchAddress.length > 150
        ) {
            errors.BranchAddress = "Branch Address must have an 5 to 150 characters!";
            SetBranchAddressErr(true);
        } else {
            SetBranchAddressErr(false);
        }
        setvalidationnErr(errors);
        return errors;
    };

    useEffect(() => {
        setPageloader(true);

        setTimeout(() => {
            setPageloader(false);
        }, 1000);

        getbankdetails(1);
    }, [0]);

    const [currTabs, setcurrTabs] = useState("one");
    const [editpage, seteditpage] = useState(false);

    const [currentPagebankdetails, setcurrentPagebankdetails] = useState(1);
    const [totalbankdetails, settotalbankdetails] = useState([]);
    const recordPerPagebankdetails = 2;
    const pageRangebankdetails = 2;

    const handlePageChangebankdetails = (pagenumber) => {
        setcurrentPagebankdetails(pagenumber);
        getbankdetails(pagenumber);
    };
    const getbankdetails = async (page) => {
        setPageloader(true);

        try {
            var data = {
                apiUrl: apiService.Getbankdetails,
                payload: { perpage: 2, page: page },
            };
            var resp = await postMethod(data);
            setPageloader(false);

            if (resp.status == true) {
                SetBankdetails(resp.obj);
                settotalbankdetails(resp.total);
            }
        } catch (error) { }
    };

    const submitID = async () => {
        try {
            validate(BankValue);
            if (
                BankValue.AccountHolderName != "" &&
                BankValue.AccountNumber != "" &&
                BankValue.IFSCCode != "" &&
                BankValue.BankName != "" &&
                BankValue.BranchName != "" &&
                BankValue.BranchAddress != ""
            ) {
                var data = {
                    apiUrl: apiService.Bankdetails,
                    payload: BankValue,
                };

                var resp = await postMethod(data);
                console.log(resp, "=-=-resp=--=-=");
                BankValue.AccountHolderName = "";
                BankValue.AccountNumber = "";
                BankValue.IFSCCode = "";
                BankValue.BankName = "";
                BankValue.BranchName = "";
                BankValue.BranchAddress = "";
                BankValue.gpay_number = "";
                BankValue.paytm_number = "";
                if (resp.status) {
                    getbankdetails();
                    toast.success(resp.Message);
                    seteditpage(false);
                    cancel();
                    window.location.reload();
                } else {
                    window.location.reload();
                    toast.error(resp.Message);
                }
            } else {
                toast.error("Please fill all the require  fields");
                console.log("ALL FIELD NEED");
            }
        } catch (error) { }
    };

    const editbankData = async (value) => {
        seteditpage(true);
        BankValue.AccountHolderName = value.AccountHolderName;
        BankValue.AccountNumber = value.AccountNumber;
        BankValue.BankName = value.BankName;
        BankValue.BranchAddress = value.BranchAddress;
        BankValue.BranchName = value.BranchName;
        BankValue.IFSCCode = value.IFSCCode;
        BankValue.gpay_number = value.gpay_number;
        BankValue.paytm_number = value.paytm_number;
        BankValue._id = value._id;
    };

    const deletebank = async (deleteData) => {
        console.log(deleteData, "deleteData");
        if (
            // eslint-disable-next-line no-restricted-globals
            confirm(
                "Are you sure you want to permanently delete this bank details ?"
            ) == true
        ) {
            try {
                var data = {
                    apiUrl: apiService.deletbank,
                    payload: deleteData,
                };
                var resp = await postMethod(data);
                if (resp.status == true) {
                    getbankdetails();
                    toast.success(resp.Message);
                    seteditpage(false);
                } else {
                    toast.error(resp.Message);
                }
            } catch (error) { }
        }
    };
    const update = async (e) => {
        try {
            if (
                BankValue.AccountHolderName != "" &&
                BankValue.AccountNumber != "" &&
                BankValue.IFSCCode != "" &&
                BankValue.BankName != "" &&
                BankValue.BranchName != "" &&
                BankValue.BranchAddress != ""
            ) {
                var data = {
                    apiUrl: apiService.updateBankdetails,
                    payload: BankValue,
                };
                seteditpage(false);
                var resp = await postMethod(data);
                BankValue.AccountHolderName = "";
                BankValue.AccountNumber = "";
                BankValue.IFSCCode = "";
                BankValue.BankName = "";
                BankValue.BranchName = "";
                BankValue.BranchAddress = "";
                BankValue.gpay_number = "";
                BankValue.paytm_number = "";
                console.log(resp, "=-=-resp=--=-=");
                if (resp.status == true) {
                    getbankdetails();
                    toast.success(resp.Message);
                    window.location.reload();
                } else {
                    toast.error(resp.Message);
                }
            } else {
                toast.error("Please fill all the require  fields");
                console.log("ALL FIELD NEED");
            }
        } catch (error) { }
    };

    const cancel = async (e) => {
        try {
            seteditpage(false);
            BankValue.AccountHolderName = "";
            BankValue.AccountNumber = "";
            BankValue.IFSCCode = "";
            BankValue.BankName = "";
            BankValue.BranchName = "";
            BankValue.BranchAddress = "";
            BankValue.gpay_number = "";
            BankValue.paytm_number = "";
        } catch (error) { }
    };

    const [siteLoader, setSiteLoader] = useState(false);

    return (
        <>
            <main className="nextex_p2p_main">
                <section>
                    <Header />
                </section>

                {pageLoader == true ? (
                    <div className="dashboard_content_section loadercss">
                        <Bars
                            height="40"
                            width="40"
                            color="#1062fe"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                ) : (
                    <section className="nextex_p2p_section">
                        <div className="container">
                            <div className="nextex_p2p_section_content">
                                <ul
                                    class="nav nav-tabs p2p_order_tabs"
                                    id="myTab"
                                    role="tablist"
                                >
                                    <div className="">
                                        <li class="nav-item" role="presentation">
                                            <Link to="/p2p">
                                                <button class="nav-link" id="home-tab">
                                                    <i class="ri-p2p-line"></i> P2P Offers
                                                </button>
                                            </Link>
                                        </li>
                                    </div>
                                    <div className="p2p_order_tab2">
                                        <li class="nav-item" role="presentation">
                                            <Link to="/p2porder">
                                                <button class="nav-link " id="profile-tab">
                                                    <i class="ri-file-list-2-line"></i> Orders
                                                </button>
                                            </Link>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <Link to="/p2poffers">
                                                <button class="nav-link" id="contact-tab">
                                                    <i class="ri-file-text-line"></i> My Offers
                                                </button>
                                            </Link>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <Link to="/p2paccount">
                                                <button class="nav-link active" id="account-tab">
                                                    <i class="ri-account-box-line"></i> My P2P Account
                                                </button>
                                            </Link>
                                        </li>

                                        {p2pstatusref.current == "Deactive" ? (
                                            ""
                                        ) : (
                                            <Link to="/postad">
                                                {" "}
                                                <li>
                                                    <button
                                                        className="create_offer_btn"
                                                    // type="button"
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#createModal"
                                                    >
                                                        {" "}
                                                        <i class="ri-add-large-line"></i> Create Offer
                                                    </button>
                                                </li>
                                            </Link>
                                        )}
                                    </div>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div
                                        class="tab-pane fade show active"
                                        id="account-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="disabled-tab"
                                        tabindex="0"
                                    >
                                        <div className="trades_prfile_card">
                                            {/* <div className="p2p_acc_profile_content">
                                                <div className="acc_profile_icon">
                                                    <img
                                                        src={require("../images/landing_new/profile_icon.png")}
                                                    />
                                                </div>
                                            </div> */}
                                            {/* <div className="p2p_profile_email_content">
                                                <p>
                                                    Email <i class="ri-checkbox-circle-fill"></i>
                                                </p>
                                                <p>
                                                    Phone <i class="ri-close-circle-fill"></i>
                                                </p>
                                                <p>
                                                    Identity Verification{" "}
                                                    <i class="ri-close-circle-fill"></i>
                                                </p>
                                            </div> */}
                                            <div className="row mt-4 ">
                                                <div className="col-lg-3">
                                                    <div className="completed_card">
                                                        <h4>
                                                            Completed trades{" "}
                                                            <button
                                                                data-tooltip-id="my-tooltip"
                                                                data-tooltip-content="Total number of completed P2P orders (buy and sell included)"
                                                                className="tooltip-button"
                                                                place="bottom"
                                                            >
                                                                <i class="ri-information-2-line"></i>
                                                                <Tooltip id="my-tooltip" />
                                                            </button>
                                                        </h4>

                                                        <p>
                                                            <span className="span_buy">
                                                                {p2pAccountref.current
                                                                    ? p2pAccountref.current.buycount
                                                                    : 0}{" "}
                                                                Buy
                                                            </span>
                                                            /{" "}
                                                            <span className="span_sell">
                                                                {p2pAccountref.current
                                                                    ? p2pAccountref.current.sellcount
                                                                    : 0}{" "}
                                                                Sell
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="completed_card">
                                                        <h4>
                                                            30d trades{" "}
                                                            <button
                                                                data-tooltip-id="my-tooltip"
                                                                data-tooltip-content="Total number of completed P2P orders (buy and sell included) in the past 30 days"
                                                                className="tooltip-button"
                                                            >
                                                                <i class="ri-information-2-line"></i>
                                                                <Tooltip id="my-tooltip" />
                                                            </button>
                                                        </h4>
                                                        <p>
                                                            {p2pAccountref.current
                                                                ? p2pAccountref.current.monthlycount
                                                                : 0}{" "}
                                                            Time(s)
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="completed_card">
                                                        <h4>
                                                            30d completion rate
                                                            <button
                                                                data-tooltip-id="my-tooltip"
                                                                data-tooltip-content="Completion Rate = Completed Orders / Orders(30d)*100%"
                                                                className="tooltip-button"
                                                            >
                                                                <i class="ri-information-2-line"></i>
                                                                <Tooltip id="my-tooltip" />
                                                            </button>
                                                        </h4>
                                                        <p>
                                                            {" "}
                                                            {p2pAccountref.current
                                                                ? p2pAccountref.current.ratio.toFixed(2)
                                                                : 0}{" "}
                                                            %{" "}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="completed_card">
                                                        <h4>
                                                            Registered
                                                            <button
                                                                data-tooltip-id="my-tooltip"
                                                                data-tooltip-content="Registered on Netex"
                                                                className="tooltip-button"
                                                            >
                                                                <i class="ri-information-2-line"></i>
                                                                <Tooltip id="my-tooltip" />
                                                            </button>
                                                        </h4>
                                                        <p>
                                                            {p2pAccountref.current
                                                                ? p2pAccountref.current.daysSinceRegistration
                                                                : 0}{" "}
                                                            Day(s) ago
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p2p_payment_methods_content">
                                            <h2 className="p2p_payment_title">P2P Payment methods</h2>
                                        </div>
                                        <div className="add_payment_methods_content">
                                            <h2>P2P Payment Methods</h2>
                                            <button
                                                type="button"
                                                class=""
                                                data-bs-toggle="modal"
                                                data-bs-target="#paymentModal"
                                            >
                                                <i class="ri-add-large-line"></i> Add Paymemnt Method
                                            </button>
                                        </div>
                                        <div className="add_payment_method_text">
                                            Add preferred payment method to receive fiat payments when
                                            selling crypto (up to 20 payment methods).
                                        </div>
                                        <div className="table-responsive table-cont padding_table">
                                            <table className="table pt-4">
                                                <thead>
                                                    <tr className="stake-head">
                                                        <th className="table_center_text">Preferred</th>

                                                        <th className="table_center_text">Account name</th>
                                                        <th className="table_center_text">
                                                            Account number
                                                        </th>
                                                        <th className="table_center_text">Bank name</th>
                                                        <th className="table_center_text">Branch name</th>

                                                        <th className="table_center_text">Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {Bankdetails && Bankdetails.length > 0 ? (
                                                        Bankdetails.map((item, i) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        <input
                                                                            className=""
                                                                            type="radio"
                                                                            name="flexRadioDefault"
                                                                            id="flexRadioDefault2"
                                                                            value={item}
                                                                            checked={item.Status == true}
                                                                            onClick={() => {
                                                                                defaultBank(item);
                                                                            }}
                                                                        />{" "}
                                                                    </td>

                                                                    <td>{item.AccountHolderName}</td>

                                                                    <td>{item.AccountNumber}</td>

                                                                    <td>{item.BankName}</td>

                                                                    <td>{item.BranchAddress}</td>

                                                                    <td>
                                                                        <div className="d-flex gap-3">
                                                                            <i
                                                                                class="fa-solid fa-pen-to-square"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#paymentModal"
                                                                                onClick={() => editbankData(item)}
                                                                            ></i>
                                                                            <i
                                                                                class="fa-regular fa-trash-can"
                                                                                onClick={() => {
                                                                                    deletebank(item);
                                                                                }}
                                                                            ></i>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colspan="8">
                                                                {" "}
                                                                <div className="no data">
                                                                    <img
                                                                        src={require("../images/landing_new/no_data_asset.png")}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                <div
                    class="modal fade"
                    id="paymentModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-centered modal_max_width">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title-deposit" id="exampleModalLabel">
                                    Add payment method
                                </h1>
                                <button
                                    type="button"
                                    class="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={cancel}
                                ></button>
                            </div>
                            <div class="modal-body padding_modal">
                                <div className="acc_holder_detail">
                                    <h3>Account holder name</h3>
                                    <input
                                        type="text"
                                        maxLength={30}
                                        placeholder="Account holder name"
                                        name="AccountHolderName"
                                        value={AccountHolderName}
                                        onChange={handleChange}
                                    />
                                    {AccountHolderNameErrref.current == true ? (
                                        <p className="text-danger">
                                            {" "}
                                            {validationnErr.AccountHolderName}{" "}
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="acc_holder_detail mt-4">
                                    <h3>Account number</h3>
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Enter account number"
                                        name="AccountNumber"
                                        value={AccountNumber}
                                        onChange={handleChange}
                                    />
                                    {AccountNumberErrref.current == true ? (
                                        <p className="text-danger">
                                            {" "}
                                            {validationnErr.AccountNumber}{" "}
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="acc_holder_detail mt-4">
                                    <h3>IFSC code</h3>
                                    <input
                                        type="text"
                                        maxLength={30}
                                        class="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="IFSC code"
                                        name="IFSCCode"
                                        value={IFSCCode}
                                        onChange={handleChange}
                                    />
                                    {IFSCCodeErrref.current == true ? (
                                        <p className="text-danger"> {validationnErr.IFSCCode} </p>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="acc_holder_detail mt-4">
                                    <h3>Bank Name</h3>
                                    <input
                                        type="text"
                                        maxLength={40}
                                        class="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Bank name"
                                        name="BankName"
                                        value={BankName}
                                        onChange={handleChange}
                                    />

                                    {BankNameErrref.current == true ? (
                                        <p className="text-danger"> {validationnErr.BankName} </p>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="acc_holder_detail mt-4">
                                    <h3>Acount Opening Branch </h3>
                                    <input
                                        type="text"
                                        maxLength={30}
                                        class="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Account opening branch"
                                        name="BranchName"
                                        value={BranchName}
                                        onChange={handleChange}
                                    />
                                    {BranchNameErrref.current == true ? (
                                        <p className="text-danger"> {validationnErr.BranchName} </p>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="acc_holder_detail mt-4">
                                    <h3> Branch Address </h3>
                                    <input
                                        type="text"
                                        class="form-control"
                                        maxLength={50}
                                        id="exampleInputPassword1"
                                        placeholder="Branch Address"
                                        name="BranchAddress"
                                        value={BranchAddress}
                                        onChange={handleChange}
                                    />
                                    {BranchAddressErrref.current == true ? (
                                        <p className="text-danger">
                                            {" "}
                                            {validationnErr.BranchAddress}{" "}
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="acc_holder_detail mt-4">
                                    <h3>UPI ID (Optional) </h3>
                                    <input
                                        type="text"
                                        maxLength={30}
                                        class="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="UPI ID"
                                        name="gpay_number"
                                        value={gpay_number}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="acc_holder_detail mt-4">
                                    <h3>Paytm ID (Optional) </h3>
                                    <input
                                        type="text"
                                        maxLength={30}
                                        class="form-control"
                                        id="exampleInputPassword1"
                                        placeholder="Paytm"
                                        name="paytm_number"
                                        value={paytm_number}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="go_profile_btns pt-4">
                                    <button
                                        className="btn_cancel"
                                        type="button"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={cancel}
                                    >
                                        Cancel
                                    </button>
                                    {editpage ? (
                                        <button className="btn_proflie" onClick={update}>
                                            Update{" "}
                                        </button>
                                    ) : (
                                        <button className="btn_proflie" onClick={submitID}>
                                            Create
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
export default P2p;
