import React, { useEffect } from "react";
import Home_header from "./Home_header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

function Home() {
  return (
    <main className="dashboard_page">
      <section>
        <Home_header />
      </section>

      <section className="dashboard_content_section">
        <div className="container">
          <div className="smart_dashboard_content">
            <h2>
              Net1Ex –<span>Privacy Policy</span>{" "}
            </h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_board_profile_content">
                  <div className="contentpage">
                    <p>
                      Net1Ex ("Net1Ex", "We", "Us", "Platform", "Exchange") is
                      committed to protecting the privacy of our customers, and
                      we take our data protection responsibilities with absolute
                      seriousness.
                    </p>

                    <p>
                      This Privacy Policy describes how Net1Ex collects and
                      processes your personal information through the Net1Ex
                      websites and applications. Net1Ex refers to an ecosystem
                      which comprises Net1Ex official websites
                      <a href="https://net1ex.com"> https://net1ex.com</a>,
                      mobile applications, clients, applets and other
                      applications that are developed to offer Net1Ex services,
                      and includes independently-operated platforms, websites
                      and clients within the ecosystem. “Net1Ex Operators” refer
                      to all parties that run Net1Ex, including but not limited
                      to legal persons, unincorporated organizations and teams
                      that provide Net1Ex Services and are responsible for such
                      services.
                    </p>

                    <p>
                      This privacy policy outlines how we collect, use, store,
                      and disclose your personal information. By using our
                      platform, you agree to this policy.
                    </p>

                    <h5>Collection of Information</h5>
                    <p>
                      We collect personal information from you when you register
                      on our platform, verify your identity, and use our
                      services. The information we collect may include your
                      name, email address, phone number, date of birth,
                      government-issued identification, and other necessary
                      information to comply with legal and regulatory
                      requirements. We also collect non-personal information,
                      such as your IP address, browser type, and device type, to
                      improve our platform's performance and user experience.
                    </p>

                    <h5>Use of Information</h5>
                    <p>
                      We use your personal information to provide and improve
                      our platform and services, verify your identity, prevent
                      fraud and money laundering, and comply with legal and
                      regulatory requirements. We may use your information to
                      send you marketing communications and promotional offers
                      related to our services. You can opt-out of receiving such
                      communications at any time.
                    </p>

                    <h5>Storage of Information</h5>
                    <p>
                      We store your personal information on our secure servers
                      and take appropriate measures to protect it from
                      unauthorized access, disclosure, and use. We retain your
                      information for as long as necessary to fulfill the
                      purposes outlined in this privacy policy, unless a longer
                      retention period is required by law.
                    </p>

                    <h5>Disclosure of Information</h5>
                    <p>
                      We may share your personal information with third-party
                      service providers who assist us in providing our services,
                      such as payment processors and identity verification
                      providers. We may also share your information with
                      government and regulatory authorities as required by law.
                      We do not sell or rent your personal information to third
                      parties.
                    </p>

                    <h5>
                      1. What personal information do we collect and process?
                    </h5>
                    <p>
                      a. E-mail ID
                      <br />
                      b. Name
                      <br />
                      c. Gender
                      <br />
                      d. Date of birth
                      <br />
                      e. Residence address
                      <br />
                      f. Phone number
                      <br />
                      g. Nationality
                      <br />
                      h. Device ID
                      <br />
                      i. Transactional information
                    </p>

                    <h5>2. Why do we process your personal information?</h5>
                    <p>
                      Regulatory Compliance: Due to government regulations, we
                      are required to collect and verify our users' personal
                      information. KYC (Know Your Customer) and AML (Anti-Money
                      Laundering) requires verification of personal information
                      of users and to monitor their transactions.
                    </p>

                    <p>
                      Security: To enhance the security of our platform and
                      prevent any fraud, it is necessary for us to process our
                      users' personal information. By collecting and analyzing
                      user data, we can detect and block suspicious activities
                      and transactions.
                    </p>

                    <p>
                      User Account Management: To provide a seamless user
                      experience, we process your personal information to manage
                      user accounts, such as verifying login credentials,
                      updating users’ profiles, and resetting passwords.
                    </p>

                    <p>
                      Customer support: To provide effective customer support,
                      we process users’ personal information to respond to
                      users' inquiries and resolve issues. This includes
                      accessing users' account information to identify and
                      troubleshoot problems.
                    </p>

                    <p>
                      Communicate: We use your personal information to
                      communicate with you regarding the service our platform
                      provides.
                    </p>

                    <p>
                      The processing of personal information is necessary due to
                      the reasons mentioned above. However, we always ensure
                      that we protect our users' privacy and comply with data
                      protection laws.
                    </p>

                    <h5>
                      3. What are the Legal bases for our use of personal
                      information?
                    </h5>
                    <p>
                      a. Consent: We use personal information only if the user
                      has given their explicit and informed consent to us. This
                      means that users must have willingly provided their
                      personal information and agreed to the terms of our
                      service. The sole reason our platform asks for users'
                      personal information is to communicate with the user. When
                      you consent to processing your personal information for a
                      specified purpose, you may withdraw your consent at any
                      time and we will stop processing your personal information
                      for that purpose. The withdrawal of consent does not
                      affect the lawfulness of processing based on consent
                      before its withdrawal.
                    </p>

                    <p>
                      b. Contractual necessity: We will ask and process users'
                      information where it is necessary to fulfill the
                      contractual obligations we promised to provide the user,
                      such as providing access to the platform or executing
                      trades.
                    </p>

                    <p>
                      c. Legitimate interests: The exchange can use personal
                      information if it is necessary for its legitimate
                      interests, provided that those interests do not override
                      the user's fundamental rights and freedoms. For example,
                      the exchange may use personal information to prevent
                      fraud, ensure the security of the platform, or comply with
                      legal obligations.
                    </p>

                    <p>
                      d. Legal obligations: The exchange can use personal
                      information if it is required by law to collect and
                      process certain personal information, such as anti-money
                      laundering (AML) and know-your-customer (KYC) information,
                      in order to comply with regulatory requirements.
                    </p>

                    <p>
                      e. Public interest: The exchange may use personal
                      information for reasons of public interest, such as in the
                      prevention of crime or terrorism financing.
                    </p>

                    <p>
                      f. Vital interests: The exchange may use personal
                      information if it is necessary to protect the vital
                      interests of the user or another person. This may include
                      situations where the exchange needs to share personal
                      information with emergency services or law enforcement
                      agencies.
                    </p>

                    <h5>
                      4. What is the minimum age required to use Net1Ex
                      Exchange?
                    </h5>
                    <p>
                      Anyone above the age of 18 years can use our platform.
                    </p>

                    <h5>5. What About Cookies and Other Identifiers?</h5>
                    <p>
                      We use cookies and similar tools to enhance your user
                      experience, provide our services, enhance our marketing
                      efforts and understand how customers use our services so
                      we can make improvements. Depending on applicable laws in
                      the region you are located in, the cookie banner on your
                      browser will tell you how to accept or refuse cookies.
                    </p>

                    <h5>6. Does Net1Ex share its user's information?</h5>
                    <p>
                      We may share your Personal Data with third parties if we
                      believe that sharing your Personal Data is in accordance
                      with, or required by, any contractual relationship with
                      you or us, applicable law, regulation or legal process.
                    </p>

                    <p>
                      We may also share personal information with the following
                      persons:
                    </p>

                    <p>
                      • Regulatory bodies: We are often required by law to share
                      certain user information with regulatory bodies. This
                      information can include user identities, transaction
                      history, and other data related to anti-money laundering
                      (AML) and know-your-customer (KYC) compliance.
                    </p>

                    <p>
                      • Law enforcement agencies: We may be required by law to
                      share user information with law enforcement agencies. This
                      is typically done in response to a subpoena or court
                      order. We will only disclose information about you to
                      legal authorities to the extent we are obliged to do so
                      according to the law. We may also need to share your
                      information in order to enforce or apply our legal rights
                      or to prevent fraud.
                    </p>

                    <p>
                      • Third-party service providers: We may also share user
                      information with third-party service providers who help us
                      with various aspects involved in business, such as payment
                      processing, customer support, or marketing. These
                      third-party providers sign agreements that protect our
                      users' privacy and prevent them from using the data for
                      any other purposes.
                    </p>

                    <p>
                      • Acquirers or investors: In the event of a merger,
                      acquisition, or investment, we may share user information
                      with potential acquirers or investors to facilitate due
                      diligence. In such transitions, user information generally
                      is one of the transferred business assets but remains
                      subject to the promises made in any pre-existing Privacy
                      policy (unless, the user consents to otherwise). Also, in
                      the unlikely event that Net1Ex or substantially all of its
                      assets are acquired by a third party, user information
                      will be one of the transferred assets.
                    </p>

                    <p>
                      • Public disclosure: In some cases, we may be required to
                      disclose user information publicly, such as in the case of
                      a security breach or data leak. But this is highly
                      unlikely to happen; still, we will disclose information
                      only to the extent we are obliged to.
                    </p>

                    <h5>
                      7. What is international transfer of personal data? How or
                      why Net1Ex transfers user’s personal data internationally?
                    </h5>
                    <p>
                      International transfer of information is a common practice
                      for international crypto exchanges, which operate across
                      multiple jurisdictions. However, such transfers may be
                      subject to various data protection laws and regulations,
                      such as the GDPR in the European Union or the CCPA in
                      California, USA. Therefore, international crypto exchanges
                      need to ensure that they comply with these regulations and
                      implement appropriate safeguards, such as data encryption,
                      to protect user information during transit. Additionally,
                      international crypto exchanges may need to obtain user
                      consent or provide adequate notice regarding the transfer
                      of their information to other countries. Finally, it is
                      important for international crypto exchanges to
                      periodically review their data protection practices and
                      stay up-to-date with changes in relevant laws and
                      regulations.
                    </p>

                    <p>
                      We may transfer your personal data within our Affiliates,
                      third-party partners, and service providers based
                      throughout the world. In cases where we intend to transfer
                      personal data to third countries or international
                      organizations outside of the EEA. Net1Ex puts in place
                      suitable technical, organizational and contractual
                      safeguards (including Standard Contractual Clauses), to
                      ensure that such transfer is carried out in compliance
                      with applicable data protection rules, except where the
                      country to which the personal information is transferred
                      has already been determined by the European Commission to
                      provide an adequate level of protection.
                    </p>

                    <h5>8. How secure is your information with us?</h5>
                    <p>
                      We employ various security measures to protect our user's
                      information from unauthorized access, theft, and misuse.
                      Security measures implemented by our exchange include:
                    </p>

                    <p>
                      a. Encryption: We encrypt user information using advanced
                      cryptographic algorithms to prevent unauthorized access.
                    </p>

                    <p>
                      b. Two-factor authentication: On our platform, users are
                      required to enable two-factor authentication (2FA) to
                      access their accounts, adding an extra layer of security.
                    </p>

                    <p>
                      c. Cold storage: We store user funds and private keys in
                      "cold storage" wallets, which are offline and less
                      vulnerable to hacking attacks.
                    </p>

                    <p>
                      d. Anti-phishing measures: We implement various measures
                      to detect and prevent phishing attacks, such as email
                      verification and anti-phishing warnings.
                    </p>

                    <p>
                      e. Compliance with data protection laws: We always comply
                      with relevant data protection laws and regulations, such
                      as the GDPR in the European Union and others, to protect
                      user information.
                    </p>

                    <p>
                      However, users should also take their own precautions to
                      protect their personal information, such as using strong
                      passwords, enabling 2FA, and being cautious of suspicious
                      emails or links.
                    </p>

                    <h5>9. Advertising and Marketing</h5>
                    <p>
                      In order for us to provide you with the best user
                      experience, we may share your personal information with
                      our marketing partners for the purposes of targeting,
                      modeling, and/or analytics as well as marketing and
                      advertising. You have a right to object at any time to the
                      processing of your personal information for direct
                      marketing purposes.
                    </p>

                    <h5>10. What are the rights of the users?</h5>
                    <p>
                      You have a number of rights in relation to your personal
                      information and your privacy. These rights include:
                    </p>

                    <p>
                      a. Right to access: Users have the right to request access
                      to their personal information held by us, as well as
                      information on how their data is being processed and
                      shared.
                    </p>

                    <p>
                      b. Right to rectification: Users have the right to request
                      the correction or updating of their personal information
                      if it is inaccurate or incomplete.
                    </p>

                    <p>
                      c. Right to erasure: Users have the right to request the
                      deletion or removal of their personal information from our
                      system in certain circumstances, such as when the data is
                      no longer necessary for the purposes for which it was
                      collected.
                    </p>

                    <p>
                      d. Right to restrict processing: Users have the right to
                      request the platform to limit or restrict the processing
                      of their personal information in certain circumstances,
                      such as when the accuracy of the data is being contested.
                    </p>

                    <p>
                      e. Right to data portability: Users have the right to
                      request a copy of their personal information held by us in
                      a structured, commonly used, and machine-readable format.
                    </p>

                    <p>
                      f. Right to object: Users have the right to object to the
                      processing of their personal information in certain
                      circumstances, such as when the processing is based on the
                      platform's legitimate interests.
                    </p>

                    <p>
                      g. Right to lodge a complaint: Users have the right to
                      lodge a complaint with the relevant data protection
                      authority if they believe their personal information has
                      been mishandled by our exchange.
                    </p>

                    <h5>
                      11. How Long Does Net1Ex Keep Users Personal Information?
                    </h5>
                    <p>
                      We keep your personal information to enable your continued
                      use of Net1Ex services, for as long as it is required in
                      order to fulfill the relevant purposes described in this
                      Privacy policy, and as may be required by law such as for
                      tax and accounting purposes, compliance with Anti-Money
                      Laundering laws, or as otherwise communicated to you.
                    </p>

                    <h5>12. Contact Information</h5>
                    <p>
                      Our data protection officer can be contacted at{" "}
                      <a href="https://net1ex.com">support@net1ex.com</a>, and
                      will work to address any questions or issues that you have
                      with respect to the collection and processing of your
                      personal information.
                    </p>

                    <h5>13. Notices and Revisions</h5>
                    <p>
                      If you have any concerns about privacy at Net1Ex, please
                      contact us, and we will try to resolve it. You also have
                      the right to contact your local Data Protection Authority.
                    </p>

                    <p>
                      Our business changes regularly and our Privacy Notice may
                      change also. You should check our websites frequently to
                      see recent changes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
}

export default Home;
