import React, { useState, useEffect } from "react";
import Home_header from "./Home_header";
import { Bars } from "react-loader-spinner";
import { Dropdown } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";

const Convert = () => {
  const [siteLoader, setSiteLoader] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("ETH");
  const [allCurrency, setAllCurrency] = useState([]);


  const selectToken = (e, { value }) => {
    setSelectedCurrency(value);
  };

  const currArrayCrypto = [
    {
      value: "ETH",
      key: "ETH",
      text: "ETH",
      image: {
        avatar: true,
        src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664015323/fswpx9cb8ygezbx25edq.png",
      },
    },
    {
      value: "USDC",
      key: "USDC",
      text: "USDC",
      image: {
        avatar: true,
        src: "https://res.cloudinary.com/taikonz-com/image/upload/v1664014174/t4ayejcmp5be42sm1o7k.png",
      },
    },
  ];

  useEffect(() => {
    setAllCurrency(currArrayCrypto);
  }, []);
  return (
    <main className="convert_main">
      <div>
        <Home_header />
      </div>

      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <section className="convert_section">
            <div className="row justify-content-center">
              <div className="convert_title">
                <h2>Convert</h2>
                <Link to="/history">
                  <button className="order_button_out">History</button>
                </Link>
              </div>

              <div className="col-lg-5">
                <div className="Convert_convertPage__wrapper">
                  <div className="convert_bln_box">
                    <div className="from_content">
                      <p>From</p>
                      <p>
                        <span>Available Balance </span>0 ETH
                      </p>
                    </div>
                    <div className="from_content ">
                      <input type="number" placeholder="0" />
                      <div className="convert_bln_dropdown">
                        <Dropdown
                          inline
                          options={allCurrency}
                          value={selectedCurrency}
                          onChange={selectToken}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="from_content ">
                    <p>Spend</p>

                    <div className="spend_buttons">
                      <button>25%</button>
                      <button>50%</button>
                      <button>75%</button>
                      <button>100%</button>
                    </div>
                  </div>
                  <div className="convert_img">
                    <img
                      src={require("../images/landing_new/convert_icon.png")}
                    />
                  </div>
                  <div className="convert_bln_box">
                    <div className="from_content">
                      <p>To</p>
                      <p>
                        <span>Total Balance </span>0 ETH
                      </p>
                    </div>
                    <div className="from_content ">
                      <input type="number" placeholder="0" />
                      <div className="convert_bln_dropdown">
                        <Dropdown
                          inline
                          options={allCurrency}
                          value={selectedCurrency}
                          onChange={selectToken}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="rate_fee_content">
                    <div className="rate_content">
                      <p>
                        Rate:<i class="ri-information-2-line"></i>
                      </p>
                      <p>1 ETH ≈ 2,492.09 USDT</p>
                    </div>
                    <div className="rate_content rate_content2">
                      <p>Fee:</p>
                      <p>0.15%</p>
                    </div>
                  </div>
                  <div className="convert_usdt_button">
                    <button>Convert To USDT</button>
                    <p>
                      This functionality simplifies buying and selling on the
                      <br />
                      exchange and is based on market order.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </main>
  );
};

export default Convert;
