import React, { useEffect } from "react";
import useState from "react-usestateref";
import Home_header from "./Home_header";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import moment from "moment";
import Sidebar from "./Sidebar";
import { Dropdown } from "semantic-ui-react";

const Support = () => {
  const value = {
    Subject: "",
    Category: "Choose Category",
    text: "",
  };

  const [siteLoader, setSiteLoader] = useState(false);
  const [Formdata, setFormdata] = useState(value);
  const [SubjectErr, setSubjectErr, SubjectErrref] = useState(false);
  const [CategoryErr, setCategoryErr, CategoryErrref] = useState(false);
  const [textErr, settextErr, textErrref] = useState(false);
  const [formErr, setformErr] = useState("");
  const [user, setuser] = useState([]);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const [supportCategories, setCategories] = useState([]);
  const [viewChat, setViewChat] = useState(false);
  const [replyMess, replyMessage, replyMessref] = useState("");
  const [chatDatas, setChatDatas] = useState("");
  const [chatHistory, setchatHistory] = useState([]);

  const preferPayment = [
    { key: "Withdraw", text: "Withdraw", value: "Withdraw" },
    { key: "Deposit", text: "Deposit", value: "Deposit" },
    { key: "P2P", text: "P2P", value: "P2P" },
    { key: "KYC", text: "KYC", value: "KYC" },
    { key: "Spot", text: "Spot", value: "Spot" },
  ];

  const { Subject, Category, text } = Formdata;

  const getItem = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let sanitizedValue = value;
    if (sanitizedValue.startsWith(" ")) {
      sanitizedValue = sanitizedValue.trimLeft();
    }
    let formData = { ...Formdata, ...{ [name]: sanitizedValue } };
    setFormdata(formData);
    validate(formData);
  };

  const withCash = (event) => {
    const { value } = event.target;
    setCategoryErr(false);
    var obj = {
      Subject: Formdata.Subject,
      Category: value,
      text: Formdata.text,
    };
    setFormdata(obj);
  };

  const recordPerPage = 5;
  const totalRecords = 15;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    viewData(pageNumber);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    viewData();
    getCategory();
  }, []);

  const checkKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      reply();
    }
  };

  const validate = async (condition) => {
    var error = {};
    if (condition.Subject === "") {
      error.Subject = "Subject is a required field";
      setSubjectErr(true);
    } else if (!/^[a-zA-Z]/.test(condition.Subject)) {
      error.Subject = "Subject must start with a letter !";
      setSubjectErr(true);
    } else if (condition.Subject.length < 4 || condition.Subject.length > 25) {
      error.Subject = "Subject must be between 4 to 25 characters !";
      setSubjectErr(true);
    } else if (!/^[a-zA-Z0-9_]+$/.test(condition.Subject)) {
      error.Subject =
        "Subject can only contain letters, numbers, and underscores !";
      setSubjectErr(true);
    } else if (condition.Category === "Choose Category") {
      setSubjectErr(false);
      error.Category = "Category is Required";
      setCategoryErr(true);
    } else if (condition.text === "") {
      setCategoryErr(false);
      error.text = "Message is a required field";
      settextErr(true);
    } else if (!/^[a-zA-Z]/.test(condition.text)) {
      error.text = "Message must start with a letter !";
      settextErr(true);
    } else if (condition.text.length < 3 || condition.text.length > 250) {
      error.text = "Message must be between 3 to 250 characters !";
      settextErr(true);
    } else {
      setCategoryErr(false);
      setSubjectErr(false);
      settextErr(false);
    }
    setformErr(error);
  };

  const submit = async () => {
    validate(Formdata);
    if (
      SubjectErrref.current === false &&
      CategoryErrref.current === false &&
      textErrref.current === false
    ) {
      toast.success(
        "Your ticket created successfully, Please wait for admin reply"
      );
      var data = {
        apiUrl: apiService.createdSupport,
        payload: Formdata,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      const obj = {
        Subject: "",
        Category: "Choose Category",
        text: "",
      };
      setFormdata(obj);
      viewData();
    } else {
      console.log("All are required fields");
    }
  };

  const viewData = async () => {
    try {
      var api = {
        apiUrl: apiService.findedSupport,
      };
      var view = await postMethod(api);
      if (view.status) setuser(view.data.data);
      setTotalpages(view.data.total);
    } catch (error) {}
  };

  const getCategory = async () => {
    try {
      var api = {
        apiUrl: apiService.supportCategories,
      };
      setSiteLoader(true);
      var response = await getMethod(api);
      setSiteLoader(false);

      if (response.status) {
        setCategories(response.data);
      }
    } catch (error) {}
  };

  const userChat = async (data) => {
    try {
      setChatDatas(data);
      setViewChat(true);
      var obj = {
        _id: data._id,
      };
      var api = {
        apiUrl: apiService.getSupportDetails,
        payload: obj,
      };
      var view = await postMethod(api);
      if (view) {
        setchatHistory(view.Message.reply);
      } else {
        setchatHistory([]);
      }
    } catch (error) {}
  };

  const closeTicket = async (data) => {
    try {
      var obj = {
        _id: data._id,
      };
      var api = {
        apiUrl: apiService.closeTicket,
        payload: obj,
      };
      var view = await postMethod(api);
      if (view) {
        viewData();
        toast.success(view.Message);
      } else {
        toast.error(view.Message);
      }
    } catch (error) {}
  };

  const back = async () => {
    setViewChat(false);
  };

  const reply = async () => {
    try {
      if (Object.keys(replyMessref.current).length > 0) {
        var obj = {
          message: replyMessref.current,
          chatId: chatDatas._id,
          tag: "user",
          image: "",
          status: "0",
        };
        var api = {
          apiUrl: apiService.addUserSupport,
          payload: obj,
        };
        var view = await postMethod(api);
        if (view) {
          userChat(chatDatas);
          replyMessage("");
          toast.success(view.Message);
        }
      } else {
        toast.error("Please enter the message!");
      }
    } catch (error) {}
  };

  return (
    <main className="support_page_main">
      <div>
        <Home_header />
      </div>

      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            <div className="col-lg-10">
              <section className="support_section_netex">
                <div className="container">
                  <div className="profile_title">Support</div>
                  <div className="row justify-content-center cardd_borderr">
                    <div className="col-lg-7 smart_login_row">
                      <div className="card_login register_page_login_card user-profile">
                        <div>
                          <label className="lable_text">Subject</label>
                          <div className="form_login_input">
                            <input
                              type="text"
                              autocomplete="off"
                              className="form-control"
                              placeholder="Subject"
                              name="Subject"
                              value={Subject}
                              minLength={4}
                              maxLength={25}
                              onChange={getItem}
                            />
                          </div>
                          {SubjectErr && (
                            <small className="errorcss">
                              {formErr.Subject}
                            </small>
                          )}
                        </div>

                        <div className="select_category">
                          <label className="lable_text">Select Category</label>
                          <div className="form_login_input">
                            <select
                              className="form-control"
                              name="Category"
                              value={Category}
                              onChange={withCash}
                            >
                              <option
                                value="Choose Category"
                                className="mt-3"
                                disabled
                              >
                                Choose Category
                              </option>
                              {supportCategories.map((item, j) => (
                                <option
                                  key={j}
                                  value={item.category}
                                  className="mt-3"
                                >
                                  {item.category}
                                </option>
                              ))}
                            </select>

                            {/* <div className="support-dropdown">
                              <Dropdown
                                placeholder="Choose Category"
                                fluid
                                className="custom-dropdown"
                                selection
                                options={preferPayment}
                              />
                            </div> */}
                          </div>
                          {CategoryErr && (
                            <small className="errorcss">
                              {formErr.Category}
                            </small>
                          )}
                        </div>
                        <div>
                          <label className="lable_text">Message</label>
                          <div className="form_login_input">
                            <textarea
                              name="text"
                              value={text}
                              minLength={3}
                              className="form-control"
                              cols="50"
                              maxLength={250}
                              placeholder="Message"
                              onChange={getItem}
                            />
                          </div>
                          {textErr && (
                            <small className="errorcss">{formErr.text}</small>
                          )}
                        </div>
                        <div className="profile_submit_btn">
                          {buttonLoader ? (
                            <button className="">Loading</button>
                          ) : (
                            <button className="" onClick={submit}>
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="deposit_history_tabel_section">
                <div className="container">
                  <div className="deposit_history_title">Support History</div>
                  <div className="table-responsive deposite_tabel_border">
                    <table className="table">
                      <thead>
                        <tr className="tabel_bg">
                          <th scope="col" className="starts">
                            Ticket
                          </th>
                          <th scope="col">Subject</th>
                          <th scope="col">Message</th>
                          <th scope="col">Category</th>
                          <th scope="col">Date/Time</th>
                          <th scope="col" className="ends">
                            Status
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {user && user.length > 0 ? (
                          user.map((data, i) => {
                            return (
                              <tr className="history_tabel_bottom_border">
                                <td>#{data._id.substring(0, 8)}</td>
                                <td>{data.subject}</td>
                                <td>{data.message}</td>
                                <td>{data.category}</td>
                                <td>{moment(data.updated_at).format("lll")}</td>
                                {/* <td onClick={() => userChat(data)}> View </td> */}
                                {data.status == "0" ? (
                                  <td
                                    onClick={() => closeTicket(data)}
                                    className="text-success cursor-pointer"
                                  >
                                    {" "}
                                    Close{" "}
                                  </td>
                                ) : (
                                  <td className="text-danger"> Closed </td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <td colSpan={7}>
                            <div className="empty_data">
                              <div className="empty_data_img">
                                <img src={require("../images/No-data.png")} />
                              </div>

                              <div className="no_records_text">
                                No Records Found
                              </div>
                            </div>
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      )}
    </main>
  );
};

export default Support;
