import React, { useEffect } from "react";
import Home_header from "./Home_header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

function Home() {
  return (
    <main className="dashboard_page">
      <section>
        <Home_header />
      </section>

      <section className="dashboard_content_section">
        <div className="container">
          <div className="smart_dashboard_content">
            <h2>
              Net1Ex –<span> Listing</span>{" "}
            </h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_board_profile_content">
                  <div className="contentpage">
                    <p>
                      Net1Ex provides a robust platform designed to enhance your
                      token’s market visibility and accessibility, leveraging a
                      broad user base and a solid infrastructure. Listing your
                      token on Net1Ex is a great way to increase its exposure
                      and connect with a diverse trading community.
                    </p>
                    <p>
                      To apply for a listing, simply click on the 'Listing Form'
                      and complete the required details. Our business manager
                      will reach out to you promptly to guide you through the
                      process.
                    </p>

                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSeR4yXBPYQxLUFhODu94q6dfRed0FavJDbFSiMsoeL3IgeW4w/viewform">
                      <button className="listing-form-btn">Listing Form</button>
                    </a>

                    <p>
                      For any questions or assistance, please contact us at{" "}
                      <a href="net1ex.com">info@net1ex.com</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
}

export default Home;
