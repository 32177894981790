import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Button } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Home_header";
import { socket } from "./context/socket";
import { getAuthToken } from "../core/lib/localStorage";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { env } from "../core/service/envconfig";
import moment from "moment";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { widget } from "../core/lib/chart/charting_library/charting_library.min";
import isEmpty from "../core/lib/isEmpty";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import PriceFooter from "./TradePage/PriceFooter";

const colourStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isFocused ? "#222327" : "#222327",
    color: isFocused ? "#ffc630" : "#fff",
    cursor: isDisabled ? "not-allowed" : "pointer",
    borderBottom: `1px solid ${isFocused ? "#ffc630" : "#17171a"}`,
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
  }),
};

function Home() {
  const [theme, setTheme] = useState("Dark");
  const [pair, setPair] = useState("NET1_USDN");
  //

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleIconClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  //
  const options = ["one", "two", "three"];
  const [pairlist, setpairlist] = useState([]);
  const [orderbookask, setorderbookask, orderbookaskref] = useState([]);
  const [orderbooksloader, setorderbooksloader, orderbooksloaderref] =
    useState(false);

  const [orderbookbid, setorderbookbid, orderbookbidref] = useState([]);
  const [searchInputlist, setsearchInputlist, searchInputlistref] = useState(
    []
  );
  const [curnt_Ordertype_tab, setcurnt_Ordertype_tab, curnt_Ordertype_tabref] =
    useState("Limit");
  const [currentPair, setcurrentPair] = useState("");
  const [fromCurrency, setFromCurrenncy] = useState("");
  const [toCurrency, setToCurrenncy] = useState("");
  const [currentType, setcurrentType, currentTyperef] = useState("buy");
  const [frombalance, setFromBalance] = useState(0);
  const [tobalance, settobalance] = useState(0);

  const [checkAuth, setcheckAuth] = useState(false);
  const [pairDetails, setpairDetails] = useState("");
  const [orderbookLoader, setorderbookLoader] = useState(true);
  const [orderbookLoaderbid, setorderbookLoaderbit] = useState(true);
  const [orderbookdivider, setorderbookdivider] = useState(true);
  const [pairLoader, setPairLoader] = useState(false);
  const [sideLoader, setsideLoader] = useState(false);

  const [perpage, setperpage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeOrderDatas, setActiveOders] = useState([]);
  const [tradeHistoryData, settradeHistory] = useState([]);
  // const [alltradeHistoryData, setalltradeHistory] = useState([]);
  const [cancelOrders, setcancelOrders] = useState([]);
  const [pairTickerDetails, setpairTickerDetails] = useState("");
  const [marketPrice, setmarketPrice, marketPriceref] = useState(0);
  const [marketTrade, setmarketTrade] = useState([]);
  const [totalactive, settotalactive] = useState(0);
  const [currentPagecan, setCurrentPagecan] = useState(1);
  const [totalcan, settotalcan] = useState(0);
  const [currentPageHis, setcurrentPageHis] = useState(1);
  const [totalHist, settotalHist] = useState(0);
  const [authentication, setauthentication] = useState(false);
  const [searchpair, setsearchpair] = useState(null);
  const [currentpairs, setcurrentpairs, currentpairsref] = useState(null);
  const [allpairslist, setallpairslist, allpairslistref] = useState([]);
  const [orderloader, setorderloader, orderloaderref] = useState(false);
  const [sellorderloader, setsellorderloader, sellorderloaderref] =
    useState(false);
  const [pairliststatus, setpairliststatus, pairliststatusref] =
    useState("USDT");
  const [siteLoader, setSiteLoader, siteLoaderref] = useState(false);
  const [priceLoader, setpriceLoader, priceLoaderref] = useState(false);
  const [fromcurrency, Setfromcurrency, fromcurrencyref] = useState("");
  const [tocurrency, Settocurrency, tocurrencyref] = useState("");

  const [marketTradeloader, setmarketTradeloader, marketTradeloaderref] =
    useState(false);
  const [sliderValue, setSliderValue, sliderValueref] = useState(0);
  const [sliderValue1, setSliderValue1, sliderValue1ref] = useState(0);
  const [sliderValue2, setSliderValue2, sliderValue2ref] = useState(0);
  const [sliderValue3, setSliderValue3, sliderValue3ref] = useState(0);
  const [sliderValue4, setSliderValue4, sliderValue4ref] = useState(0);
  const [sliderValue5, setSliderValue5, sliderValue5ref] = useState(0);

  const [mytrades, setmytrades] = useState("home");
  const [mymytopmove, setmymytopmove] = useState("all");
  const [myhistory, setmyhistory] = useState("openorder");
  const [myspot, setmyspot, myspotref] = useState("Limit");

  const [buyForm, setbuyForm, buyFormref] = useState(true);
  const [sellForm, setsellForm, sellFormref] = useState(false);

  const mytradesfunction = (newValue) => {
    setmytrades(newValue);
  };

  const mytopmove = (newValue) => {
    setmymytopmove(newValue);
  };

  const myhistoryfunction = (newValue) => {
    setmyhistory(newValue);
  };

  const myspotfunction = (newValue) => {
    setmyspot(newValue);
    formValue.amount = "";
    formValue.sellamount = "";
    formValue.total = "";
    formValue.selltotal = "";
  };

  const handletypeChange = (event) => {
    setmyspot(event.target.value);
    formValue.amount = "";
    formValue.sellamount = "";
    formValue.total = "";
    formValue.selltotal = "";
  };

  const Fullorderbook = (event, newValue) => {
    setorderbookLoader(true);
    setorderbookLoaderbit(true);
    setorderbookdivider(true);
  };
  const bidorderbook = (event, newValue) => {
    setorderbookLoader(false);
    setorderbookLoaderbit(true);
    setorderbookdivider(false);
  };
  const askorderbook = (event, newValue) => {
    setorderbookLoader(true);
    setorderbookLoaderbit(false);
    setorderbookdivider(false);
  };

  const handleSliderChange = (event, newValue) => {
    setcurrentType(event.target.name);
    setSliderValue(newValue);
    buy_sell_percentage(newValue);
    setSliderValue1("0");
    setSliderValue2("0");
    setSliderValue3("0");
    setSliderValue4("0");
    setSliderValue5("0");

    buy_sell_percentage1("0");
  };
  const handleSliderChange1 = (event, newValue) => {
    setcurrentType(event.target.name);

    setSliderValue1(newValue);
    buy_sell_percentage1(newValue);
    setSliderValue("0");
    setSliderValue2("0");
    setSliderValue3("0");
    setSliderValue4("0");
    setSliderValue5("0");
    buy_sell_percentage("0");
  };
  const handleSliderChange2 = (event, newValue) => {
    setcurrentType(event.target.name);
    setSliderValue2(newValue);

    buy_sell_percentage(newValue);
    setSliderValue1("0");
    setSliderValue("0");
    setSliderValue3("0");
    setSliderValue4("0");
    setSliderValue5("0");
    buy_sell_percentage1("0");
  };
  const handleSliderChange3 = (event, newValue) => {
    setcurrentType(event.target.name);
    setSliderValue3(newValue);
    buy_sell_percentage1(newValue);
    setSliderValue1("0");
    setSliderValue2("0");
    setSliderValue("0");
    setSliderValue4("0");
    setSliderValue5("0");
    buy_sell_percentage("0");
  };
  const handleSliderChange4 = (event, newValue) => {
    setSliderValue4(newValue);
    console.log(event.target.name, "newValue");
    setcurrentType(event.target.name);
    buy_sell_percentage(newValue);
    setSliderValue1("0");
    setSliderValue2("0");
    setSliderValue3("0");
    setSliderValue("0");
    setSliderValue5("0");
    buy_sell_percentage1("0");
  };
  const handleSliderChange5 = (event, newValue) => {
    setcurrentType(event.target.name);
    setSliderValue5(newValue);
    buy_sell_percentage1(newValue);
    setSliderValue1("0");
    setSliderValue2("0");
    setSliderValue3("0");
    setSliderValue4("0");
    setSliderValue("0");
    buy_sell_percentage("0");
  };
  const recordPerPage = 5;
  const pageRange = 5;
  const recordPerPagecan = 5;
  const pageRangecan = 5;

  const recordPerPageHist = 5;
  const pageRangeHis = 5;

  const navigate = useNavigate();

  const tvWidget = null;

  useEffect(() => {
    var urls = window.location.href;
    var pair = urls.split("trade/")[1];
    Setfromcurrency(pair.split("_")[0]);
    Settocurrency(pair.split("_")[1]);
    var replace_string = pair.replace("_", "");
    var changelower = replace_string.toLowerCase();
    socket.emit("GetOrderBook", { symbol: changelower });
    if (tvWidget !== null) {
      tvWidget.remove();
      tvWidget = null;
    }
    //setorderbooksloader(true);

    check();
    socketinit();
    selectPairbyCurrency("USDN");
  }, [socket]);

  async function check() {
    let mecheck = await getAuthToken();

    var callapi = false;
    if (mecheck != "" && mecheck != undefined && mecheck != null) {
      await setcheckAuth(true);
      callapi = true;
    } else {
      await setcheckAuth(false);
      callapi = false;
    }
    var urls = window.location.href;
    var fetchPair = urls.split("trade/")[1];
    if (fetchPair) {
      setcurrentPair(fetchPair);
      var fromcurr = fetchPair.split("_")[0];
      var toCurr = fetchPair.split("_")[1];
      setFromCurrenncy(fromcurr);
      Setfromcurrency(fromcurr);
      setToCurrenncy(toCurr);
      Settocurrency(toCurr);
      getCurrpairDatas(fetchPair);
      fetchTickerPrice(fetchPair);
      getMarketTrades(fetchPair);
      selectPair(fetchPair);
      if (callapi == true) {
        await getUserbalance(fetchPair);
        await getstopLimitOrders(1);
        await getActiveOrders(1, fetchPair);
        await tradeHistory(1);
        getCancelOrders(1);
      } else {
      }
      setPair(fromcurr + "_" + toCurr);
      allpairDatas();
    } else {
      navigate("/");
    }
    socket.connect();
  }

  const socketinit = async () => {
    socket.on("OrderBook", async (response) => {
      var data = await response.data;
      if (data != null && data != undefined && data != "") {
        if (data.symbol) {
          setorderbookask([]);
          setorderbookbid([]);
          var orderbookbid = [];
          var orderbookask = [];
          orderbookbid = data["bids"].length == 0 ? [] : data["bids"];
          orderbookask = data["asks"].length == 0 ? [] : data["asks"];
          var askcumtotal = 0;
          for (let index = 0; index < orderbookask.length; index++) {
            var element = orderbookask[index];
            var multiply = element[0] * element[1];
            askcumtotal = parseFloat(askcumtotal) + parseFloat(multiply);
            orderbookask[index]["percent"] = (multiply / askcumtotal) * 100;
          }
          var bidcumtotal = 0;
          for (let index = 0; index < orderbookbid.length; index++) {
            var element = orderbookbid[index];
            var multiply = element[0] * element[1];
            bidcumtotal = parseFloat(bidcumtotal) + parseFloat(multiply);
            orderbookbid[index]["percent"] = (multiply / bidcumtotal) * 100;
          }
          setorderbookask(
            orderbookask.sort(function (a, b) {
              return b[0] - a[0];
            })
          );
          setorderbookbid(
            orderbookbid.sort(function (a, b) {
              return b[0] - a[0];
            })
          );
          //setorderbooksloader(false);
        }
      }
    });
    socket.on("TickerPrice", async (response) => {
      if (response.data) {
        var tickerdetail = response.data;
        setpairTickerDetails(tickerdetail);
        setmarketPrice(
          tickerdetail.lastprice.lastprice
            ? tickerdetail.lastprice.lastprice
            : 0.0
        );
        if (curnt_Ordertype_tabref.current == "Stop") {
          if (formValue.price <= 0) {
            formValue.price = parseFloat(+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
            formValue.sellprice = parseFloat(+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
          }
        } else if (curnt_Ordertype_tabref.current == "Market") {
          formValue.price = parseFloat(+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
          formValue.sellprice = parseFloat(+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
        } else {
          if (priceLoaderref.current == false) {
            formValue.price = parseFloat(+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
            formValue.sellprice = parseFloat(+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
          }
        }
      }
    });
    socket.on("TradeHistory", async (response) => {
      var tickerdetail = response.data;
      if (tickerdetail !== null) {
        setmarketTrade(tickerdetail);
      } else {
        if (marketTrade.length > 0) {
        }
      }
    });
    socket.on("loadchartend", async (response) => {
      var myFrame = document.querySelector("iframe");
      if (myFrame != undefined && myFrame != null) {
        myFrame.contentWindow.location.reload(true);
      }
      buildchart(
        localStorage.getItem("theme") == "light" ? "White" : "Dark",
        fromcurrencyref.current + "_" + tocurrencyref.current
      );
    });
    let token_socket = localStorage.getItem("socket_token");
    if (token_socket) {
      let socketToken = token_socket.split("_")[0];
      socket.on("userDetails" + socketToken, async (response) => {
        if (currentPair !== "") {
          getActiveOrders(1, currentPair);
          getUserbalance(currentPair);
          tradeHistory(1);
          getCancelOrders(1);
          getMarketTrades(currentPair);
        } else {
          var urls = window.location.href;
          var fetchPair = urls.split("trade/")[1];
          if (fetchPair) {
            setcurrentPair(fetchPair);
            getActiveOrders(1, fetchPair);
            getUserbalance(fetchPair);
            tradeHistory(1);
            getCancelOrders(1);
            getMarketTrades(fetchPair);
          }
        }
      });
      socket.on("cancelOrder" + socketToken, async (response) => {
        showSuccessToast(
          "Your order cancelled by admin, Please try again later"
        );
        if (currentPair !== "") {
          getActiveOrders(1, currentPair);
          getUserbalance(currentPair);
          tradeHistory(1);
          getCancelOrders(1);
        } else {
          var urls = window.location.href;
          var fetchPair = urls.split("trade/")[1];
          if (fetchPair) {
            setcurrentPair(fetchPair);
            getActiveOrders(1, fetchPair);
            getUserbalance(fetchPair);
            tradeHistory(1);
            getCancelOrders(1);
          }
        }
      });
    }
    socket.on("close", function () {
      socket.connect();
      //setorderbooksloader(true);
      socketinit();
    });
  };

  useEffect(() => {
    fetchTheme();
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  const getLanguageFromURL = () => {
    const regex = new RegExp("[\\?&]lang=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const buildchart = (theme, pair) => {
    console.log("chart theme", theme);
    console.log("chart symbol", pair);
    try {
      const widgetOptions = {
        symbol: pair,
        datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
          env.apiHost + "chartapi/chart"
        ),
        interval: pair == "USDT_INR" ? "240" : "1D",
        container_id: "tv_chart_container",
        library_path: "/charting_library/",
        locale: getLanguageFromURL() || "en",
        disabled_features: ["use_localstorage_for_settings"],
        enabled_features: ["study_templates"],
        charts_storage_url: "",
        charts_storage_api_version: "1.1",
        client_id: "tradingview.com",
        user_id: "public_user_id",
        fullscreen: false,
        //autosize: true,
        width: "100%",
        height: "518",
        studies_overrides: {},
        loading_screen: { backgroundColor: theme == "White" ? "fff" : "#111" },
        theme: theme,
        toolbar_bg: theme == "White" ? "fff" : "#111",
        timezone: "Asia/Kolkata",
        overrides: {
          "paneProperties.background": theme == "White" ? "fff" : "#111",
          "paneProperties.vertGridProperties.color": "transparent",
          "paneProperties.horzGridProperties.color": "transparent",
        },
      };

      if (theme == "White") {
        delete widgetOptions.toolbar_bg;
        delete widgetOptions.overrides;
      }

      const tvWidget = new widget(widgetOptions);

      tvWidget.onChartReady(() => {
        tvWidget.headerReady().then(() => {
          const button = tvWidget.createButton();
          button.setAttribute("title", "Click to show a notification popup");
          button.classList.add("apply-common-tooltip");
          button.addEventListener("click", () =>
            tvWidget.showNoticeDialog({
              title: "Notification",
              body: "TradingView Charting Library API works correctly",
              callback: () => {
                console.log("Noticed!");
              },
            })
          );
        });
      });
    } catch (error) {
      console.log("chart error", error);
    }
  };

  const fetchTheme = () => {
    var theme = localStorage.getItem("theme");
    if (theme != undefined) {
      if (theme == "light") {
        setTheme("White");
      } else {
        setTheme("Dark");
      }
    } else {
      localStorage.setItem("theme", "dark");
      setTheme("Dark");
    }
    let themeValue = "Dark";
    if (theme == "light") {
      themeValue = "White";
    } else if (theme == "dark") {
      themeValue = "Dark";
    }
    var urls = window.location.href;
    var pair = urls.split("trade/")[1];
    buildchart(themeValue, pair);
  };

  const selectPair = async (pair) => {
    var replace_string = pair.replace("_", "");
    var changelower = replace_string.toLowerCase();
    socket.emit("GetOrderBook", { symbol: changelower });
    setPair(pair);
  };

  const Movelogin = () => {
    navigate("/login");
  };

  const getCurrpairDatas = async (pair) => {
    var data = {
      apiUrl: apiService.getCurrpairData,
      payload: { pair: pair },
    };
    var fetchticker = await postMethod(data);
    if (fetchticker) {
      setpairDetails(fetchticker.data);
    }
  };

  const selectPairbyCurrency = async (curr) => {
    setpairliststatus(curr);
    setPairLoader(true);
    // setsideLoader(true);
    socket.off("DashTickerPrice");
    socket.emit("GetTickerPrice", "getall");
    var data = {
      apiUrl: apiService.pairbycurrency,
      payload: { currency: curr },
    };

    var pairdetail = await postMethod(data);
    if (pairdetail) {
      socket.on("DashTickerPrice", async (response) => {
        //console.log("DashTickerPrice", response)
        setPairLoader(false);
        // setsideLoader(false);
        var tickarr = await response.data;
        for (let index = 0; index < pairdetail.data.length; index++) {
          const element = pairdetail.data[index];
          var replace_string = element.pair.replace("_", "");
          var changelower = replace_string.toLowerCase();
          if (tickarr[changelower]) {
            var tickobj = JSON.parse(tickarr[changelower]);
            if (tickarr) {
              if (element.pair == tickobj.pair) {
                pairdetail.data[index]["price_change"] =
                  (await tickobj.change_percent)
                    ? parseFloat(tickobj.change_percent).toFixed(4)
                    : 0.0;
                pairdetail.data[index]["lastprice"] = (await tickobj.lastprice
                  .lastprice)
                  ? tickobj.lastprice.lastprice
                  : 0.0;
              }
            }
          }
        }
      });

      await setsearchInputlist(pairdetail.data);
      if (searchpair != null) {
        setpairlist(
          searchInputlistref.current.filter(function (tag) {
            if (
              tag.liquidity_name
                .toLowerCase()
                .indexOf(searchpair.toLowerCase()) >= 0 ||
              tag.liquidity_name
                .toLowerCase()
                .indexOf(searchpair.toLowerCase()) >= 0
            ) {
              return tag;
            }
          })
        );
      } else {
        await setpairlist(pairdetail.data);
        await setsearchInputlist(pairdetail.data);
        setPairLoader(false);
        // setsideLoader(false);
      }
    }
  };

  async function handleInputChange(event) {
    event.preventDefault();

    const sanitizedValue = event.target.value.replace(/\s+/g, "");

    if (sanitizedValue.indexOf("_") > 0) {
      var searchval = sanitizedValue.replace("_", "");
      setcurrentpairs(sanitizedValue);
      pair_change();
      setpairlist(
        allpairslistref.current.filter(function (tag) {
          if (
            tag.liquidity_name.toLowerCase().indexOf(searchval.toLowerCase()) >=
              0 ||
            tag.liquidity_name.toLowerCase().indexOf(searchval.toLowerCase()) >=
              0
          ) {
            return tag;
          }
        })
      );
    } else {
      setsearchpair(sanitizedValue);
      setpairlist(
        searchInputlistref.current.filter(function (tag) {
          if (
            tag.liquidity_name
              .toLowerCase()
              .indexOf(sanitizedValue.toLowerCase()) >= 0 ||
            tag.liquidity_name
              .toLowerCase()
              .indexOf(sanitizedValue.toLowerCase()) >= 0
          ) {
            return tag;
          }
        })
      );
    }
  }

  //------trade forms--------//

  const pairChange = async (pair) => {
    try {
      setDropdownVisible(!isDropdownVisible);

      console.log("call pair", pair);
      setPairLoader(true);
      navigate("/trade/" + pair.pair);
      setcurrentPair(pair.pair);
      getCurrpairDatas(pair.pair);

      var indexPage = pairlist.findIndex((x) => x.pair == pair.pair);

      if (indexPage != -1) {
        var getPair = pairlist[indexPage];
        var fromcurr = getPair.pair.split("_")[0];
        var toCurr = getPair.pair.split("_")[1];
        setFromCurrenncy(fromcurr);
        setToCurrenncy(toCurr);
        Settocurrency(toCurr);
        Setfromcurrency(fromcurr);
        // console.log(fromcurr,"fromcurr",toCurr,"toCurr");
        if (checkAuth === true) {
          getUserbalance(pair.pair);
          getCancelOrders(1);
          getActiveOrders(1, pair.pair);
        }
        getCurrpairDatas(getPair.pair);
        fetchTickerPrice(getPair.pair);
        getMarketTrades(getPair.pair);
        setPair(getPair.pair);
        setPairLoader(false);
        var themevalue =
          localStorage.getItem("theme") == "light" ? "White" : "Dark";

        buildchart(themevalue, pair.pair);

        var replace_string = pair.pair.replace("_", "");
        var changelower = replace_string.toLowerCase();
        socket.emit("GetOrderBook", { symbol: changelower });
      }
    } catch (error) {}
  };

  const getUserbalance = async (pair) => {
    var obj = {
      pair: pair,
    };
    var data = {
      apiUrl: apiService.getparUserBalance,
      payload: obj,
    };
    setSiteLoader(true);
    var resp = await postMethod(data);
    setSiteLoader(false);

    if (resp.status) {
      if (resp.data != null) {
        var getFromBalance = resp.data.fromCurrency;
        var getToBalance = resp.data.toCurrency;
        if (getFromBalance != null) {
          setFromBalance(getFromBalance.totalBalance);
        }

        if (getToBalance != null) {
          settobalance(getToBalance.totalBalance);
        }
      }
    } else {
    }
  };

  const pair_change = async () => {
    try {
      if (currentpairsref.current.indexOf("_") > 0) {
        var pairname = currentpairsref.current;
        var indexPage = pairlist.findIndex((x) => x.pair == pairname);
        if (indexPage != -1) {
          setPairLoader(true);
          navigate("/trade/" + pairname);
          setcurrentPair(pairname);
          getCurrpairDatas(pairname);
          var getPair = pairlist[indexPage];
          var fromcurr = getPair.pair.split("_")[0];
          var toCurr = getPair.pair.split("_")[1];
          setFromCurrenncy(fromcurr);
          setToCurrenncy(toCurr);
          if (checkAuth === true) {
            getUserbalance(pair.pair);
            getCancelOrders(1);
            getActiveOrders(1, pair.pair);
          }
          getCurrpairDatas(getPair.pair);
          // fetchTickerPrice(getPair.pair);
          getMarketTrades(getPair.pair);
          setPair(getPair.pair);
          setPairLoader(false);
        }
      }
    } catch (error) {}
  };

  const allpairDatas = async () => {
    var data = {
      apiUrl: apiService.allpairs,
    };
    var allpairs = await getMethod(data);
    if (allpairs) {
      setallpairslist(allpairs.data);
    }
  };

  const initialFormValue = {
    price: "",
    amount: "",
    total: "",
    stop_price: "",
    sellprice: "",
    sellamount: "",
    selltotal: "",
    sellstop_price: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);

  const {
    price,
    amount,
    total,
    stop_price,
    sellprice,
    sellamount,
    selltotal,
    sellstop_price,
  } = formValue;
  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    if (/[eE\+\-]/.test(pasteData)) {
      e.preventDefault();
    }
  };

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const handleKeyDown = (e) => {
    if (/[eE\+\-]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (!/[eE\+\-]/.test(value)) {
      let formData = { ...formValue, ...{ [name]: value } };
      if (curnt_Ordertype_tabref.current == "Limit") {
        if (formData.sellamount != "") {
          let totalPrice = formData.sellprice * formData.sellamount;
          formData = {
            ...formData,
            //...{["total"]: parseFloat(totalPrice).toFixed(pairDetails?.liq_price_decimal)},
            ...{ ["selltotal"]: parseFloat(totalPrice).toFixed(8) },
          };
        } else {
          let totalPrice = formData.price * formData.amount;
          formData = {
            ...formData,
            //...{["total"]: parseFloat(totalPrice).toFixed(pairDetails?.liq_price_decimal)},
            ...{ ["total"]: parseFloat(totalPrice).toFixed(8) },
          };
        }
      } else if (curnt_Ordertype_tabref.current == "Market") {
        if (formData.sellamount != "") {
          let totalPrice = formData.sellprice * formData.sellamount;
          formData = {
            ...formData,
            //...{["total"]: parseFloat(totalPrice).toFixed(pairDetails?.liq_price_decimal)},
            ...{ ["selltotal"]: parseFloat(totalPrice).toFixed(8) },
          };
        } else {
          let totalPrice = +marketPriceref.current * formData.amount;
          formData = {
            ...formData,
            //...{["total"]: parseFloat(totalPrice).toFixed(pairDetails?.liq_price_decimal)},
            ...{ ["total"]: parseFloat(totalPrice).toFixed(8) },
          };
        }
      } else {
        if (formData.sellamount != "") {
          let totalPrice = formData.sellprice * formData.sellamount;
          formData = {
            ...formData,
            //...{["total"]: parseFloat(totalPrice).toFixed(pairDetails?.liq_price_decimal)},
            ...{ ["selltotal"]: parseFloat(totalPrice).toFixed(8) },
          };
        } else {
          let totalPrice = formData.price * formData.amount;
          // console.log(total, "======total===total");
          formData = {
            ...formData,
            //...{["total"]: parseFloat(totalPrice).toFixed(pairDetails?.liq_price_decimal)},
            ...{ ["total"]: parseFloat(totalPrice).toFixed(8) },
          };
        }
      }

      if (formData.price < 0) {
        showErrorToast("Enter valid price");
      }
      if (formData.amount < 0) {
        showErrorToast("Enter valid amount");
      }
      setFormValue(formData);
    } else {
    }
  };

  const handleChange_total = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let formData = { ...formValue, ...{ [name]: value } };
    if (curnt_Ordertype_tabref.current == "Limit") {
      let amount = value / formData.price;
      formData = {
        ...formData,
        ...{ ["total"]: parseFloat(value) },
        ...{ ["amount"]: parseFloat(amount).toFixed(8) },
      };
    } else if (curnt_Ordertype_tabref.current == "Market") {
      let amount = value / +marketPriceref.current;
      formData = {
        ...formData,
        ...{ ["total"]: parseFloat(value) },
        ...{ ["amount"]: parseFloat(amount).toFixed(8) },
      };
    } else {
      let amount = value / formData.price;
      formData = {
        ...formData,
        ...{ ["total"]: parseFloat(value) },
        ...{ ["amount"]: parseFloat(amount).toFixed(8) },
      };
    }

    if (formData.price < 0) {
      showErrorToast("Enter valid price");
    }
    if (formData.amount < 0) {
      showErrorToast("Enter valid amount");
    }
    if (formData.total < 0) {
      showErrorToast("Enter valid total");
    }
    setFormValue(formData);
  };

  const orderPlace = async (ordertype, ordertab) => {
    try {
      var checkPrice = 0;
      if (ordertype == "Limit") {
        checkPrice = formValue.price;
      } else if (ordertype == "Market") {
        checkPrice = marketPriceref.current;
      } else {
        checkPrice = formValue.price;
      }
      if (formValue.amount > 0 && checkPrice > 0 && total) {
        // if(frombalance >= total){
        if (ordertype == "Limit") {
          var orderData = {
            amount: parseFloat(formValue.amount),
            price: parseFloat(formValue.price),
            total: parseFloat(total),
            type: ordertab,
            orderType: ordertype,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            pair: currentPair,
            stop_price: 0,
            pair_id: pairDetails._id,
            fromCurr_id: pairDetails.from_symbol_id,
            toCurr_id: pairDetails.to_symbol_id,
          };
        }
        if (ordertype == "Market") {
          var orderData = {
            amount: parseFloat(formValue.amount),
            price: parseFloat(marketPriceref.current),
            total: marketPriceref.current * parseFloat(formValue.amount),
            type: ordertab,
            orderType: ordertype,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            pair: currentPair,
            stop_price: 0,
            pair_id: pairDetails._id,
            fromCurr_id: pairDetails.from_symbol_id,
            toCurr_id: pairDetails.to_symbol_id,
          };
        }

        if (ordertype == "Stop") {
          var orderData = {
            amount: parseFloat(formValue.amount),
            price: parseFloat(formValue.price),
            total: formValue.price * parseFloat(formValue.amount),
            type: ordertab,
            orderType: ordertype,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            pair: currentPair,
            stop_price: parseFloat(formValue.stop_price),
            pair_id: pairDetails._id,
            fromCurr_id: pairDetails.from_symbol_id,
            toCurr_id: pairDetails.to_symbol_id,
          };
        }

        var data = {
          apiUrl: apiService.orderPlaceapi,
          payload: orderData,
        };
        setorderloader(true);
        var resp = await postMethod(data);
        setorderloader(false);
        setSliderValue("0");
        setSliderValue1("0");
        setSliderValue2("0");
        setSliderValue3("0");
        setSliderValue4("0");
        setSliderValue5("0");
        if (resp.status) {
          formValue.amount = "";
          fetchTickerPrice(currentPair);
          formValue.total = "";
          formValue.stop_price = "";
          getActiveOrders(1, currentPair);
          showSuccessToast(resp.Message);
          getUserbalance(currentPair);
        } else {
          formValue.amount = "";
          fetchTickerPrice(currentPair);

          formValue.total = "";
          formValue.stop_price = "";

          showErrorToast(resp.Message);
        }
      } else {
        showErrorToast("Enter all fields");
      }
    } catch (error) {
      showErrorToast("Please try again later");
    }
  };
  const sellorderPlace = async (ordertype, ordertab) => {
    try {
      var checkPrice = 0;
      if (ordertype == "Limit") {
        checkPrice = formValue.sellprice;
      } else if (ordertype == "Market") {
        checkPrice = marketPriceref.current;
      } else {
        checkPrice = formValue.sellprice;
      }
      if (formValue.sellamount > 0 && checkPrice > 0 && selltotal) {
        if (ordertype == "Limit") {
          var orderData = {
            amount: parseFloat(formValue.sellamount),
            price: parseFloat(formValue.sellprice),
            total: parseFloat(selltotal),
            type: ordertab,
            orderType: ordertype,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            pair: currentPair,
            stop_price: 0,
            pair_id: pairDetails._id,
            fromCurr_id: pairDetails.from_symbol_id,
            toCurr_id: pairDetails.to_symbol_id,
          };
        }
        if (ordertype == "Market") {
          var orderData = {
            amount: parseFloat(formValue.sellamount),
            price: parseFloat(marketPriceref.current),
            total: marketPriceref.current * parseFloat(formValue.sellamount),
            type: ordertab,
            orderType: ordertype,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            pair: currentPair,
            stop_price: 0,
            pair_id: pairDetails._id,
            fromCurr_id: pairDetails.from_symbol_id,
            toCurr_id: pairDetails.to_symbol_id,
          };
        }
        if (ordertype == "Stop") {
          var orderData = {
            amount: parseFloat(formValue.sellamount),
            price: parseFloat(formValue.sellprice),
            total: formValue.sellprice * parseFloat(formValue.sellamount),
            type: ordertab,
            orderType: ordertype,
            fromCurrency: fromCurrency,
            toCurrency: toCurrency,
            pair: currentPair,
            stop_price: parseFloat(formValue.sellstop_price),
            pair_id: pairDetails._id,
            fromCurr_id: pairDetails.from_symbol_id,
            toCurr_id: pairDetails.to_symbol_id,
          };
        }

        var data = {
          apiUrl: apiService.orderPlaceapi,
          payload: orderData,
        };

        setsellorderloader(true);
        var resp = await postMethod(data);
        setsellorderloader(false);

        setSliderValue("0");
        setSliderValue1("0");
        setSliderValue2("0");
        setSliderValue3("0");
        setSliderValue4("0");
        setSliderValue5("0");
        if (resp.status) {
          setsellorderloader(false);
          showSuccessToast(resp.Message);
          formValue.sellamount = "";
          fetchTickerPrice(currentPair);
          formValue.selltotal = "";
          formValue.sellstop_price = "";
          getActiveOrders(1, currentPair);
          getUserbalance(currentPair);
        } else {
          setsellorderloader(false);
          formValue.sellamount = "";

          formValue.selltotal = "";
          formValue.sellstop_price = "";
          showErrorToast(resp.Message);
        }
      } else {
        showErrorToast("Enter all fields");
      }
    } catch (error) {
      showErrorToast("Please try again later");
    }
  };

  const buy_sell_percentage = (percentage) => {
    if (checkAuth) {
      if (percentage == 25) {
        if (curnt_Ordertype_tabref.current != "Stop") {
          formValue.price = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          );
        }
      }
      if (percentage == 50) {
        if (curnt_Ordertype_tabref.current != "Stop") {
          formValue.price = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          );
        }
      }
      if (percentage == 75) {
        if (curnt_Ordertype_tabref.current != "Stop") {
          formValue.price = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          );
        }
      }
      if (percentage == 100) {
        if (curnt_Ordertype_tabref.current != "Stop") {
          formValue.price = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          );
        }
      }

      if (currentTyperef.current == "buy") {
        var total = (+percentage * +tobalance) / 100;
        var amt = total / +formValue.price;
        formValue.amount = amt.toFixed(8);
        formValue.total = +total.toFixed(8);
      } else if (currentTyperef.current == "sell") {
        var total = (+percentage * +frombalance) / 100;
        var tot = total * +formValue.price;
        formValue.amount = total.toFixed(8);
        formValue.total = +tot.toFixed(8);
      }
    } else {
    }
  };

  const buy_sell_percentage1 = (percentage) => {
    if (checkAuth) {
      if (percentage == 25) {
        if (curnt_Ordertype_tabref.current != "Stop") {
          formValue.sellprice = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          );
        }
      }
      if (percentage == 50) {
        if (curnt_Ordertype_tabref.current != "Stop") {
          formValue.sellprice = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          );
        }
      }
      if (percentage == 75) {
        if (curnt_Ordertype_tabref.current != "Stop") {
          formValue.sellprice = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          );
        }
      }
      if (percentage == 100) {
        if (curnt_Ordertype_tabref.current != "Stop") {
          formValue.sellprice = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          );
        }
      }

      if (currentTyperef.current == "buy") {
        var total = (+percentage * +tobalance) / 100;
        var amt = total / +formValue.sellprice;
        formValue.sellamount = amt.toFixed(8);

        formValue.selltotal = +total.toFixed(8);
      } else if (currentTyperef.current == "sell") {
        var total = (+percentage * +frombalance) / 100;

        var tot = total * +formValue.sellprice;
        formValue.sellamount = total.toFixed(8);
        formValue.selltotal = +tot.toFixed(8);
      }
    } else {
    }
  };

  const getActiveOrders = async (pages, getpair) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: getpair,
      };
      var data = {
        apiUrl: apiService.getActiveOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotalactive(resp.count);
        setActiveOders(resp.result);
      } else {
      }
    } catch (error) {}
  };

  const getstopLimitOrders = async (pages, getpair) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: getpair,
      };
      var data = {
        apiUrl: apiService.getStop_limit_Orders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        // settotalactive(resp.count);
        // console.log(resp.result);
      } else {
      }
    } catch (error) {}
  };

  const tradeHistory = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: pairDetails.pair,
      };
      var data = {
        apiUrl: apiService.tradeHistory,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settradeHistory(resp.result);
        settotalHist(resp.count);
      } else {
      }
    } catch (error) {}
  };

  const getCancelOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: currentPair,
      };
      var data = {
        apiUrl: apiService.getCancelOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setcancelOrders(resp.result);
        settotalcan(resp.count);
      } else {
      }
    } catch (error) {}
  };

  //========FETCH TICKER PRICE ==========//

  const fetchTickerPrice = async (pair) => {
    try {
      var data = {
        apiUrl: apiService.fetch_tickers,
        payload: { pair: pair },
      };
      var fetchticker = await postMethod(data);
      if (fetchticker) {
        var data = await fetchticker.data;
        setpairTickerDetails(data);
        setmarketPrice(
          data.lastprice.lastprice ? data.lastprice.lastprice : 0.0
        );
        if (curnt_Ordertype_tabref.current == "Stop") {
          if (formValue.price <= 0) {
            formValue.price = parseFloat(+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
            formValue.sellprice = parseFloat(+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
          }
        } else if (
          curnt_Ordertype_tabref.current == "Market" ||
          curnt_Ordertype_tabref.current == "Limit"
        ) {
          formValue.price = parseFloat(+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
          formValue.sellprice = parseFloat(+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
        }
      }
    } catch (error) {}
  };

  // ================FETCH MARKET =============//

  const getMarketTrades = async (pair) => {
    try {
      var data = {
        apiUrl: apiService.marketTrades,
        payload: { pair: pair },
      };
      setmarketTradeloader(true);
      var fetchTradeHisotory = await postMethod(data);
      if (fetchTradeHisotory) {
        if (fetchTradeHisotory.status) {
          setmarketTradeloader(false);
          setmarketTrade(fetchTradeHisotory.Message);
        } else {
          setmarketTradeloader(false);
          setmarketTrade([]);
        }
      } else {
      }
    } catch (error) {}
  };
  const activePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getActiveOrders(pageNumber, "");
  };

  const cancelPageChange = (event, pageNumber) => {
    setCurrentPagecan(pageNumber); // call API to get data based on pageNumber
    getCancelOrders(pageNumber);
  };

  const orderhistoryactive = (event, pageNumber) => {
    setcurrentPageHis(pageNumber); // call API to get data based on pageNumber
    tradeHistory(pageNumber);
  };

  const orderCancel = async (cancelDatas) => {
    try {
      var obj = {
        _id: cancelDatas._id,
      };
      var data = {
        apiUrl: apiService.cancelOrder,
        payload: obj,
      };
      var fetchTradeHisotory = await postMethod(data);
      if (fetchTradeHisotory) {
        showSuccessToast(
          "Order cancelled successfully, your amount credit to your wallet"
        );
        getActiveOrders(1, currentPair);
        getUserbalance(currentPair);
      } else {
        showErrorToast("Please try again later");
      }
    } catch (error) {
      showErrorToast("Please try again later");
    }
  };

  const callCancelOrder = async (data) => {
    setmyhistory(data);
    if (checkAuth === true) {
      getCancelOrders(1);
    }
  };
  const callOrdehistory = async (data) => {
    setmyhistory(data);
    if (checkAuth === true) {
      tradeHistory(1);
    }
  };

  const addPrice = async (price) => {
    setpriceLoader(true);
    if (curnt_Ordertype_tabref.current == "Limit") {
      formValue.price = parseFloat(price).toFixed(
        pairDetails?.liq_price_decimal
      );
      formValue.sellprice = parseFloat(price).toFixed(
        pairDetails?.liq_price_decimal
      );
    }
  };

  const selectOrder = async (ordertype) => {
    setcurrentType(ordertype);
    if (ordertype == "buy") {
      setbuyForm(true);
      setsellForm(false);
    } else {
      setbuyForm(false);
      setsellForm(true);
    }
    setmyspot("Limit");
    formValue.amount = "";
    formValue.sellamount = "";
    formValue.total = "";
    formValue.selltotal = "";
  };

  // const limitMarket = [
  //   { value: "Limit", label: "Limit" },
  //   { value: "Market", label: "Market" },
  //   { value: "Stop", label: "Stop" },
  // ];

  return (
    <div className="header ">
      <Header />

      <main className="min-height-100vh">
        <div className="trade_page_global">
          {orderbooksloaderref.current == true || sideLoader == true ? (
            <div className="dashboard_content_section mb-5 loadercss">
              <Bars
                height="40"
                width="40"
                color="#1062fe"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="subHeader">
                <div className="inner_sub">
                  <div className="content">
                    <div class="left">
                      <div class="headline_left__lBBPY">
                        <div class="headline_left__lBBPY_leftmain d-flex align-items-center">
                          <div class="headline_symbolName__KfmIZ mt_tr_pr">
                            <div>
                              <div className="headline_bigName__dspVW">
                                <i
                                  className="faaa ri-menu-add-line"
                                  onClick={handleIconClick}
                                ></i>
                              </div>

                              {isDropdownVisible && (
                                <div className="markets">
                                  <div className="form_seldect__pairs">
                                    <div className="searcj">
                                      <i className="ri-search-line"></i>
                                      <input
                                        type="text"
                                        placeholder="Search Pair"
                                        onChange={handleInputChange}
                                        name="searchpair"
                                        minLength={1}
                                        maxLength={15}
                                        value={searchpair}
                                      />
                                    </div>
                                    <ul className="nav nav-pills">
                                    <li>
                                        <a
                                          data-toggle="tab"
                                          href="javascript:void(0)"
                                          className={
                                            pairliststatusref.current === "USDN"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() =>
                                            selectPairbyCurrency("USDN")
                                          }
                                        >
                                          USDN
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          data-toggle="tab"
                                          href="javascript:void(0)"
                                          className={
                                            pairliststatusref.current === "USDT"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() =>
                                            selectPairbyCurrency("USDT")
                                          }
                                        >
                                          USDT
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          data-toggle="tab"
                                          href="javascript:void(0)"
                                          className={
                                            pairliststatusref.current === "BTC"
                                              ? "active"
                                              : ""
                                          }
                                          onClick={() =>
                                            selectPairbyCurrency("BTC")
                                          }
                                        >
                                          BTC
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          data-toggle="tab"
                                          className={
                                            pairliststatusref.current === "ETH"
                                              ? "active"
                                              : ""
                                          }
                                          href="javascript:void(0)"
                                          onClick={() =>
                                            selectPairbyCurrency("ETH")
                                          }
                                        >
                                          ETH
                                        </a>
                                      </li>
                                    </ul>
                                    <div className="tab-content pair_details">
                                    <div
                                        id="USDN"
                                        className="tab-pane fade in active show"
                                      >
                                        <div className="fixTableHead mt-2">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Pair</th>
                                                <th className="text-left">
                                                  Price
                                                </th>
                                                <th className="text-right">
                                                  Change
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {sideLoader ? (
                                                <tr>
                                                  <td
                                                    colSpan="3"
                                                    className="text-center "
                                                  >
                                                    <i className="fa-solid fa-spinner fa-spin text-center tradeicons"></i>
                                                  </td>
                                                </tr>
                                              ) : (
                                                pairlist &&
                                                pairlist.map((obj, i) => (
                                                  <tr
                                                    className={`cursor-pointer pair_section cursor-pointer-coin ${
                                                      obj.pair === pair
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      pairChange(obj)
                                                    }
                                                    key={i}
                                                  >
                                                    <td>
                                                      <div className="d-flex">
                                                        <img
                                                          src={
                                                            obj.Currency_image
                                                          }
                                                          alt="currency"
                                                        />
                                                        <h2>
                                                          {obj.from_symbol}/
                                                          <small>
                                                            {obj.to_symbol}
                                                          </small>
                                                        </h2>
                                                      </div>
                                                    </td>
                                                    <td className="text-left">
                                                      <div className="price_symbol">
                                                        {obj.lastprice ==
                                                          null ||
                                                        obj.lastprice === "" ? (
                                                          0.0
                                                        ) : (
                                                          <span
                                                            className={
                                                              obj.lastprice <= 0
                                                                ? "color-buy"
                                                                : "text-green"
                                                            }
                                                          >
                                                            {parseFloat(
                                                              obj.lastprice
                                                            ).toFixed(
                                                              pairDetails?.liq_price_decimal
                                                            )}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </td>
                                                    <td className="text-right">
                                                      <p className="mb-0">
                                                        <i
                                                          className={`bi ${
                                                            obj.price_change <=
                                                            0
                                                              ? "bi-caret-down-fill color-buy"
                                                              : "bi-caret-up-fill red-green"
                                                          }`}
                                                        ></i>
                                                        <span
                                                          className={
                                                            obj.price_change <=
                                                            0
                                                              ? "color-buy"
                                                              : "red-green"
                                                          }
                                                        >
                                                          {obj.price_change}%
                                                        </span>
                                                      </p>
                                                    </td>
                                                  </tr>
                                                ))
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div
                                        id="USDT"
                                        className="tab-pane fade"
                                      >
                                        <div className="fixTableHead mt-2">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Pair</th>
                                                <th className="text-left">
                                                  Price
                                                </th>
                                                <th className="text-right">
                                                  Change
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {sideLoader ? (
                                                <tr>
                                                  <td
                                                    colSpan="3"
                                                    className="text-center "
                                                  >
                                                    <i className="fa-solid fa-spinner fa-spin text-center tradeicons"></i>
                                                  </td>
                                                </tr>
                                              ) : (
                                                pairlist &&
                                                pairlist.map((obj, i) => (
                                                  <tr
                                                    className={`cursor-pointer pair_section cursor-pointer-coin ${
                                                      obj.pair === pair
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      pairChange(obj)
                                                    }
                                                    key={i}
                                                  >
                                                    <td>
                                                      <div className="d-flex">
                                                        <img
                                                          src={
                                                            obj.Currency_image
                                                          }
                                                          alt="currency"
                                                        />
                                                        <h2>
                                                          {obj.from_symbol}/
                                                          <small>
                                                            {obj.to_symbol}
                                                          </small>
                                                        </h2>
                                                      </div>
                                                    </td>
                                                    <td className="text-left">
                                                      <div className="price_symbol">
                                                        {obj.lastprice ==
                                                          null ||
                                                        obj.lastprice === "" ? (
                                                          0.0
                                                        ) : (
                                                          <span
                                                            className={
                                                              obj.lastprice <= 0
                                                                ? "color-buy"
                                                                : "text-green"
                                                            }
                                                          >
                                                            {parseFloat(
                                                              obj.lastprice
                                                            ).toFixed(
                                                              pairDetails?.liq_price_decimal
                                                            )}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </td>
                                                    <td className="text-right">
                                                      <p className="mb-0">
                                                        <i
                                                          className={`bi ${
                                                            obj.price_change <=
                                                            0
                                                              ? "bi-caret-down-fill color-buy"
                                                              : "bi-caret-up-fill red-green"
                                                          }`}
                                                        ></i>
                                                        <span
                                                          className={
                                                            obj.price_change <=
                                                            0
                                                              ? "color-buy"
                                                              : "red-green"
                                                          }
                                                        >
                                                          {obj.price_change}%
                                                        </span>
                                                      </p>
                                                    </td>
                                                  </tr>
                                                ))
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div
                                        id="BTC"
                                        className="tab-pane fade"
                                      >
                                        <div className="fixTableHead mt-2">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Pair</th>
                                                <th className="text-left">
                                                  Price
                                                </th>
                                                <th className="text-right">
                                                  Change
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {sideLoader ? (
                                                <tr>
                                                  <td
                                                    colSpan="3"
                                                    className="text-center "
                                                  >
                                                    <i className="fa-solid fa-spinner fa-spin text-center tradeicons"></i>
                                                  </td>
                                                </tr>
                                              ) : (
                                                pairlist &&
                                                pairlist.map((obj, i) => (
                                                  <tr
                                                    className={`cursor-pointer pair_section cursor-pointer-coin ${
                                                      obj.pair === pair
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      pairChange(obj)
                                                    }
                                                    key={i}
                                                  >
                                                    <td>
                                                      <div className="d-flex">
                                                        <img
                                                          src={
                                                            obj.Currency_image
                                                          }
                                                          alt="currency"
                                                        />
                                                        <h2>
                                                          {obj.from_symbol}/
                                                          <small>
                                                            {obj.to_symbol}
                                                          </small>
                                                        </h2>
                                                      </div>
                                                    </td>
                                                    <td className="text-left">
                                                      <div className="price_symbol">
                                                        {obj.lastprice ==
                                                          null ||
                                                        obj.lastprice === "" ? (
                                                          0.0
                                                        ) : (
                                                          <span
                                                            className={
                                                              obj.lastprice <= 0
                                                                ? "color-buy"
                                                                : "text-green"
                                                            }
                                                          >
                                                            {parseFloat(
                                                              obj.lastprice
                                                            ).toFixed(
                                                              pairDetails?.liq_price_decimal
                                                            )}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </td>
                                                    <td className="text-right">
                                                      <p className="mb-0">
                                                        <i
                                                          className={`bi ${
                                                            obj.price_change <=
                                                            0
                                                              ? "bi-caret-down-fill color-buy"
                                                              : "bi-caret-up-fill red-green"
                                                          }`}
                                                        ></i>
                                                        <span
                                                          className={
                                                            obj.price_change <=
                                                            0
                                                              ? "color-buy"
                                                              : "red-green"
                                                          }
                                                        >
                                                          {obj.price_change}%
                                                        </span>
                                                      </p>
                                                    </td>
                                                  </tr>
                                                ))
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div
                                        id="ETH"
                                        className="tab-pane fade"
                                      >
                                        <div className="fixTableHead mt-2">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Pair</th>
                                                <th className="text-left">
                                                  Price
                                                </th>
                                                <th className="text-right">
                                                  Change
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {sideLoader ? (
                                                <tr>
                                                  <td
                                                    colSpan="3"
                                                    className="text-center "
                                                  >
                                                    <i className="fa-solid fa-spinner fa-spin text-center tradeicons"></i>
                                                  </td>
                                                </tr>
                                              ) : (
                                                pairlist &&
                                                pairlist.map((obj, i) => (
                                                  <tr
                                                    className={`cursor-pointer pair_section cursor-pointer-coin ${
                                                      obj.pair === pair
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      pairChange(obj)
                                                    }
                                                    key={i}
                                                  >
                                                    <td>
                                                      <div className="d-flex">
                                                        <img
                                                          src={
                                                            obj.Currency_image
                                                          }
                                                          alt="currency"
                                                        />
                                                        <h2>
                                                          {obj.from_symbol}/
                                                          <small>
                                                            {obj.to_symbol}
                                                          </small>
                                                        </h2>
                                                      </div>
                                                    </td>
                                                    <td className="text-left">
                                                      <div className="price_symbol">
                                                        {obj.lastprice ==
                                                          null ||
                                                        obj.lastprice === "" ? (
                                                          0.0
                                                        ) : (
                                                          <span
                                                            className={
                                                              obj.lastprice <= 0
                                                                ? "color-buy"
                                                                : "text-green"
                                                            }
                                                          >
                                                            {parseFloat(
                                                              obj.lastprice
                                                            ).toFixed(
                                                              pairDetails?.liq_price_decimal
                                                            )}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </td>
                                                    <td className="text-right">
                                                      <p className="mb-0">
                                                        <i
                                                          className={`bi ${
                                                            obj.price_change <=
                                                            0
                                                              ? "bi-caret-down-fill color-buy"
                                                              : "bi-caret-up-fill red-green"
                                                          }`}
                                                        ></i>
                                                        <span
                                                          className={
                                                            obj.price_change <=
                                                            0
                                                              ? "color-buy"
                                                              : "red-green"
                                                          }
                                                        >
                                                          {obj.price_change}%
                                                        </span>
                                                      </p>
                                                    </td>
                                                  </tr>
                                                ))
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              <div class="headline_bigName__dspVW ">
                                <h1></h1>
                              </div>
                              <div class="headline_etfDisplay__P4Hdv">
                                <span>
                                  {fromcurrencyref.current +
                                    "/" +
                                    tocurrencyref.current}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="headline_leftItem__7BFYq headline_latestPrice__AYXu0">
                            <div>
                              <span class="headline_title__x1csO">
                                {isNaN(marketPriceref.current)
                                  ? ""
                                  : parseFloat(marketPriceref.current).toFixed(
                                      pairDetails?.liq_price_decimal
                                    )}
                              </span>
                              {pairTickerDetails?.price_change <= 0 ? (
                                <span class="headline_change__tGyxo text-danger ms-2">
                                  {isNaN(pairTickerDetails?.price_change)
                                    ? ""
                                    : parseFloat(
                                        pairTickerDetails?.price_change
                                      ).toFixed(pairDetails?.liq_price_decimal)}
                                </span>
                              ) : (
                                <span class="headline_change__tGyxo text-green ms-2">
                                  {isNaN(pairTickerDetails?.price_change)
                                    ? ""
                                    : parseFloat(
                                        pairTickerDetails?.price_change
                                      ).toFixed(pairDetails?.liq_price_decimal)}
                                </span>
                              )}
                            </div>
                            <div class="headline_etfDisplay__P4Hdv">
                              <span>
                                {isNaN(marketPriceref.current)
                                  ? ""
                                  : parseFloat(marketPriceref.current).toFixed(
                                      pairDetails?.liq_price_decimal
                                    )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="right">
                      <div class="tickerListContainer">
                        <div class="tickerList">
                          <div>
                            <div class="tickerItemLabel">
                              24h Change{" "}
                              <small className="small">
                                ({pairDetails.to_symbol})
                              </small>{" "}
                            </div>
                            <div class="tickerPriceText">
                              <span className="price_red">
                                {pairTickerDetails?.price_change <= 0 ? (
                                  <div className="tickerPriceText d-flex">
                                    {isNaN(pairTickerDetails?.price_change)
                                      ? ""
                                      : parseFloat(
                                          pairTickerDetails?.price_change
                                        ).toFixed(
                                          pairDetails?.liq_price_decimal
                                        )}

                                    {"  "}

                                    <span className="ml-2 pink_text color-buy">
                                      {parseFloat(
                                        pairTickerDetails?.change_percent
                                      ).toFixed(2)}{" "}
                                      %
                                    </span>
                                  </div>
                                ) : (
                                  <div className="tickerPriceText d-flex">
                                    {isNaN(pairTickerDetails?.price_change)
                                      ? ""
                                      : parseFloat(
                                          pairTickerDetails?.price_change
                                        ).toFixed(
                                          pairDetails?.liq_price_decimal
                                        )}
                                    <span className=" mx-2 pink_text red-green">
                                      {parseFloat(
                                        pairTickerDetails?.change_percent
                                      ).toFixed(2)}{" "}
                                      %
                                    </span>
                                  </div>
                                )}
                              </span>
                            </div>
                          </div>
                          <div>
                            <div class="tickerItemLabel">24h High </div>
                            <div class="tickerPriceText">
                              {" "}
                              {isNaN(pairTickerDetails?.highprice)
                                ? ""
                                : parseFloat(
                                    pairTickerDetails?.highprice
                                  ).toFixed(
                                    pairDetails?.liq_price_decimal
                                  )}{" "}
                              <small>{pairDetails.to_symbol}</small>
                            </div>
                          </div>
                          <div>
                            <div class="tickerItemLabel">24h Low</div>
                            <div class="tickerPriceText">
                              {" "}
                              {isNaN(pairTickerDetails?.lowprice)
                                ? ""
                                : parseFloat(
                                    pairTickerDetails?.lowprice
                                  ).toFixed(
                                    pairDetails?.liq_price_decimal
                                  )}{" "}
                              <small>{pairDetails.to_symbol}</small>
                            </div>
                          </div>
                          <div>
                            <div class="tickerItemLabel">
                              24h Volume{" "}
                              {/* <small>({pairDetails.from_symbol})</small> */}
                            </div>
                            <div class="tickerPriceText">
                              {isNaN(pairTickerDetails?.volume)
                                ? ""
                                : parseFloat(pairTickerDetails?.volume).toFixed(
                                    pairDetails?.liq_price_decimal
                                  )}
                            </div>
                          </div>
                          {/* <div>
                            <div class="tickerItemLabel">
                              24h Volume <small>({pairDetails.to_symbol})</small>
                            </div>
                            <div class="tickerPriceText">
                              {isNaN(pairTickerDetails?.volumequote)
                                ? ""
                                : parseFloat(
                                  pairTickerDetails?.volumequote
                                ).toFixed(pairDetails?.liq_price_decimal)}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="order_form">
                {/* <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      id="spottop-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#spottop"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Spot
                    </a>
                  </li>
                </ul> */}
                {/* <div class="tab-content tradeform" id="myTabContent">
                  <div
                    class="tab-pane fade in show active"
                    id="spottop"
                    role="tabpanel"
                    aria-labelledby="spottop-tab"
                  >
                    <ul
                      class="nav nav-tabs spot_market_tabs"
                      id="myTab"
                      role="tablist"
                    >
                      <li>
                        <a
                          class="nav-link active mx-2"
                          id="market-tab"
                          data-bs-toggle="tab"
                          onClick={() => myspotfunction("market")}
                          data-bs-target="#market"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Market
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class="nav-link  mx-2"
                          id="limit-tab"
                          onClick={() => myspotfunction("limit")}
                          data-bs-toggle="tab"
                          data-bs-target="#limit"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Limit
                        </a>
                      </li>

                      <li>
                        <a
                          class="nav-link mx-2"
                          id="spot-tab"
                          data-bs-toggle="tab"
                          onClick={() => myspotfunction("spot")}
                          data-bs-target="#spot"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Stop-limit
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div
                        id="limit"
                        className={`tab-pane fade ${
                          myspot === "limit" ? "show active" : ""
                        }`}
                        role="tabpanel"
                        aria-labelledby="limit-tab"
                      >
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form_trade">
                              <div className="avali">
                                <p>
                                  Avbl{" "}
                                  <span>
                                    {parseFloat(tobalance).toFixed(8)}{" "}
                                    {toCurrency}{" "}
                                  </span>
                                </p>
                              </div>
                              <div className="form_right">
                                <label>Price</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    // min="-1"
                                    className="form-control"
                                    placeholder="Price"
                                    name="price"
                                    // value={parseFloat(price).toFixed(
                                    //   pairDetails?.liq_price_decimal
                                    // )}
                                    value={price}
                                    onChange={handleChange}
                                    // onPaste={handlePaste}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <span>{toCurrency}</span>
                                </div>
                              </div>
                              <div className="form_right">
                                <label>Amount</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    // min="0"
                                    className="form-control"
                                    placeholder="Amount"
                                    name="amount"
                                    value={amount}
                                    onChange={handleChange}
                                    // onPaste={handlePaste}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <span>{fromCurrency}</span>
                                </div>
                              </div>
                              {!checkAuth ? (
                                ""
                              ) : (
                                <div className="slider_spacing">
                                  <Box>
                                    <Slider
                                      value={sliderValueref.current}
                                      step={25}
                                      marks
                                      name="buy"
                                      min={0}
                                      max={100}
                                      onChange={handleSliderChange}
                                      sx={{
                                        "& .MuiSlider-track": {
                                          backgroundColor: "#5841d8 ",
                                        },
                                        "& .MuiSlider-thumb": {
                                          backgroundColor: "#5841d8",
                                        },
                                      }}
                                    />
                                  </Box>
                                </div>
                              )}

                              <div className="form_right">
                                <label>Total</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Total"
                                    value={total}
                                  />
                                </div>
                              </div>
                              <div className="buy_BTN">
                                {!checkAuth ? (
                                  <Button
                                    className="buy_selecu "
                                    onClick={Movelogin}
                                  >
                                    <Link to="/login" className="text-white">
                                      Login to continue
                                    </Link>
                                  </Button>
                                ) : orderloaderref.current == true ? (
                                  <Button className="buy_selecu">
                                    Loading...
                                  </Button>
                                ) : (
                                  <Button
                                    className="buy_selecu"
                                    onClick={() => orderPlace("Limit", "buy")}
                                  >
                                    Buy
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form_trade">
                              <div className="avali">
                                <p>
                                  Avbl{" "}
                                  <span>
                                    {parseFloat(frombalance).toFixed(8)}{" "}
                                    {fromCurrency}{" "}
                                  </span>
                                </p>
                              </div>
                              <div className="form_right">
                                <label>Price</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    // min="0"
                                    className="form-control"
                                    placeholder="Price"
                                    name="sellprice"
                                    // value={parseFloat(sellprice).toFixed(
                                    //   pairDetails?.liq_price_decimal
                                    // )}
                                    value={sellprice}
                                    onChange={handleChange}
                                    // onPaste={handlePaste}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <span>{toCurrency}</span>
                                </div>
                              </div>
                              <div className="form_right">
                                <label>Amount</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    // min="0"
                                    className="form-control"
                                    placeholder="Amount"
                                    name="sellamount"
                                    value={sellamount}
                                    onChange={handleChange}
                                    // onPaste={handlePaste}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <span>{fromCurrency}</span>
                                </div>
                              </div>
                              {!checkAuth ? (
                                ""
                              ) : (
                                <div className="slider_spacing">
                                  <Box>
                                    <Slider
                                      value={sliderValue1ref.current}
                                      step={25}
                                      marks
                                      min={0}
                                      name="sell"
                                      max={100}
                                      onChange={handleSliderChange1}
                                      sx={{
                                        "& .MuiSlider-track": {
                                          backgroundColor: "#5841d8 ",
                                        },
                                        "& .MuiSlider-thumb": {
                                          backgroundColor: "#5841d8",
                                        },
                                      }}
                                    />
                                  </Box>
                                </div>
                              )}

                              <div className="form_right">
                                <label>Total</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Total"
                                    value={selltotal}
                                  />
                                </div>
                              </div>
                              <div className="buy_BTN">
                                {!checkAuth ? (
                                  <Button className="Sell" onClick={Movelogin}>
                                    <Link to="/login" className="text-white">
                                      Login to continue
                                    </Link>
                                  </Button>
                                ) : sellorderloaderref.current == true ? (
                                  <Button className="Sell">Loading...</Button>
                                ) : (
                                  <Button
                                    className="Sell"
                                    onClick={() =>
                                      sellorderPlace("Limit", "sell")
                                    }
                                  >
                                    Sell
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="market"
                        className={`tab-pane fade ${
                          myspot === "market" ? "show active" : ""
                        }`}
                        aria-labelledby="market-tab"
                      >
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form_trade">
                              <div className="avali">
                                <p>
                                  Avbl{" "}
                                  <span>
                                    {parseFloat(tobalance).toFixed(8)}{" "}
                                    {toCurrency}{" "}
                                  </span>
                                </p>
                              </div>
                              <div className="form_right">
                                <label>Price</label>
                                <div className="input_section">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Price"
                                    value={parseFloat(
                                      marketPriceref.current
                                    ).toFixed(pairDetails?.liq_price_decimal)}
                                    name="price"
                                    disabled
                                  />
                                  <span>{toCurrency}</span>
                                </div>
                              </div>
                              <div className="form_right">
                                <label>Amount</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    // min="0"
                                    className="form-control"
                                    placeholder="Amount"
                                    name="amount"
                                    value={amount}
                                    onChange={handleChange}
                                    // onPaste={handlePaste}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <span>{fromCurrency}</span>
                                </div>
                              </div>
                              {!checkAuth ? (
                                ""
                              ) : (
                                <div className="slider_spacing">
                                  <Box>
                                    <Slider
                                      value={sliderValue2ref.current}
                                      step={25}
                                      marks
                                      name="buy"
                                      min={0}
                                      max={100}
                                      onChange={handleSliderChange2}
                                      sx={{
                                        "& .MuiSlider-track": {
                                          backgroundColor: "#5841d8 ",
                                        },
                                        "& .MuiSlider-thumb": {
                                          backgroundColor: "#5841d8",
                                        },
                                      }}
                                    />
                                  </Box>
                                </div>
                              )}

                              <div className="form_right">
                                <label>Total</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Total"
                                    value={total}
                                  />
                                </div>
                              </div>

                              <div className="buy_BTN">
                                {!checkAuth ? (
                                  <Button
                                    className="buy_selecu"
                                    onClick={Movelogin}
                                  >
                                    <Link to="/login" className="text-white">
                                      Login to continue
                                    </Link>
                                  </Button>
                                ) : orderloaderref.current == true ? (
                                  <Button className="buy_selecu">
                                    Loading...
                                  </Button>
                                ) : (
                                  <Button
                                    className="buy_selecu"
                                    onClick={() => orderPlace("Market", "buy")}
                                  >
                                    Buy
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form_trade">
                              <div className="avali">
                                <p>
                                  Avbl{" "}
                                  <span>
                                    {parseFloat(frombalance).toFixed(8)}{" "}
                                    {fromCurrency}{" "}
                                  </span>
                                </p>
                              </div>
                              <div className="form_right">
                                <label>Price</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Price"
                                    value={parseFloat(
                                      marketPriceref.current
                                    ).toFixed(pairDetails?.liq_price_decimal)}
                                    name="sellprice"
                                    disabled
                                  />
                                  <span>{toCurrency}</span>
                                </div>
                              </div>
                              <div className="form_right">
                                <label>Amount</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Amount"
                                    name="sellamount"
                                    value={sellamount}
                                    onChange={handleChange}
                                    // onPaste={handlePaste}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <span>{fromCurrency}</span>
                                </div>
                              </div>
                              {!checkAuth ? (
                                ""
                              ) : (
                                <div className="slider_spacing">
                                  <Box>
                                    <Slider
                                      value={sliderValue3ref.current}
                                      step={25}
                                      marks
                                      min={0}
                                      name="sell"
                                      max={100}
                                      onChange={handleSliderChange3}
                                      sx={{
                                        "& .MuiSlider-track": {
                                          backgroundColor: "#5841d8 ",
                                        },
                                        "& .MuiSlider-thumb": {
                                          backgroundColor: "#5841d8",
                                        },
                                      }}
                                    />
                                  </Box>
                                </div>
                              )}
                              <div className="form_right">
                                <label>Total</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Total"
                                    value={selltotal}
                                  />
                                </div>
                              </div>
                              <div className="buy_BTN">
                                {!checkAuth ? (
                                  <Button className="Sell" onClick={Movelogin}>
                                    <Link to="/login" className="text-white">
                                      {" "}
                                      Login to continue
                                    </Link>
                                  </Button>
                                ) : sellorderloaderref.current == true ? (
                                  <Button className="Sell">Loading...</Button>
                                ) : (
                                  <Button
                                    className="Sell"
                                    onClick={() =>
                                      sellorderPlace("Market", "sell")
                                    }
                                  >
                                    Sell
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="stop"
                        className={`tab-pane fade ${
                          myspot === "spot" ? "show active" : ""
                        }`}
                        role="tabpanel"
                        aria-labelledby="spot-tab"
                      >
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form_trade">
                              <div className="avali">
                                <p>
                                  Avbl{" "}
                                  <span>
                                    {parseFloat(tobalance).toFixed(8)}{" "}
                                    {toCurrency}{" "}
                                  </span>
                                </p>
                              </div>
                              <div className="form_right">
                                <label>Price</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Limit"
                                    value={price}
                                    name="price"
                                    onChange={handleChange}
                                    // onPaste={handlePaste}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <span>{toCurrency}</span>
                                </div>
                              </div>
                              <div className="form_right">
                                <label>Stop Limit</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    // min="0"
                                    className="form-control"
                                    placeholder="Stop"
                                    value={stop_price}
                                    name="stop_price"
                                    onChange={handleChange}
                                    // onPaste={handlePaste}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <span>{toCurrency}</span>
                                </div>
                              </div>
                              <div className="form_right">
                                <label>Amount</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    // min="0"
                                    className="form-control"
                                    placeholder="Amount"
                                    name="amount"
                                    value={amount}
                                    onChange={handleChange}
                                    // onPaste={handlePaste}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <span>{fromCurrency}</span>
                                </div>
                              </div>
                              {!checkAuth ? (
                                ""
                              ) : (
                                <div className="slider_spacing">
                                  <Box>
                                    <Slider
                                      value={sliderValue4ref.current}
                                      step={25}
                                      marks
                                      name="buy"
                                      min={0}
                                      max={100}
                                      onChange={handleSliderChange4}
                                      sx={{
                                        "& .MuiSlider-track": {
                                          backgroundColor: "#5841d8 ",
                                        },
                                        "& .MuiSlider-thumb": {
                                          backgroundColor: "#5841d8",
                                        },
                                      }}
                                    />
                                  </Box>
                                </div>
                              )}
                              <div className="form_right">
                                <label>Total</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Total"
                                    value={total}
                                  />
                                </div>
                              </div>
                              <div className="buy_BTN">
                                {!checkAuth ? (
                                  <Button
                                    className="buy_selecu"
                                    onClick={Movelogin}
                                  >
                                    <Link to="/login" className="text-white">
                                      {" "}
                                      Login to continue
                                    </Link>
                                  </Button>
                                ) : orderloaderref.current == true ? (
                                  <Button className="buy_selecu">
                                    Loading...
                                  </Button>
                                ) : (
                                  <Button
                                    className="buy_selecu"
                                    onClick={() => orderPlace("Stop", "buy")}
                                  >
                                    Buy
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form_trade">
                              <div className="avali">
                                <p>
                                  Avbl{" "}
                                  <span>
                                    {parseFloat(frombalance).toFixed(8)}{" "}
                                    {fromCurrency}{" "}
                                  </span>
                                </p>
                              </div>
                              <div className="form_right">
                                <label>Price</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    // min="0"
                                    className="form-control"
                                    placeholder="Limit"
                                    value={sellprice}
                                    name="sellprice"
                                    onChange={handleChange}
                                    // onPaste={handlePaste}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <span>{toCurrency}</span>
                                </div>
                              </div>
                              <div className="form_right">
                                <label>Stop Limit</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    // min="0"
                                    className="form-control"
                                    autocomplete="off"
                                    placeholder="Stop"
                                    value={sellstop_price}
                                    name="sellstop_price"
                                    onChange={handleChange}
                                    // onPaste={handlePaste}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <span>{toCurrency}</span>
                                </div>
                              </div>

                              <div className="form_right">
                                <label>Amount</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    autocomplete="off"
                                    // min="0"
                                    className="form-control"
                                    placeholder="Amount"
                                    name="sellamount"
                                    value={sellamount}
                                    onChange={handleChange}
                                    // onPaste={handlePaste}
                                    // onKeyDown={handleKeyDown}
                                  />
                                  <span>{fromCurrency}</span>
                                </div>
                              </div>
                              {!checkAuth ? (
                                ""
                              ) : (
                                <div className="slider_spacing">
                                  <Box>
                                    <Slider
                                      value={sliderValue5ref.current}
                                      step={25}
                                      marks
                                      name="sell"
                                      min={0}
                                      max={100}
                                      onChange={handleSliderChange5}
                                      sx={{
                                        "& .MuiSlider-track": {
                                          backgroundColor: "#5841d8 ",
                                        },
                                        "& .MuiSlider-thumb": {
                                          backgroundColor: "#5841d8",
                                        },
                                      }}
                                    />
                                  </Box>
                                </div>
                              )}
                              <div className="form_right">
                                <label>Total</label>
                                <div className="input_section">
                                  <input
                                    type="number"
                                    autocomplete="off"
                                    className="form-control"
                                    placeholder="Total"
                                    value={selltotal}
                                  />
                                </div>
                              </div>
                              <div className="buy_BTN">
                                {!checkAuth ? (
                                  <Button className="Sell" onClick={Movelogin}>
                                    <Link to="/login" className="text-white">
                                      Login to continue
                                    </Link>
                                  </Button>
                                ) : sellorderloaderref.current == true ? (
                                  <Button className="Sell">Loading...</Button>
                                ) : (
                                  <Button
                                    className="Sell"
                                    onClick={() =>
                                      sellorderPlace("Stop", "sell")
                                    }
                                  >
                                    Sell
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="buy_sell_order_form">
                  <button
                    className={`buy_btn ${
                      buyFormref.current == true ? "active" : ""
                    }`}
                    onClick={() => selectOrder("buy")}
                  >
                    Buy
                  </button>
                  <button
                    className={`sell_btn ${
                      sellFormref.current == true ? "active" : ""
                    }`}
                    onClick={() => selectOrder("sell")}
                  >
                    Sell
                  </button>
                </div>
                {buyFormref.current && (
                  <div>
                    <div className="limit_market">
                      {" "}
                      <select
                        name="options"
                        id="limit_value"
                        onChange={handletypeChange}
                      >
                        <option value="Limit">Limit</option>
                        <option value="Market">Market</option>
                        <option value="Stop">Stop Limit</option>
                      </select>
                      {/* <Select
                            value={limitMarket}
                            options={stakingDays}
                            styles={colourStyles}
                            onChange={handletypeChange}
                          /> */}
                    </div>
                    <div className="available_blc">
                      <h4>Available Balance:</h4>
                      <p>
                        {" "}
                        <span>{parseFloat(tobalance).toFixed(8)} </span>
                        {toCurrency} <i class="ri-add-circle-fill"></i>
                      </p>
                    </div>
                    {myspotref.current == "Limit" ? (
                      <div className="price_input">
                        <p>Price</p>
                        <div className="price_input_items">
                          <input
                            type="number"
                            placeholder=""
                            name="price"
                            value={price}
                            onChange={handleChange}
                          />
                          <p>{toCurrency}</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {myspotref.current == "Market" ? (
                      <div className="price_input">
                        <p>Price</p>
                        <div className="price_input_items">
                          <input
                            type="number"
                            placeholder=""
                            name="price"
                            value={parseFloat(marketPriceref.current).toFixed(
                              pairDetails?.liq_price_decimal
                            )}
                            disabled
                          />
                          <p>{toCurrency}</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {myspotref.current == "Stop" ? (
                      <>
                        <div className="price_input">
                          <p>Limit</p>
                          <div className="price_input_items">
                            <input
                              type="number"
                              placeholder=""
                              name="price"
                              value={price}
                              onChange={handleChange}
                            />
                            <p>{toCurrency}</p>
                          </div>
                        </div>

                        <div className="price_input">
                          <p>Stop Limit</p>
                          <div className="price_input_items">
                            <input
                              type="number"
                              placeholder=""
                              name="stop_price"
                              value={stop_price}
                              onChange={handleChange}
                            />
                            <p>{toCurrency}</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="price_input mt-4">
                      <p>Amount</p>
                      <div className="price_input_items">
                        <input
                          type="number"
                          placeholder=""
                          name="amount"
                          value={amount}
                          onChange={handleChange}
                        />
                        <p>{fromCurrency}</p>
                      </div>
                      <div className="price_input_items mt-4">
                        <input type="number" placeholder="" value={total} />
                        <p>Total</p>
                      </div>
                    </div>
                    {checkAuth ? (
                      <div
                        class="btn-group btn-group-mini grp_buttons mt-4 mb-3"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <input
                          type="radio"
                          class="btn-check"
                          name="btnradio"
                          id="btnradio125"
                          autocomplete="off"
                          onClick={() => buy_sell_percentage("25")}
                        />
                        <label
                          class="btn btn-outline-success"
                          for="btnradio125"
                        >
                          25%
                        </label>
                        <input
                          type="radio"
                          class="btn-check"
                          name="btnradio"
                          id="btnradio250"
                          autocomplete="off"
                          onClick={() => buy_sell_percentage("50")}
                        />
                        <label
                          class="btn btn-outline-success"
                          for="btnradio250"
                        >
                          50%
                        </label>
                        <input
                          type="radio"
                          class="btn-check"
                          name="btnradio"
                          id="btnradio375"
                          autocomplete="off"
                          onClick={() => buy_sell_percentage("75")}
                        />
                        <label
                          class="btn btn-outline-success"
                          for="btnradio375"
                        >
                          75%
                        </label>
                        <input
                          type="radio"
                          class="btn-check"
                          name="btnradio"
                          id="btnradio3100"
                          autocomplete="off"
                          onClick={() => buy_sell_percentage("100")}
                        />
                        <label
                          class="btn btn-outline-success"
                          for="btnradio3100"
                        >
                          100%
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="buy_BTN trade_btn">
                      {!checkAuth ? (
                        <Button className="buy_selecu" onClick={Movelogin}>
                          <Link to="/login" className="text-white">
                            {" "}
                            Login to continue
                          </Link>
                        </Button>
                      ) : orderloaderref.current == true ? (
                        <Button className="buy_selecu">Loading...</Button>
                      ) : (
                        <Button
                          className="buy_selecu"
                          onClick={() => orderPlace(myspotref.current, "buy")}
                        >
                          Buy
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                {sellFormref.current && (
                  <div>
                    <div className="limit_market">
                      {" "}
                      <select
                        name="options"
                        id="limit_value"
                        onChange={handletypeChange}
                      >
                        <option value="Limit">Limit</option>
                        <option value="Market">Market</option>
                        <option value="Stop">Stop Limit</option>
                      </select>
                    </div>
                    <div className="available_blc">
                      <h4>Available Balance:</h4>
                      <p>
                        {" "}
                        <span>{frombalance} </span>
                        {fromcurrencyref.current}{" "}
                        <i class="ri-add-circle-fill"></i>
                      </p>
                    </div>
                    {myspotref.current == "Limit" ? (
                      <div className="price_input">
                        <p>Price</p>
                        <div className="price_input_items">
                          <input
                            type="number"
                            placeholder=""
                            name="sellprice"
                            value={sellprice}
                            onChange={handleChange}
                          />
                          <p>{toCurrency}</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {myspotref.current == "Market" ? (
                      <div className="price_input">
                        <p>Price</p>
                        <div className="price_input_items">
                          <input
                            type="number"
                            placeholder=""
                            name="sellprice"
                            value={parseFloat(marketPriceref.current).toFixed(
                              pairDetails?.liq_price_decimal
                            )}
                            disabled
                          />
                          <p>{toCurrency}</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {myspotref.current == "Stop" ? (
                      <>
                        <div className="price_input">
                          <p>Limit</p>
                          <div className="price_input_items">
                            <input
                              type="number"
                              placeholder=""
                              name="sellprice"
                              value={sellprice}
                              onChange={handleChange}
                            />
                            <p>{toCurrency}</p>
                          </div>
                        </div>

                        <div className="price_input">
                          <p>Stop Limit</p>
                          <div className="price_input_items">
                            <input
                              type="number"
                              placeholder=""
                              name="sellstop_price"
                              value={sellstop_price}
                              onChange={handleChange}
                            />
                            <p>{toCurrency}</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="price_input mt-4">
                      <p>Amount</p>
                      <div className="price_input_items">
                        <input
                          type="number"
                          placeholder=""
                          name="sellamount"
                          value={sellamount}
                          onChange={handleChange}
                        />
                        <p>{fromCurrency}</p>
                      </div>
                      <div className="price_input_items mt-4">
                        <input type="number" placeholder="" value={selltotal} />
                        <p>Total</p>
                      </div>
                    </div>
                    {checkAuth ? (
                      <div
                        class="btn-group btn-group-mini grp_buttons mt-4 mb-3"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <input
                          type="radio"
                          class="btn-check"
                          name="btnradio"
                          id="btnradio125"
                          autocomplete="off"
                          onClick={() => buy_sell_percentage1("25")}
                        />
                        <label
                          class="btn btn-outline-success"
                          for="btnradio125"
                        >
                          25%
                        </label>
                        <input
                          type="radio"
                          class="btn-check"
                          name="btnradio"
                          id="btnradio250"
                          autocomplete="off"
                          onClick={() => buy_sell_percentage1("50")}
                        />
                        <label
                          class="btn btn-outline-success"
                          for="btnradio250"
                        >
                          50%
                        </label>
                        <input
                          type="radio"
                          class="btn-check"
                          name="btnradio"
                          id="btnradio375"
                          autocomplete="off"
                          onClick={() => buy_sell_percentage1("75")}
                        />
                        <label
                          class="btn btn-outline-success"
                          for="btnradio375"
                        >
                          75%
                        </label>
                        <input
                          type="radio"
                          class="btn-check"
                          name="btnradio"
                          id="btnradio3100"
                          autocomplete="off"
                          onClick={() => buy_sell_percentage1("100")}
                        />
                        <label
                          class="btn btn-outline-success"
                          for="btnradio3100"
                        >
                          100%
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="buy_BTN trade_btn">
                      {!checkAuth ? (
                        <Button className="Sell" onClick={Movelogin}>
                          <Link to="/login" className="text-white">
                            Login to continue
                          </Link>
                        </Button>
                      ) : sellorderloaderref.current == true ? (
                        <Button className="Sell">Loading...</Button>
                      ) : (
                        <Button
                          className="Sell"
                          onClick={() =>
                            sellorderPlace(myspotref.current, "sell")
                          }
                        >
                          Sell
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="order_book">
                <div className="contant_scetion">
                  <div className="orderbook-header ">
                    <div class="orderbook-header-tips">
                      <button
                        data-bn-type="button"
                        data-testid="defaultModeButton"
                        class=" css-sz6ky9"
                        onClick={Fullorderbook}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          class="css-3kwgah"
                        >
                          <path d="M4 4h7v7H4V4z" fill="#F6465D"></path>
                          <path d="M4 13h7v7H4v-7z" fill="#0ECB81"></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                            fill="#dddddd"
                          ></path>
                        </svg>
                      </button>
                      <button
                        data-bn-type="button"
                        data-testid="buyModeButton"
                        class=" css-1meiumy"
                        onClick={bidorderbook}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          class="css-3kwgah"
                        >
                          <path d="M4 4h7v16H4V4z" fill="#0ECB81"></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                            fill="#dddddd"
                          ></path>
                        </svg>
                      </button>
                      <button
                        data-bn-type="button"
                        data-testid="sellModeButton"
                        class=" css-1meiumy"
                        onClick={askorderbook}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          class="css-3kwgah"
                        >
                          <path d="M4 4h7v16H4V4z" fill="#F6465D"></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                            fill="#dddddd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="market_order">
                    <article>
                      <section class="mb-2">
                        <table width="100%">
                          <tr>
                            <th class="price">
                              Price({pairDetails.to_symbol})
                            </th>
                            <th>Amount({pairDetails.from_symbol})</th>
                            <th>Total</th>
                          </tr>
                        </table>
                      </section>

                      {orderbooksloaderref.current == true ? (
                        <i class="fa-solid fa-spinner fa-spin text-center"></i>
                      ) : (
                        <>
                          <section class="side" id="asks">
                            <table width="100%" className="green_content">
                              {orderbookLoader == false ? (
                                ""
                              ) : orderbookaskref.current.length > 0 ? (
                                orderbookaskref.current
                                  .slice(0, 12)
                                  .map((ask, i) => {
                                    return (
                                      <tr data-width="70">
                                        <td
                                          width="25%"
                                          style={{ cursor: "pointer" }}
                                          className="color-buy price sell priceclick"
                                          onClick={() => addPrice(ask[0])}
                                        >
                                          {ask[0]}
                                        </td>
                                        <td width="25%">{ask[1]}</td>
                                        <td width="25%">{ask[2]}</td>
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  {" "}
                                  <td colSpan="3" className="text-center py-3">
                                    {" "}
                                    Data not found!
                                  </td>{" "}
                                </tr>
                              )}
                            </table>
                          </section>
                          {orderbookdivider == false ? (
                            ""
                          ) : (
                            <section class="divider">
                              <div class="current-price">
                                {parseFloat(marketPriceref.current).toFixed(
                                  pairDetails?.liq_price_decimal
                                )}
                              </div>
                            </section>
                          )}
                          <section class="side bids">
                            <table width="100%">
                              {orderbookLoaderbid == false ? (
                                ""
                              ) : orderbookbidref.current.length > 0 ? (
                                orderbookbidref.current
                                  .slice(0, 12)
                                  .map((bid, i) => {
                                    return (
                                      <tr>
                                        <td
                                          style={{ cursor: "pointer" }}
                                          width="25%"
                                          className="red-green price buy priceclick"
                                          onClick={() => addPrice(bid[0])}
                                        >
                                          {bid[0]}
                                        </td>
                                        <td width="25%">{bid[1]}</td>
                                        <td width="25%">{bid[2]}</td>
                                      </tr>
                                    );
                                  })
                              ) : (
                                <tr>
                                  {" "}
                                  <td colSpan="3" className="text-center py-3">
                                    {" "}
                                    Data not found!
                                  </td>{" "}
                                </tr>
                              )}
                            </table>
                          </section>
                        </>
                      )}
                    </article>
                  </div>
                </div>
              </div>

              <div className="chart_trade">
                <div id="tv_chart_container"></div>
              </div>

              <div className="trades pb-0">
                <div className="form_seldect__pair pt-0 tradeform">
                  <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link active"
                        id="home-tab"
                        onClick={() => mytradesfunction("home")}
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Market Trades
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="profile-tab"
                        onClick={() => mytradesfunction("menu2")}
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        My Trades
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content pair_details" id="myTabContent">
                    <div
                      id="home"
                      role="tabpanel"
                      className={`tab-pane fade ${
                        mytrades === "home" ? "show active" : ""
                      }`}
                      aria-labelledby="home-tab"
                    >
                      <div class="fixTableHead mt-2">
                        <table>
                          <thead>
                            <tr>
                              <th>Pair ({pairDetails.to_symbol})</th>
                              <th className="text-right">
                                Price ({pairDetails.from_symbol})
                              </th>
                              <th className="text-right">Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!checkAuth ? (
                              <tr>
                                {" "}
                                <td colSpan="3">
                                  <Button
                                    className="Butn_header123 my-4 d-block mx-auto"
                                    onClick={Movelogin}
                                  >
                                    <Link to="/login" className="text-white">
                                      Login to continue
                                    </Link>
                                  </Button>{" "}
                                </td>{" "}
                              </tr>
                            ) : marketTrade.length > 0 ? (
                              marketTrade.map((item, i) => {
                                return (
                                  <tr className="position_rel_over">
                                    {item.tradeType == "buy" ? (
                                      <td>
                                        <span className="color-buy">
                                          {" "}
                                          {item.price}{" "}
                                        </span>
                                      </td>
                                    ) : (
                                      <td>
                                        <span className="red-green">
                                          {" "}
                                          {item.price}{" "}
                                        </span>
                                      </td>
                                    )}
                                    <td className="text-right">
                                      {item.amount}{" "}
                                    </td>
                                    <td className="text-right">
                                      {moment(item.time).format("hh:mm:ss")}{" "}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="3" className="text-center mt-4">
                                  No Market Trades
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      className={`tab-pane fade ${
                        mytrades === "menu2" ? "show active" : ""
                      }`}
                    >
                      <div class="fixTableHead mt-2">
                        <table>
                          <thead>
                            <tr>
                              <th>Pair ({pairDetails.to_symbol})</th>
                              <th className="text-right">
                                Price ({pairDetails.from_symbol})
                              </th>
                              <th className="text-right">Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!checkAuth ? (
                              <tr>
                                {" "}
                                <td colSpan="3">
                                  <Button
                                    className="Butn_header123 my-4 d-block mx-auto"
                                    onClick={Movelogin}
                                  >
                                    <Link to="/login" className="text-white">
                                      Login to continue
                                    </Link>
                                  </Button>{" "}
                                </td>{" "}
                              </tr>
                            ) : tradeHistoryData.length > 0 ? (
                              tradeHistoryData.map((item, i) => {
                                return (
                                  <tr className="position_rel_over">
                                    {item.tradeType == "buy" ? (
                                      <td>
                                        <span className="color-buy">
                                          {" "}
                                          {item.askPrice}{" "}
                                        </span>
                                      </td>
                                    ) : (
                                      <td>
                                        <span className="red-green">
                                          {" "}
                                          {item.askPrice}{" "}
                                        </span>
                                      </td>
                                    )}
                                    <td className="text-right">
                                      {item.askAmount}{" "}
                                    </td>
                                    <td className="text-right">
                                      {moment(item.created_at).format(
                                        "hh:mm:ss"
                                      )}{" "}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="3" className="text-center mt-4">
                                  No Market Trades
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="basictable tradeform">
                <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active pr-2"
                      id="openorder-tab"
                      onClick={() => myhistoryfunction("openorder")}
                      data-bs-toggle="tab"
                      data-bs-target="#openorder"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Open Orders
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link px-2"
                      id="order-tab"
                      onClick={() => callOrdehistory("order")}
                      data-bs-toggle="tab"
                      data-bs-target="#order"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Order History
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link px-2"
                      id="cancel-tab"
                      onClick={() => callCancelOrder("cancel")}
                      data-bs-toggle="tab"
                      data-bs-target="#cancel"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Cancel Orders
                    </a>
                  </li>
                </ul>
                <div
                  class="tab-content pair_details pading_oedar"
                  id="myTabContent"
                >
                  {/* ==========ACTIVE OREDERS========== */}

                  <div
                    id="OpenOrders"
                    className={`tab-pane fade ${
                      myhistory === "openorder" ? "show active" : ""
                    }`}
                    role="tabpanel"
                    aria-labelledby="openorder-tab"
                  >
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="tabel_bg">
                            <th className="starts">Date</th>
                            <th>Pair </th>
                            <th>Price </th>
                            <th>Side </th>
                            <th>Order Type </th>
                            <th> Amount </th>
                            <th>Total </th>
                            <th className="ends">Cancel </th>
                          </tr>
                        </thead>
                        <tbody>
                          {activeOrderDatas.length > 0 ? (
                            activeOrderDatas.map((item, i) => {
                              var dates = moment(item.createddate).format(
                                "DD-MM-YYYY hh:mm:ss"
                              );
                              return (
                                <tr className="history_tabel_bottom_border">
                                  <td>{dates} </td>
                                  <td>{item.pairName} </td>
                                  <td>
                                    {item.tradeType == "buy" ? (
                                      <span className="text-green">
                                        {parseFloat(item.price).toFixed(8)}{" "}
                                      </span>
                                    ) : (
                                      <span className="text-red">
                                        {parseFloat(item.price).toFixed(8)}{" "}
                                      </span>
                                    )}
                                  </td>
                                  <td> {item.tradeType} </td>
                                  <td> {item.ordertype} </td>
                                  <td>
                                    {" "}
                                    {parseFloat(item.filledAmount).toFixed(
                                      8
                                    )}{" "}
                                  </td>
                                  <td>
                                    {parseFloat(
                                      item.filledAmount * item.price
                                    ).toFixed(8)}
                                  </td>
                                  <td>
                                    <Button
                                      className="btn btn-primary-alta connectBtn cancel-red"
                                      onClick={() => orderCancel(item)}
                                    >
                                      Cancel
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              {" "}
                              {!checkAuth ? (
                                <td colSpan="8">
                                  <Button
                                    className="Butn_header123 my-4 d-block mx-auto"
                                    onClick={Movelogin}
                                  >
                                    <Link to="/login" className="text-white">
                                      Login to continue
                                    </Link>
                                  </Button>{" "}
                                </td>
                              ) : (
                                <td colSpan="8" className="text-center no_data">
                                  {" "}
                                  <div className="empty_data_img">
                                    <img
                                      src={require("../images/No-data.png")}
                                    />
                                  </div>
                                  No found open orders!
                                </td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="pagination">
                      <Stack spacing={2}>
                        <Pagination
                          count={Math.ceil(totalactive / 5)}
                          page={currentPage}
                          onChange={activePageChange}
                          renderItem={(item) => (
                            <PaginationItem
                              slots={{
                                previous: ArrowBackIcon,
                                next: ArrowForwardIcon,
                              }}
                              {...item}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                  </div>

                  {/* ==========OREDERS HISTORY========== */}

                  <div
                    id="OrderHistory"
                    className={`tab-pane fade ${
                      myhistory === "order" ? "show active" : ""
                    }`}
                    role="tabpanel"
                    aria-labelledby="order-tab"
                  >
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="tabel_bg">
                            <th className="starts">Date</th>
                            <th>Pair </th>
                            <th>Type </th>
                            <th>Price </th>
                            <th>Amount </th>
                            <th className="ends">Total </th>
                          </tr>
                        </thead>

                        <tbody>
                          {tradeHistoryData.length > 0 ? (
                            tradeHistoryData.map((item, i) => {
                              var datesHis = moment(item.created_at).format(
                                "DD-MM-YYYY hh:mm:ss"
                              );
                              return (
                                <tr className="history_tabel_bottom_border">
                                  <td>{datesHis} </td>
                                  <td>{item.pair} </td>
                                  <td>{item.type} </td>
                                  <td>
                                    {item.type == "buy" ? (
                                      <span className="text-green">
                                        {" "}
                                        {item.askPrice}{" "}
                                      </span>
                                    ) : (
                                      <span className="text-red">
                                        {" "}
                                        {item.askPrice}{" "}
                                      </span>
                                    )}
                                  </td>
                                  <td> {item.askAmount} </td>
                                  <td>{item.total}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              {" "}
                              {!checkAuth ? (
                                <td colSpan="6">
                                  <Button
                                    className="Butn_header123 my-4 d-block mx-auto"
                                    onClick={Movelogin}
                                  >
                                    <Link to="/login" className="text-white">
                                      Login to continue
                                    </Link>
                                  </Button>{" "}
                                </td>
                              ) : (
                                <td colSpan="6" className="text-center no_data">
                                  {" "}
                                  <div className="empty_data_img">
                                    <img
                                      src={require("../images/No-data.png")}
                                    />
                                  </div>
                                  No Records Found!
                                </td>
                              )}{" "}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="pagination">
                      <Stack spacing={2}>
                        <Pagination
                          count={Math.ceil(totalHist / 5)}
                          page={currentPageHis}
                          onChange={orderhistoryactive}
                          renderItem={(item) => (
                            <PaginationItem
                              slots={{
                                previous: ArrowBackIcon,
                                next: ArrowForwardIcon,
                              }}
                              {...item}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                  </div>

                  {/* ==========OREDERS Book========== */}

                  <div
                    id="OrderBook"
                    className={`tab-pane fade ${
                      myhistory === "cancel" ? "show active" : ""
                    }`}
                    aria-labelledby="cancel-tab"
                  >
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr className="tabel_bg">
                            <th className="starts">Date</th>
                            <th>Pair</th>
                            <th>Side </th>
                            <th>Type </th>
                            <th>Price </th>
                            <th>Amount </th>
                            <th className="ends">Total </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cancelOrders.length > 0 ? (
                            cancelOrders.map((item, i) => {
                              var total =
                                item.ordertype == "Stop"
                                  ? +item.filledAmount * +item.stoporderprice
                                  : +item.filledAmount * +item.price;
                              return (
                                <tr className="history_tabel_bottom_border">
                                  <td>{item.createddate} </td>
                                  <td>{item.pairName} </td>
                                  <td>
                                    {item.tradeType == "buy" ? (
                                      <span className="text-green"> Buy </span>
                                    ) : (
                                      <span className="text-red"> Sell </span>
                                    )}
                                  </td>
                                  <td>{item.ordertype} </td>
                                  <td>
                                    <span className="text-green">
                                      {/* {item.ordertype == "Stop"
                                          ? parseFloat(
                                              item.stoporderprice
                                            ).toFixed(pairDetails?.liq_price_decimal)
                                          : parseFloat(item.price).toFixed(
                                              4
                                            )}{" "} */}
                                      {item.ordertype == "Stop"
                                        ? parseFloat(
                                            item.stoporderprice
                                          ).toFixed(8)
                                        : parseFloat(item.price).toFixed(
                                            8
                                          )}{" "}
                                    </span>
                                  </td>
                                  <td> {item.amount} </td>
                                  <td>
                                    {/* {parseFloat(total).toFixed(pairDetails?.liq_price_decimal)} */}
                                    {parseFloat(total).toFixed(8)}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              {" "}
                              {!checkAuth ? (
                                <td colSpan="7">
                                  <Button
                                    className="Butn_header123 my-4 d-block mx-auto"
                                    onClick={Movelogin}
                                  >
                                    <Link to="/login" className="text-white">
                                      Login to continue
                                    </Link>
                                  </Button>{" "}
                                </td>
                              ) : (
                                <td colSpan="7" className="text-center no_data">
                                  {" "}
                                  <div className="empty_data_img">
                                    <img
                                      src={require("../images/No-data.png")}
                                    />
                                  </div>
                                  No Cancel order found!{" "}
                                </td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="pagination">
                      <Stack spacing={2}>
                        <Pagination
                          count={Math.ceil(totalcan / 5)}
                          page={currentPagecan}
                          onChange={cancelPageChange}
                          renderItem={(item) => (
                            <PaginationItem
                              slots={{
                                previous: ArrowBackIcon,
                                next: ArrowForwardIcon,
                              }}
                              {...item}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                  </div>
                </div>
              </div>

              <PriceFooter />
            </>
          )}
        </div>
      </main>
    </div>
  );
}
export default Home;
