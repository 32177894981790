import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "./Home_header.js";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import { Dropdown } from "semantic-ui-react";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";

const PostAd = () => {
  const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
  const [allcryptoCurrencies, setallCryptoCurrencies] = useState([]);
  const [siteLoader, setSiteLoader] = useState(false);
  const [btnloader, setbtnLoader] = useState(false);

  const [fiatCurrencies, setFiatCurrencies] = useState([]);
  let navigate = useNavigate();

  const [formData, setFormData, formDataref] = useState({
    cryptoCurrency: "",
    fiatCurrency: "",
    quantity: "",
    minQuantity: "",
    maxQuantity: "",
    price: "",
    lowestOrderPrice: "",
    higeshOrderPrice: "",
    preferredPayment: "All Payment",
    paymentTime: "15",
    termsAccepted: false,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getCurrencies();
    getp2pstatus();
  }, []);

  const getp2pstatus = async () => {
    try {
      setSiteLoader(true);
      const data = {
        apiUrl: apiService.getP2Pstatus,
      };

      const response = await getMethod(data);
      setSiteLoader(false);

      console.log(response, "iikhikhnikki");

      if (response.p2pstatus == "Deactive") {
        navigate("/p2p");
      }
    } catch (err) {
      console.error("Error fetching currencies:", err);
    }
  };

  const getCurrencies = async () => {
    try {
      setSiteLoader(true);
      const data = {
        apiUrl: apiService.getP2Pcurrency,
      };

      const response = await getMethod(data);
      setSiteLoader(false);

      if (response.status) {
        setallCryptoCurrencies(response.data);
        const cryptos = response.data
          .filter((currency) => currency.coinType === "1")
          .map((currency) => ({
            value: currency.currencySymbol,
            text: currency.currencyName,
            image: {
              avatar: true,
              src: currency.Currency_image,
            },
          }));

        const fiat = response.data
          .filter((currency) => currency.coinType === "2")
          .map((currency) => ({
            value: currency.currencySymbol,
            text: currency.currencyName,
            image: {
              avatar: true,
              src: currency.Currency_image,
            },
          }));
        setCryptoCurrencies(cryptos);
        setFiatCurrencies(fiat);
      } else {
        setCryptoCurrencies([]);
        setFiatCurrencies([]);
      }
    } catch (err) {
      console.error("Error fetching currencies:", err);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value.trim(),
    }));

    validateForm(formDataref.current);
  };

  const validateForm = (formData) => {
    const newErrors = {};

    if (!formData.cryptoCurrency)
      newErrors.cryptoCurrency = "Please select a cryptocurrency.";
    if (!formData.fiatCurrency)
      newErrors.fiatCurrency = "Please select a fiat currency.";
    if (!formData.quantity) newErrors.quantity = "Quantity is required.";
    if (!formData.minQuantity)
      newErrors.minQuantity = "Minimum quantity is required.";
    if (!formData.maxQuantity)
      newErrors.maxQuantity = "Maximum quantity is required.";
    if (!formData.price) newErrors.price = "Price is required.";
    if (!formData.lowestOrderPrice)
      newErrors.lowestOrderPrice = "Lowest order price is required.";

    // Check that minQuantity is less than or equal to maxQuantity
    if (
      formData.minQuantity &&
      formData.maxQuantity &&
      Number(formData.minQuantity) > Number(formData.maxQuantity)
    ) {
      newErrors.quantityRange =
        "Minimum quantity must be less than or equal to maximum quantity.";
    }

    // Check that minQuantity and maxQuantity are less than or equal to quantity
    if (
      formData.minQuantity &&
      formData.quantity &&
      Number(formData.minQuantity) > Number(formData.quantity)
    ) {
      newErrors.minQuantity =
        "Minimum quantity must be less than or equal to quantity.";
    }
    if (
      formData.maxQuantity &&
      formData.quantity &&
      Number(formData.maxQuantity) > Number(formData.quantity)
    ) {
      newErrors.maxQuantity =
        "Maximum quantity must be less than or equal to quantity.";
    }

    if (!formData.termsAccepted)
      newErrors.termsAccepted = "You must accept the terms and conditions.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e, type) => {
    setbtnLoader(true);

    console.log(type, "type");
    e.preventDefault();

    formData["orderType"] = type;
    formData["firstCurrency"] = formDataref.current.cryptoCurrency;

    if (validateForm(formDataref.current)) {
      console.log("Form submitted:", formData);

      var data = {
        apiUrl: apiService.p2pOrder,
        payload: formDataref.current,
      };

      var resp = await postMethod(data);
      setbtnLoader(false);

      console.log(resp, "resp");

      if (resp.status) {
        toast.success(resp.Message);
        navigate("/p2p");
        setFormData({});
      } else {
        navigate("/p2p");
        setFormData({});
        toast.error(resp.Message);
        // console.log("error response====", resp);
        // if (resp.bank) {
        //   toast.error(resp.Message);
        //   navigate("/p2pp2p");
        //   setFormData({});
        // } else {

        // }
      }

      // Here you can send the form data to your API
    }
  };

  const [exchangeRate, setExchangeRate, exchangeRateref] = useState(0); // New state for exchange rate
  const fetchExchangeRate = async (cryptoCurrency, fiatCurrency) => {
    try {
      const apiUrl = `https://min-api.cryptocompare.com/data/pricemulti?fsyms=${cryptoCurrency}&tsyms=${fiatCurrency}&api_key=93e3c5b6fe23291d2114acf508c57635e90100074cf42266f20cd231e5f8e854`;
      const response = await axios.get(apiUrl);

      if (response.data) {
        const pricevalue = response.data[cryptoCurrency][fiatCurrency];
        setExchangeRate(pricevalue);

        setFormData((prevData) => ({
          ...prevData,
          lowestOrderPrice: pricevalue,
          higeshOrderPrice: pricevalue,
          price: pricevalue,
        }));

        console.log(pricevalue);
      } else {
        setExchangeRate(null);
      }
    } catch (err) {
      console.error("Error fetching exchange rate:", err);
      setExchangeRate(null);
    }
  };

  useEffect(() => {
    if (formData.cryptoCurrency && formData.fiatCurrency) {
      fetchExchangeRate(formData.cryptoCurrency, formData.fiatCurrency);

      const cryptos = allcryptoCurrencies.filter(
        (currency) => currency.currencySymbol === formData.cryptoCurrency
      );
      const Fiat = allcryptoCurrencies.filter(
        (currency) => currency.currencySymbol === formData.fiatCurrency
      );

      // Check if cryptos[0] and Fiat[0] exist before accessing _id
      formData["fromcurrency"] = cryptos.length > 0 ? cryptos[0]._id : "";
      formData["tocurrency"] = Fiat.length > 0 ? Fiat[0]._id : "";
      fiat_price();
    }
  }, [formData.cryptoCurrency, formData.fiatCurrency]);

  const fiat_price = async () => {
    var payload = {
      fromCurrency: formData.cryptoCurrency,
      toCurrency: formData.fiatCurrency,
    };

    var data = {
      apiUrl: apiService.fetch_price,
      payload: payload,
    };

    var resp = await postMethod(data);
    console.log(resp.data, "fiat price -=-=-resp=-=-");

    formData.lowestOrderPrice = resp.data.lowprice
      ? resp.data.lowprice
      : formDataref.current.price;
    formData.higeshOrderPrice = resp.data.highprice
      ? resp.data.highprice
      : formDataref.current.price;

    if (resp) {
      // sethighprice(high);
      // setlowprice(low);
    }
  };

  return (
    <>
      <section>
        <Header />
      </section>
      <div>
        <div className="Verification_postad">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mt-5">
                  <h6>
                    <Link to="/p2p" className="back_white">
                      <i className="fa-solid fa-arrow-left-long mr-3"></i> Back
                    </Link>
                  </h6>

                  <div className="row justify-content-center">
                    <div className="col-lg-7 post-ad-card mt-4">
                      <div className="post-ad-title">Post Ad</div>

                      <form>
                        <div
                          className="nav nav-tabs nav_bottom_line"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            className="nav-link nav-das active"
                            id="nav-buy-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-buy"
                            type="button"
                            role="tab"
                            aria-controls="nav-buy"
                            aria-selected="true"
                          >
                            I want to buy
                          </button>
                          <button
                            className="nav-link nav-das"
                            id="sell-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#sell-profile"
                            type="button"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                          >
                            I want to sell
                          </button>
                        </div>

                        <div className="tab-content" id="nav-tabContent">
                          {/* Buy Tab Content */}
                          <div
                            className="tab-pane fade show active"
                            id="nav-buy"
                            role="tabpanel"
                            aria-labelledby="nav-buy-tab"
                            tabindex="0"
                          >
                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Crypto Currency
                              </h6>
                              <Dropdown
                                inline
                                placeholder="Please Select The Crypto"
                                options={cryptoCurrencies}
                                value={formData.cryptoCurrency}
                                onChange={(e, { value }) =>
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    cryptoCurrency: value,
                                  }))
                                }
                                className="ad-input-field"
                              />
                              {errors.cryptoCurrency && (
                                <p className="errorcss">
                                  {errors.cryptoCurrency}
                                </p>
                              )}
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Fiat Currency
                              </h6>
                              <Dropdown
                                inline
                                placeholder="Please Select The Fiat"
                                options={fiatCurrencies}
                                value={formData.fiatCurrency}
                                onChange={(e, { value }) =>
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    fiatCurrency: value,
                                  }))
                                }
                                className="ad-input-field"
                              />
                              {errors.fiatCurrency && (
                                <p className="errorcss">
                                  {errors.fiatCurrency}
                                </p>
                              )}
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">Quantity</h6>
                              <input
                                type="number"
                                name="quantity"
                                value={formData.quantity}
                                onChange={(e) => {
                                  // Allow only numbers and limit the length to 10 digits
                                  const value = e.target.value;
                                  if (
                                    value.length <= 10 &&
                                    /^[0-9]*$/.test(value)
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                className="ad-input-field"
                                placeholder="Enter the Quantity"
                              />

                              {errors.quantity && (
                                <p className="errorcss">{errors.quantity}</p>
                              )}
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Minimum Quantity
                              </h6>
                              <input
                                type="number"
                                name="minQuantity"
                                value={formData.minQuantity}
                                onChange={(e) => {
                                  // Allow only numbers and limit the length to 10 digits
                                  const value = e.target.value;
                                  if (
                                    value.length <= 10 &&
                                    /^[0-9]*$/.test(value)
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                className="ad-input-field"
                                placeholder="Enter the Minimum Quantity"
                              />
                              {errors.minQuantity && (
                                <p className="errorcss">{errors.minQuantity}</p>
                              )}
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Maximum Quantity
                              </h6>
                              <input
                                type="number"
                                name="maxQuantity"
                                value={formData.maxQuantity}
                                onChange={(e) => {
                                  // Allow only numbers and limit the length to 10 digits
                                  const value = e.target.value;
                                  if (
                                    value.length <= 10 &&
                                    /^[0-9]*$/.test(value)
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                className="ad-input-field"
                                placeholder="Enter the Maximum Quantity"
                              />
                              {errors.maxQuantity && (
                                <p className="errorcss">{errors.maxQuantity}</p>
                              )}
                              {errors.quantityRange && (
                                <p className="errorcss">
                                  {errors.quantityRange}
                                </p>
                              )}
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">Price</h6>
                              <input
                                type="number"
                                name="price"
                                value={formData.price}
                                onChange={(e) => {
                                  // Allow only numbers and limit the length to 10 digits
                                  const value = e.target.value;
                                  if (
                                    value.length <= 20 &&
                                    /^[0-9]*$/.test(value)
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                className="ad-input-field"
                                placeholder="Enter the Price"
                              />
                              {errors.price && (
                                <p className="errorcss">{errors.price}</p>
                              )}
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Lowest Order Price
                              </h6>
                              <input
                                readOnly
                                type="number"
                                name="lowestOrderPrice"
                                value={formData.lowestOrderPrice}
                                onChange={(e) => {
                                  // Allow only numbers and limit the length to 10 digits
                                  const value = e.target.value;
                                  if (
                                    value.length <= 20 &&
                                    /^[0-9]*$/.test(value)
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                className="ad-input-field"
                                placeholder="Enter the Lowest Order Price"
                              />
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Preferred Payment
                              </h6>
                              <select
                                className="ad-input-field prefer-select"
                                name="preferredPayment"
                                value={formData.preferredPayment}
                                onChange={handleChange}
                              >
                                <option value="All Payment">All Payment</option>
                                <option value="IMPS">IMPS</option>
                                <option value="UPID">UPI</option>
                                <option value="Paytm">PAYTM</option>
                                <option value="BankTransfer">
                                  Account Transfer
                                </option>
                              </select>
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Payment Time
                              </h6>
                              <select
                                className="ad-input-field prefer-select"
                                name="paymentTime"
                                value={formData.paymentTime}
                                onChange={handleChange}
                              >
                                <option value="15">15 Minutes</option>
                                <option value="30">30 Minutes</option>
                                <option value="45">45 Minutes</option>
                                <option value="60">60 Minutes</option>
                                <option value="90">90 Minutes</option>
                              </select>
                            </div>

                            <div className="my-4">
                              <div className="terms">
                                <div className="checkbox-container">
                                  <input
                                    id="custom-checkbox"
                                    type="checkbox"
                                    name="termsAccepted"
                                    checked={formData.termsAccepted}
                                    onChange={handleChange}
                                    className="input-field regular_checkbox"
                                  />
                                  <label htmlFor="custom-checkbox"></label>
                                </div>
                                <label
                                  htmlFor="custom-checkbox"
                                  className="terms-check "
                                >
                                  I have read and agree to the
                                  <Link to="/" className="text-yellow">
                                    {" "}
                                    Terms{" "}
                                  </Link>{" "}
                                  and{" "}
                                  <Link to="/" className="text-yellow">
                                    {" "}
                                    Conditions
                                  </Link>
                                </label>
                              </div>
                            </div>

                            {errors.termsAccepted && (
                              <p className="errorcss">{errors.termsAccepted}</p>
                            )}

                            <div className="Submit">
                              {btnloader == true ? (
                                <button type="submit">Loading</button>
                              ) : (
                                <button
                                  type="submit"
                                  onClick={(e) => handleSubmit(e, "buy")}
                                >
                                  Post Ad
                                </button>
                              )}
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="sell-profile"
                            role="tabpanel"
                            aria-labelledby="sell-profile-tab"
                            tabindex="0"
                          >
                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Crypto Currency
                              </h6>
                              <Dropdown
                                inline
                                placeholder="Please Select The Crypto"
                                options={cryptoCurrencies}
                                value={formData.cryptoCurrency}
                                onChange={(e, { value }) =>
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    cryptoCurrency: value,
                                  }))
                                }
                                className="ad-input-field"
                              />
                              {errors.cryptoCurrency && (
                                <p className="errorcss">
                                  {errors.cryptoCurrency}
                                </p>
                              )}
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Fiat Currency
                              </h6>
                              <Dropdown
                                inline
                                placeholder="Please Select The Fiat"
                                options={fiatCurrencies}
                                value={formData.fiatCurrency}
                                onChange={(e, { value }) =>
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    fiatCurrency: value,
                                  }))
                                }
                                className="ad-input-field"
                              />
                              {errors.fiatCurrency && (
                                <p className="errorcss">
                                  {errors.fiatCurrency}
                                </p>
                              )}
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">Quantity</h6>
                              <input
                                type="number"
                                name="quantity"
                                value={formData.quantity}
                                onChange={(e) => {
                                  // Allow only numbers and limit the length to 10 digits
                                  const value = e.target.value;
                                  if (
                                    value.length <= 10 &&
                                    /^[0-9]*$/.test(value)
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                className="ad-input-field"
                                placeholder="Enter the Quantity"
                              />
                              {errors.quantity && (
                                <p className="errorcss">{errors.quantity}</p>
                              )}
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Minimum Quantity
                              </h6>
                              <input
                                type="number"
                                name="minQuantity"
                                value={formData.minQuantity}
                                onChange={(e) => {
                                  // Allow only numbers and limit the length to 10 digits
                                  const value = e.target.value;
                                  if (
                                    value.length <= 10 &&
                                    /^[0-9]*$/.test(value)
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                className="ad-input-field"
                                placeholder="Enter the Minimum Quantity"
                              />
                              {errors.minQuantity && (
                                <p className="errorcss">{errors.minQuantity}</p>
                              )}
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Maximum Quantity
                              </h6>
                              <input
                                type="number"
                                name="maxQuantity"
                                value={formData.maxQuantity}
                                onChange={(e) => {
                                  // Allow only numbers and limit the length to 10 digits
                                  const value = e.target.value;
                                  if (
                                    value.length <= 10 &&
                                    /^[0-9]*$/.test(value)
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                className="ad-input-field"
                                placeholder="Enter the Maximum Quantity"
                              />
                              {errors.maxQuantity && (
                                <p className="errorcss">{errors.maxQuantity}</p>
                              )}
                              {errors.quantityRange && (
                                <p className="errorcss">
                                  {errors.quantityRange}
                                </p>
                              )}
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">Price</h6>
                              <input
                                type="number"
                                name="price"
                                value={formData.price}
                                onChange={(e) => {
                                  // Allow only numbers and limit the length to 10 digits
                                  const value = e.target.value;
                                  if (
                                    value.length <= 20 &&
                                    /^[0-9]*$/.test(value)
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                className="ad-input-field"
                                placeholder="Enter the Price"
                              />
                              {errors.price && (
                                <p className="errorcss">{errors.price}</p>
                              )}
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Highest Order Price
                              </h6>
                              <input
                                readOnly
                                type="text"
                                name="higeshOrderPrice"
                                value={formData.higeshOrderPrice}
                                onChange={(e) => {
                                  // Allow only numbers and limit the length to 10 digits
                                  const value = e.target.value;
                                  if (
                                    value.length <= 20 &&
                                    /^[0-9]*$/.test(value)
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                className="ad-input-field"
                                placeholder="Enter the Highest Order Price"
                              />
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Preferred Payment
                              </h6>
                              <select
                                className="ad-input-field prefer-select"
                                name="preferredPayment"
                                value={formData.preferredPayment}
                                onChange={handleChange}
                              >
                                <option value="All Payment">All Payment</option>
                                <option value="IMPS">IMPS</option>
                                <option value="UPID">UPI</option>
                                <option value="Paytm">PAYTM</option>
                                <option value="BankTransfer">
                                  Account Transfer
                                </option>
                              </select>
                            </div>

                            <div className="input-groups">
                              <h6 className="input-label ad-title">
                                Payment Time
                              </h6>
                              <select
                                className="ad-input-field prefer-select"
                                name="paymentTime"
                                value={formData.paymentTime}
                                onChange={handleChange}
                              >
                                <option value="15">15 Minutes</option>
                                <option value="30">30 Minutes</option>
                                <option value="45">45 Minutes</option>
                                <option value="60">60 Minutes</option>
                                <option value="90">90 Minutes</option>
                              </select>
                            </div>

                            <div className="my-4">
                              <div className="terms">
                                <div className="checkbox-container">
                                  <input
                                    id="custom-checkbox"
                                    type="checkbox"
                                    name="termsAccepted"
                                    checked={formData.termsAccepted}
                                    onChange={handleChange}
                                    className="input-field regular_checkbox"
                                  />
                                  <label htmlFor="custom-checkbox"></label>
                                </div>
                                <label
                                  htmlFor="custom-checkbox"
                                  className="terms-check "
                                >
                                  I have read and agree to the
                                  <Link to="/" className="text-yellow">
                                    {" "}
                                    Terms{" "}
                                  </Link>{" "}
                                  and{" "}
                                  <Link to="/" className="text-yellow">
                                    {" "}
                                    Conditions
                                  </Link>
                                </label>
                              </div>
                            </div>

                            {errors.termsAccepted && (
                              <p className="errorcss">{errors.termsAccepted}</p>
                            )}

                            <div className="Submit">
                              {btnloader == true ? (
                                <button type="submit">Loading</button>
                              ) : (
                                <button
                                  type="submit"
                                  onClick={(e) => handleSubmit(e, "sell")}
                                >
                                  Post Ad
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostAd;
