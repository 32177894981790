import React, { useEffect } from "react";
import Home_header from "./Home_header";
import { Bars } from "react-loader-spinner";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";

const Authentication = () => {

  const navigate = useNavigate();

  const back = () => {
    navigate("/security")
  }
  useEffect(() => {
    fetchTfaData();
  }, [0]);

  const [loaderButton, setloaderButton] = useState(false);
  const [tfaDetails, setTfaDetails] = useState({});
  const [tfaCode, setTfaCode] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [addDetails, setaddDetails] = useState({});
  const [siteLoader, setSiteLoader] = useState(false);
  const fetchTfaData = async () => {
    try {
      var data = {
        apiUrl: apiService.getTfaDetials,
      };
      var resp = await getMethod(data);
      setTfaDetails(resp.data.tfastatus);
      setaddDetails(resp.data);
    } catch (error) { }
  };
  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
     if (toastId) {
       toast.dismiss(toastId);
     }
     const newToastId = toast.success(message);
     setToastId(newToastId);
   };
 
   const showErrorToast = (message) => {
     if (toastId) {
       toast.dismiss(toastId);
     }
     const newToastId = toast.error(message);
     setToastId(newToastId);
   };
 
  const handleSubmit = async () => {
    setValidationErrors({});
    if (!tfaCode || tfaCode === "") {
      showErrorToast("2FA code is required");
    } else {
      let tfaStatus = tfaDetails;
      var data = {
        apiUrl: apiService.changeTfaStatus,
        payload: {
          userToken: tfaCode,
          tfaStatus: tfaStatus,
        },
      };
      var resp = await postMethod(data);
      if (resp.status) {
        showSuccessToast(resp.Message);
        setTfaCode("");
        fetchTfaData();
        localStorage.setItem("tfa_status", resp.result.tfastatus);
        if (typeof resp?.errors !== "undefined") {
          const isErrorEmpty = Object.keys(resp?.errors).length === 0;
          if (!isErrorEmpty) {
            setValidationErrors(resp?.errors);
          }
        } else {
        }
      } else {
        showErrorToast(resp.Message);
      }
    }
  };
  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast("Secret Key Copied");
  };

  return (
    <main className="user_profile_main h-100">
      <div>
        <Home_header />
      </div>

      {siteLoader == true ?
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div> : <>
          <section className="user_profile_section">
            <div className="container">
              <div className="profile_title" onClick={back}>
                <i class="fa-solid fa-angle-left"></i>2FA Authentication

                
              </div>
              {/* <div className="kyc_border"></div> */}
              <div className="row justify-content-center">
                <div className="col-lg-6 smart_login_row ">
                  <div className="card_login register_page_login_card user-profile">
                    <h3> {tfaDetails === 0 ? (
                      <h3>Enable 2FA </h3>
                    ) : (
                      <h3>Disable 2FA </h3>
                    )}</h3>
                    <div className="steps_change_password">
                      <p>
                        {" "}
                        Protect your transactions and account security, keep your login password safe and do not disclose it to others.
                      </p>
                    </div>

                    {tfaDetails === 0 ? (
                      <>
                        <label className="lable_text">
                          Scan QR
                        </label>
                        <div class="form_login_input position_eye">
                          {tfaDetails === 0 ? (
                            <img
                              src={addDetails.tfa_url}
                              className="d-block mx-auto Qrdesign"
                              width="25%"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {tfaDetails === 0 ? (
                      <>
                        <label className="lable_text">
                          Secret Key
                        </label>
                        <div class="form_login_input position_eye pos-eyes secert-key">
                          <input
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Address"
                            value={
                              addDetails.tfaenablekey ==
                                undefined
                                ? ""
                                : addDetails.tfaenablekey
                            }
                            readOnly
                          />
                          <div
                            className="eye_content"

                          >
                            <i class="ri-clipboard-line" onClick={() =>
                              copy(addDetails.tfaenablekey)
                            }></i>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div>
                      <label className="lable_text">2FA Code</label>
                      <div class="form_login_input">
                        <input
                          class="form-control"
                          type="number"
                          autocomplete="off"
                          min="0"
                          placeholder="Enter Your Code"
                          name="tfaCode"
                          value={tfaCode}
                          onChange={(e) =>
                            setTfaCode(e.target.value)
                          }

                        />
                      </div>
                    </div>
                    {tfaDetails === 0 ? (
                      loaderButton == false ? (
                        <div className="profile_submit_btn">
                          <button
                            className=""
                            onClick={handleSubmit}
                          >
                            Enable
                          </button>
                        </div>
                      ) : (
                        <div className="profile_submit_btn">
                          <button>loading...</button>
                        </div>
                      )
                    ) : loaderButton == false ? (
                      <div className="profile_submit_btn">
                        <button onClick={handleSubmit}>
                          Disable
                        </button>
                      </div>
                    ) : (
                      <div className="profile_submit_btn">
                        <button>loading...</button>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </section>
        </>}
    </main>
  );
};

export default Authentication;
