import React from "react";
import { useEffect } from "react";
import useState from "react-usestateref";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Home_header.js";
import Footer from "./Footer.js";
import { Link } from "react-router-dom";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const navigate = useNavigate();

  const sliderSettings = {
    arrows: false,
    dots: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const [siteData, setSiteData] = useState({});
  const [top_volume, set_top_volume] = useState([]);
  const [top_new_list, set_top_new_list] = useState([]);
  const [loader, setloader, loaderref] = useState(false);
  const [loginCheck, setloginCheck, loginCheckref] = useState(false);

  const fetch_site_data = async () => {
    try {
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      console.log(resp.data, "=--=-=-resp-=-=-=-=-=resp=-=-");
      setSiteData(resp.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetch_site_data();
    api1();
    api2();
    let userToken = localStorage.getItem("user_token");
    if (userToken != null) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, [0]);

  var api1 = async () => {
    var data = {
      apiUrl: apiService.hotPairs,
    };
    var resp = await getMethod(data);
    set_top_volume(resp.topgainers);
  };

  var api2 = async () => {
    var data = {
      apiUrl: apiService.newPairs,
    };
    var resp = await getMethod(data);
    set_top_new_list(resp.hotpairs);
  };

  const nav_page = () => {
    navigate("/register");
  };

  return (
    <main className="smart_bull_landing_page nextex_landing_main">
      <section>
        <Header />
      </section>
      <section className="nextex_hero_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8">
              <div className="markets_banner_content">
                <Slider {...sliderSettings}>
                  <div className="nextex_banner">
                    <img
                      src={require("../images/landing_new/hero_banner.png")}
                    />
                  </div>
                  <div className="nextex_banner">
                    <img
                      src={require("../images/landing_new/hero_banner2.png")}
                    />
                  </div>
                  <div className="nextex_banner">
                    <img
                      src={require("../images/landing_new/hero_banner3.png")}
                    />
                  </div>
                  <div className="nextex_banner">
                    <img
                      src={require("../images/landing_new/hero_banner4.png")}
                    />
                  </div>
                  <div className="nextex_banner">
                    <img
                      src={require("../images/landing_new/hero_banner5.png")}
                    />
                  </div>
                </Slider>
                <section className="popular-cryptocurrencies-section">
                  <div className="container">
                    <div className="popular-cryptocurrencies">
                      <h2>Top Markets</h2>
                      <div className="popular_tabel_border">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link active"
                              id="home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              type="button"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                            >
                              HOT LIST
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#profile"
                              type="button"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                            >
                              NEW LIST
                            </button>
                          </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr className="nextex_table_heading">
                                    <th scope="col">Name</th>
                                    <th scope="col">Price (USDT)</th>
                                    {/* <th scope="col">Volume</th> */}
                                    {/* <th scope="col">Per/Day Chart</th> */}
                                    <th
                                      scope="col"
                                      className="text-center-table"
                                    >
                                      24h Change
                                    </th>

                                    {/* <th scope="col">Trade</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {top_volume && top_volume.length > 0 ? (
                                    top_volume.map((obj, i) => {
                                      if (
                                        obj.symbol != "STETH" &&
                                        obj.symbol != "TONCOIN" &&
                                        obj.symbol != "USDC"
                                      ) {
                                        return (
                                          <tr className="table_content_nextex">
                                            <th scope="row">
                                              <div className="name-coin-item">
                                                <img
                                                  src={obj.imageUrl}
                                                  width={20}
                                                />
                                                <p>
                                                  {obj.name}{" "}
                                                  <span>{obj.symbol}</span>
                                                </p>
                                              </div>
                                            </th>
                                            <td>${obj.price}</td>
                                            {/* <td>{obj.volume}</td> */}

                                            {obj.change24Hour <= 0 ? (
                                              <>
                                                <td className="text-danger">
                                                  <img
                                                    src={require("../images/landing_new/LineChart_red.png")}
                                                    className="chart_line"
                                                  />
                                                  {parseFloat(
                                                    obj.change24Hour
                                                  ).toFixed(2)}
                                                </td>
                                              </>
                                            ) : (
                                              <>
                                                <td className="text-success">
                                                  <img
                                                    src={require("../images/landing_new/LineChart_green.png")}
                                                    className="chart_line"
                                                  />
                                                  +
                                                  {parseFloat(
                                                    obj.change24Hour
                                                  ).toFixed(2)}
                                                </td>
                                              </>
                                            )}

                                            <td className="table_convert">
                                              {/* <Link >
                                              <div className="spot_features_trade_img">
                                                <img
                                                  src={require("../images/landing_new/table_convert_img.png")}
                                                />
                                              </div>
                                            </Link> */}
                                              <Link to="/trade/NET1_USDN">
                                                <button className="convert_btn">
                                                  Trade
                                                </button>
                                              </Link>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={4}>
                                        <div className="empty_data">
                                          <div className="empty_data_img">
                                            <img
                                              src={require("../images/No-data.png")}
                                            />
                                          </div>

                                          <div className="no_records_text">
                                            No Records Found
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div
                            class="tab-pane fade"
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                          >
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr className="nextex_table_heading">
                                    <th scope="col">Name</th>
                                    <th scope="col">Price (USDT)</th>
                                    {/* <th scope="col">Volume</th> */}
                                    {/* <th scope="col">Per/Day Chart</th> */}
                                    <th
                                      scope="col"
                                      className="text-center-table"
                                    >
                                      24h Change
                                    </th>

                                    {/* <th scope="col">Trade</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {top_volume && top_volume.length > 0 ? (
                                    top_volume.map((obj, i) => {
                                      if (
                                        obj.symbol != "STETH" &&
                                        obj.symbol != "TONCOIN" &&
                                        obj.symbol != "USDC"
                                      ) {
                                        return (
                                          <tr className="table_content_nextex">
                                            <th scope="row">
                                              <div className="name-coin-item">
                                                <img
                                                  src={obj.imageUrl}
                                                  width={20}
                                                />
                                                <p>
                                                  {obj.name}{" "}
                                                  <span>{obj.symbol}</span>
                                                </p>
                                              </div>
                                            </th>
                                            <td>${obj.price}</td>
                                            {/* <td>{obj.volume}</td> */}

                                            {obj.change24Hour <= 0 ? (
                                              <>
                                                <td className="text-danger">
                                                  <img
                                                    src={require("../images/landing_new/LineChart_red.png")}
                                                    className="chart_line"
                                                  />
                                                  {parseFloat(
                                                    obj.change24Hour
                                                  ).toFixed(2)}
                                                </td>
                                              </>
                                            ) : (
                                              <>
                                                <td className="text-success">
                                                  <img
                                                    src={require("../images/landing_new/LineChart_green.png")}
                                                    className="chart_line"
                                                  />
                                                  +
                                                  {parseFloat(
                                                    obj.change24Hour
                                                  ).toFixed(2)}
                                                </td>
                                              </>
                                            )}

                                            <td className="table_convert">
                                              {/* <Link to="">
                                              {" "}
                                              <div className="spot_features_trade_img">
                                                <img
                                                  src={require("../images/landing_new/table_convert_img.png")}
                                                />
                                              </div>
                                            </Link> */}
                                              <Link to="/trade/NET1_USDN">
                                                {" "}
                                                <button className="convert_btn">
                                                  Trade
                                                </button>
                                              </Link>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={4}>
                                        <div className="empty_data">
                                          <div className="empty_data_img">
                                            <img
                                              src={require("../images/No-data.png")}
                                            />
                                          </div>

                                          <div className="no_records_text">
                                            No Records Found
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="col-lg-4">
              <section className="popular_feature_section">
                <h2>Popular Features</h2>
                <Link to="/trade/NET1_USDN">
                  <div className="spot_features_box">
                    <div className="spot_features_card">
                      <img
                        src={require("../images/landing_new/spot_trade_icon.png")}
                      />
                      <div className="spot_features_box_content">
                        <h3>Spot Trading</h3>
                        <p>Advanced trading mode with chart</p>
                      </div>
                    </div>
                    <div className="next_icon">
                      <i class="ri-arrow-right-s-line"></i>
                    </div>
                  </div>
                </Link>
                <Link to="/p2p">
                  <div className="spot_features_box">
                    <div className="spot_features_card">
                      <img
                        src={require("../images/landing_new/p2p_icon.png")}
                      />
                      <div className="spot_features_box_content">
                        <h3>Buy Crypto on P2P</h3>
                        <p>
                          Direct bank & mobile transfers to other
                          <br /> users
                        </p>
                      </div>
                    </div>
                    <div className="next_icon">
                      <i class="ri-arrow-right-s-line"></i>
                    </div>
                  </div>
                </Link>
                <Link to="/staking">
                  <div className="spot_features_box">
                    <div className="spot_features_card">
                      <img
                        src={require("../images/landing_new/staking_icon.png")}
                      />

                      <div className="spot_features_box_content">
                        <h3>Staking</h3>
                        <p>Easy and Secure Staking</p>
                      </div>
                    </div>
                    <div className="next_icon">
                      <i class="ri-arrow-right-s-line"></i>
                    </div>
                  </div>
                </Link>
                <div
                  className="spot_features_box"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <div className="spot_features_card">
                    <img
                      src={require("../images/landing_new/deposit_icon.png")}
                    />
                    <div className="spot_features_box_content">
                      <h3>Deposit</h3>
                      <p>Easy crypto & fiat deposits</p>
                    </div>
                  </div>
                  <div className="next_icon">
                    <i class="ri-arrow-right-s-line"></i>
                  </div>
                </div>
                <Link to="/trade/NET1_USDN">
                  <div className="spot_features_box">
                    <div className="spot_features_card">
                      <img
                        src={require("../images/landing_new/buy_token_icon.png")}
                      />
                      <div className="spot_features_box_content">
                        <h3>Buy NET1 coin </h3>
                        <p>Buy NET1 in few steps</p>
                      </div>
                      {/* <span class="comming_soon">Soon</span> */}
                    </div>
                    <div className="next_icon">
                      <i class="ri-arrow-right-s-line"></i>
                    </div>
                  </div>
                </Link>
              </section>
              <section className="next_app_download_section">
                <h2>
                  Download <span> NET1ex </span> App
                </h2>
                <p>Scan to download IOS & Android</p>

                <div className="download_assets_qr">
                  <div className="">
                    <img
                      src={require("../images/landing_new/download_mobile.webp")}
                      className="landing-download-mobile"
                    />
                  </div>
                  <div className="qr_assets">
                    <img
                      src={require("../images/landing_new/download_qr.png")}
                    />
                    <img src={require("../images/landing_new/app_store.png")} />
                    <img
                      src={require("../images/landing_new/play_store.png")}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <section className="smart-footer">
        <Footer />
      </section>
    </main>
  );
};

export default LandingPage;
